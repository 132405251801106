import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProductsState } from 'state/ProductsForOrgState';
import { Criteria, GetSearchCriteriaWithValueSelector } from 'state/TextToSearchState';
import { ProductHierarchyByTypeInState } from 'state/ProductHierarchyState';
import TimeFilterSearchState, { TimeFilterOptions } from 'state/TimeFilterSearchState';
import moment from 'moment';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import Product from 'types/Product';

interface SearchFilters {
  filteredProducts: Product[];
  filteredGroups: ProductHierarchy[];
  filteredLines: ProductHierarchy[];
}

function useSearchFilters(): SearchFilters {
  const productsInState = useRecoilValue(ProductsState);
  const groupsInState = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const linesInState = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.LINE));
  const searchCriteria = useRecoilValue(GetSearchCriteriaWithValueSelector);
  const timeFilterSearchValue = useRecoilValue(TimeFilterSearchState);

  const [searchFilters, setSearchFilters] = useState<SearchFilters>({
    filteredProducts: getActiveProducts(),
    filteredGroups: groupsInState,
    filteredLines: linesInState,
  });

  const [filteredProductsByTime, setFilteredProductsByTime] = useState<Product[]>(
    applyFilterByTime(),
  );

  function getActiveProducts(): Product[] {
    return productsInState.state === 'Loaded'
      ? productsInState.products.filter((product: Product) => !product.done)
      : [];
  }

  function applyGroupFilter(filteredProductsByTime: Product[]) {
    const filteredGroups = groupsInState.filter((group) =>
      group.name.toLocaleLowerCase().includes(searchCriteria.value.toLocaleLowerCase()),
    );
    setSearchFilters({
      ...searchFilters,
      filteredProducts: filteredProductsByTime,
      filteredGroups,
      filteredLines: linesInState,
    });
  }

  function applyLineFilter(filteredProductsByTime: Product[]) {
    const filteredLines = linesInState.filter((line) =>
      line.name.toLocaleLowerCase().includes(searchCriteria.value.toLocaleLowerCase()),
    );
    const filteredLineIds = filteredLines.map((line) => line.id);
    const filteredProductsByLineId: Product[] = filteredProductsByTime.filter((product) =>
      filteredLineIds.includes(product.productLineId),
    );

    setSearchFilters({
      ...searchFilters,
      filteredProducts: filteredProductsByLineId,
      filteredGroups: groupsInState,
      filteredLines,
    });
  }

  function applyProductFilter(filteredProductsByTime: Product[]) {
    if (searchCriteria.value.length > 0) {
      const filteredProducts = filteredProductsByTime.filter((product) =>
        product.name.toLowerCase().includes(searchCriteria.value.toLowerCase()),
      );
      setSearchFilters({
        ...searchFilters,
        filteredProducts,
        filteredGroups: groupsInState,
        filteredLines: linesInState,
      });
    }
  }

  function applyStageFilter(filteredProductsByTime: Product[]) {
    if (searchCriteria.value.length > 0) {
      const filteredProducts = filteredProductsByTime.filter(
        (product) =>
          product.stage && product.stage.toLowerCase().includes(searchCriteria.value.toLowerCase()),
      );
      setSearchFilters({
        ...searchFilters,
        filteredProducts,
        filteredGroups: groupsInState,
        filteredLines: linesInState,
      });
    }
  }

  function applyFilterBySearchCriteria() {
    if (searchCriteria.value) {
      switch (searchCriteria.criteria) {
        case Criteria.GROUP:
          applyGroupFilter(filteredProductsByTime);
          break;
        case Criteria.LINE:
          applyLineFilter(filteredProductsByTime);
          break;
        case Criteria.STAGE:
          applyStageFilter(filteredProductsByTime);
          break;
        case Criteria.PRODUCT:
        case Criteria.NONE:
        default:
          applyProductFilter(filteredProductsByTime);
          break;
      }
    } else
      setSearchFilters({
        filteredProducts: filteredProductsByTime,
        filteredGroups: groupsInState,
        filteredLines: linesInState,
      });
  }
  function getProductsUpdatedInLastDays(days: number): Product[] {
    const filteredTime = moment().subtract(days, 'd').startOf('date');
    return getActiveProducts().filter(
      (product) => moment(product.lastUpdatedTimeStamp).startOf('date') >= filteredTime,
    );
  }

  function getProductsUpdatedOverDays(days: number): Product[] {
    const filteredTime = moment().subtract(days, 'd').startOf('date');
    return getActiveProducts().filter(
      (product) => moment(product.lastUpdatedTimeStamp).startOf('date') < filteredTime,
    );
  }

  function applyFilterByTime(): Product[] {
    switch (timeFilterSearchValue) {
      case TimeFilterOptions.IN_THE_LAST_7_DAYS:
        return getProductsUpdatedInLastDays(7);
      case TimeFilterOptions.IN_THE_LAST_14_DAYS:
        return getProductsUpdatedInLastDays(14);
      case TimeFilterOptions.IN_THE_LAST_30_DAYS:
        return getProductsUpdatedInLastDays(30);
      case TimeFilterOptions.OVER_30_DAYS:
        return getProductsUpdatedOverDays(30);
      case TimeFilterOptions.UPDATED_ANY_TIME:
      default:
        return getActiveProducts();
    }
  }

  useEffect(() => {
    applyFilterBySearchCriteria();
  }, [searchCriteria, filteredProductsByTime]);

  useEffect(() => {
    setFilteredProductsByTime(applyFilterByTime());
  }, [timeFilterSearchValue]);

  return searchFilters;
}

export default useSearchFilters;
