import { atom, selectorFamily } from 'recoil';
import { Error, Loading } from 'data/commonTypes';
import Product from 'types/Product';

export type ProductsStateType = Loading | LoadedProductsState | Error;

export type LoadedProductsState = {
  state: 'Loaded';
  products: Product[];
  orgId: string;
};

export const ProductsState = atom<ProductsStateType>({
  key: 'ProductsState',
  default: { state: 'Loading' },
});

export const CurrentProductSelector = selectorFamily({
  key: 'CurrentProductSelector',
  get:
    (productIdParam: string) =>
    ({ get }) => {
      const productState = get(ProductsState);
      if (productIdParam && productState.state === 'Loaded') {
        const foundProduct = productState.products.find((product: Product) => {
          return !decodeURIComponent(productIdParam.toLowerCase()).localeCompare(
            product.id,
            undefined,
            {
              sensitivity: 'accent',
            },
          );
        });
        if (foundProduct) return foundProduct;
        else window.location.href = '/';
      }
      return {
        id: '',
        name: '',
        orgId: '',
        icon: '',
        productLineId: '',
        relevantLinks: [],
        stage: '',
        teamMembers: [],
      } as Product;
    },
  set:
    (productIdParam: string) =>
    ({ set, get }, newProduct) => {
      const productsInState = get(ProductsState);
      if (productsInState.state === 'Loaded') {
        const products = productsInState.products;
        const updatedProducts: Product[] = products.map((product) => {
          if (product.id === productIdParam) {
            return newProduct as Product;
          }
          return product;
        });
        set(ProductsState, { ...productsInState, products: updatedProducts });
      }
    },
});
