import ProductHierarchy from 'types/ProductHierarchy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import {  useSetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import AddNewLineModal from './AddNewLineModal/AddNewLineModal';

interface Props {
  group: ProductHierarchy;
  setExpanded(expandedIndex: number): void;
}

function AddNewLineButton(props: Readonly<Props>) {
  const { group, setExpanded } = props;
  const setModalContents = useSetRecoilState(ModalContentsState);

  return (
    <button
      data-testid={`${group.name}-new-line`}
      className="button-add"
      aria-label="add line"
      onClick={() => {
        setModalContents({
          title: 'Add New Line',
          component: <AddNewLineModal group={group} setExpanded={setExpanded} />,
          dispatchCloseModalEvent: true,
        });
      }}
    >
      <FontAwesomeIcon icon={'plus' as IconName} />
      Add New Line
    </button>
  );
}

export default AddNewLineButton;
