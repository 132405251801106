import React, { useEffect } from 'react';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { To, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TextToSearchState, {
  Criteria,
} from 'state/TextToSearchState';
import {
  Autocomplete,
  Box,
  InputAdornment,
  Paper,
  PaperProps,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import useScreenSize from 'hooks/useScreenSize';


import './SearchBar.scss';

type FilterOption = { label: Criteria; placeHolderText: string };

const CustomPaper = (props: JSX.IntrinsicAttributes & PaperProps) => {
  const isDropDownOptionsExist =
    props.children && Array.isArray(props.children) && props.children[2];
  return (
    <>
      {isDropDownOptionsExist && (
        <Paper
          data-testid="custom-paper"
          {...props}
          variant="outlined"
          sx={{
            padding: '0 14px',
            fontWeight: '400',
            boxShadow:
              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
          }}
        >
          <div className="filter-dropdown-header">narrow your search</div>
          {props.children}
        </Paper>
      )}
    </>
  );
};
export default function SearchBar(): React.ReactElement {
  const isDesktop = useScreenSize();
  const navigate = useNavigate();
  const { org = '' } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const organization = useRecoilValue(CurrentOrgSelector(org));
  const [textToSearch, setTextToSearch] = useRecoilState(TextToSearchState);

  const options: FilterOption[] = [
    { label: Criteria.PRODUCT, placeHolderText: 'name' },
    { label: Criteria.LINE, placeHolderText: 'name' },
    { label: Criteria.GROUP, placeHolderText: 'name' },
    { label: Criteria.STAGE, placeHolderText: 'name' },
  ];

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: FilterOption) => option.label,
  });

  useEffect(() => {
    if (textToSearch.trim() === '' && searchParams.get('searchFilter')) {
      searchParams.delete('searchFilter');
      setSearchParams(searchParams);
    }
  }, [textToSearch]);

  useEffect(() => {
    const searchFilterValue = searchParams.get('searchFilter');
    if (searchFilterValue) {
      setTextToSearch(searchFilterValue);
    } else setTextToSearch('');
  }, [searchParams]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Autocomplete
        id="search-bar-autocomplete"
        data-testid="search-bar-autocomplete"
        sx={{ width: 280 }}
        fullWidth={true}
        freeSolo
        inputValue={textToSearch}
        PaperComponent={CustomPaper}
        filterOptions={filterOptions}
        onInputChange={(_event, value) => {
          setTextToSearch(value);
        }}
        onClose={(event, reason) => {
          if (reason === 'blur') {
            if (textToSearch.trim() !== '') {
              searchParams.set('searchFilter', `${textToSearch.trim()}`);
              setSearchParams(searchParams);

              if (!isDesktop) {
                const focusEvent = event.nativeEvent as FocusEvent;
                if (focusEvent.relatedTarget != null) {
                  const classNames = (focusEvent.relatedTarget as HTMLLinkElement)
                    .getAttribute('class')
                    ?.split(' ');
                  const href = (focusEvent.relatedTarget as HTMLLinkElement).getAttribute('href');
                  if (classNames?.includes('standard-link')) {
                    navigate(href as To);
                  }
                }
              }
            }
          }
        }}
        clearIcon={
          <FontAwesomeIcon
            className="clear-icon"
            size="sm"
            icon={['fas', 'xmark'] as [IconPrefix, IconName]}
          />
        }
        options={options}
        //@ts-ignore
        getOptionLabel={(option: FilterOption) =>
          option.label ? `${option.label.toLocaleLowerCase()}: ` : ''
        }
        renderOption={(props, option) => (
          <Box component="li" {...props} data-testid="search-dropdown-option">
            <b>{option.label.toLocaleLowerCase()}:</b> &nbsp;{option.placeHolderText}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className="search-bar"
            label="filter products"
            variant="outlined"
            fullWidth
            inputProps={{
              ...params.inputProps,
              'data-testid': 'kanban-search-box',
              type: 'text',
              'aria-label': `Search through ${organization.name} products by name`,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon className="search-icon" icon={'search' as IconName} />
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </ThemeProvider>
  );
}
