import { atom } from 'recoil';
import getActiveTab, { ActiveLineTab, ActiveTab } from 'utils/getActiveTab';

export const ActiveTabState = atom<ActiveTab | ActiveLineTab>({
  key: 'ActiveTabState',
  default: getTabForPage(),
});

function getTabForPage(): number {
  const location = window.location.href;
  return getActiveTab(location, !location.includes('/line/'));
}
