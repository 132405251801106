import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import GroupsAndLinesModalWrapper from '../../GroupsAndLinesModalWrapper/GroupsAndLinesModalWrapper';
import { TextField } from '@mui/material';
import ProductGroupSelect from 'common/ProductGroupSelect/ProductGroupSelect';
import ProductHierarchyState, {
  Loaded,
  ProductHierarchyByTypeInState,
} from 'state/ProductHierarchyState';
import ProductHierarchyService from 'services/api/ProductHierarchyService';
import { ModalContentsState } from 'state/ModalContentsState';
import styles from './EditLineModal.module.scss';

interface LineField {
  value: string;
  isError: boolean;
}

interface GroupField extends LineField {
  id: string;
}

interface Props {
  line: ProductHierarchy;
  setExpanded(expandedIndex: number): void;
}

function EditLineModal({ line, setExpanded }: Props) {
  const allGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const closeModal = useResetRecoilState(ModalContentsState);
  const [productHierarchyState, setProductHierarchyState] = useRecoilState(ProductHierarchyState);

  const productHierarchy: ProductHierarchy[] =
    (productHierarchyState.state === 'Loaded' && productHierarchyState.productHierarchy) || [];
  const group: ProductHierarchy | undefined = getGroupById(line.parentId);

  const [lineField, setLineField] = useState<LineField>({ value: line.name, isError: false });
  const [groupField, setGroupField] = useState<GroupField>({
    id: group?.id || '',
    value: group?.name || '',
    isError: false,
  });

  function getGroupById(id: string | undefined): ProductHierarchy | undefined {
    return allGroups.find((group) => group.id === id);
  }

  function isFormUpdated() {
    return isLineNameChanged() || isGroupChanged();
  }

  function isLineNameChanged(): boolean {
    return line.name !== lineField.value;
  }

  function isGroupChanged(): boolean {
    return group?.name !== groupField.value;
  }

  function setGroupToExpand(currentLine: ProductHierarchy, groupId: string | undefined) {
    let index = currentLine.order;
    if (isGroupChanged()) {
      index = allGroups.findIndex((group) => groupId === group.id);
    }
    if (index !== undefined) setExpanded(index);
  }

  async function onSubmit() {
    if (!isFormUpdated()) {
      closeModal();
      return Promise.resolve();
    }

    try {
      const updatedLine: ProductHierarchy = {
        ...line,
        name: lineField.value.trim(),
        parentId: groupField.id,
      };
      const data = await ProductHierarchyService.editProductHierarchy(updatedLine).then((res) => [
        res.data,
      ]);

      if (Array.isArray(data)) {
        setProductHierarchyState({
          ...productHierarchyState,
          productHierarchy: [...productHierarchy].map((item) => {
            return item.id === data[0].id ? data[0] : item;
          }),
        } as Loaded);
      }

      setGroupToExpand(data[0], updatedLine.parentId);
      closeModal();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setLineField((prevState) => ({ ...prevState, isError: true }));

      } else {

        throw error;
      }
    }
  }

  return (
    <GroupsAndLinesModalWrapper
      title="Edit Line"
      onSubmit={onSubmit}
      isFormPopulated={isFormUpdated}
      useEffectDeps={[lineField]}
    >
      <div className={styles.content}>
        <TextField
          label="Line Name"
          required
          defaultValue={lineField.value}
          onChange={(event) => setLineField({ value: event.target.value, isError: false })}
          className={styles.textField}
          inputProps={{
            'data-testid': 'editLineNameInput',
            title: `Enter Line Name`,
          }}
          error={lineField.isError}
          helperText={
            lineField.isError && 'There is already a Line with the same name in the Group'
          }
        />
        <div className={styles.groupDropdown}>
          <ProductGroupSelect
            selectedProductGroupId={groupField?.id || ''}
            listOfGroups={allGroups}
            handleChange={(event) => {
              const newGroup = getGroupById(event.target.value);
              setGroupField({
                value: newGroup?.name || '',
                id: newGroup?.id || '',
                isError: false,
              });
            }}
          />
        </div>
      </div>
    </GroupsAndLinesModalWrapper>
  );
}

export default EditLineModal;
