import React, { ReactElement } from 'react';

export default function FAQPageLink(): ReactElement {
  return (
    <a
      href="https://azureford.sharepoint.com/:u:/r/sites/fordlabsproductsuite/SitePages/Frequently-Asked-Questions.aspx?csf=1&web=1&e=hLFZ6u"
      target="_blank"
      rel="noreferrer noopener"
    >
      FAQ page
    </a>
  );
}
