import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import IsContentChangedState from 'state/ContentChangedState';
import ErrorMessageState from 'state/ErrorMessageState';
import { Actions } from '../UpdateTab';

import Page from 'types/Page';

import './UpdatePageButtons.scss';

export enum VisibleViewButtons {
  ADD_AND_EDIT,
  EDIT,
  NONE,
}

interface Props {
  action: Actions;
  setShowAddNewUpdateModal: (showAddNewUpdateModal: boolean) => void;
  setAction: (action: Actions) => void;
  setShowConfirmationModal: (showConfirmationModal: boolean) => void;
  disabled: boolean;
  savePage: () => Promise<Page>;
  visibleViewButtons: VisibleViewButtons;
}

export default function UpdatePageButtons({
  savePage,
  action,
  setShowAddNewUpdateModal,
  setAction,
  setShowConfirmationModal,
  disabled,
  visibleViewButtons,
}: Props): React.ReactElement {
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const setIsContentChanged = useSetRecoilState(IsContentChangedState);

  function saveUpdate() {
    savePage()
      .then(() => {
        setAction(Actions.view);
        setErrorMessage({ ...errorMessage, caughtError: false });
        setIsContentChanged(false);
      })
      .catch(() => {
        setErrorMessage({
          ...errorMessage,
          caughtError: true,
        });
      });
  }

  function saveOrCancelButtons() {
    return (
      <>
        <button
          className={`button-blue ${disabled ? 'disabled' : ''}`}
          disabled={disabled}
          onClick={saveUpdate}
          title={`${disabled ? 'Saving Image(s) is in progress. Please Wait.' : ''}`}
        >
          Save Update
        </button>
        <button className="button-red" onClick={() => setShowConfirmationModal(true)}>
          Cancel
        </button>
      </>
    );
  }
  function handleEditButtonClick() {
    setAction(Actions.edit);
  }

  return (
    <>
      {action === Actions.view && (
        <div className={'update-buttons'}>
          {visibleViewButtons === VisibleViewButtons.ADD_AND_EDIT && (
            <button
              data-testid={`update-add-plus`}
              aria-label="add update"
              className="write-update-button"
              onClick={() => setShowAddNewUpdateModal(true)}
            >
              <FontAwesomeIcon icon={'plus' as IconName} className="fai-shadow plus" />
              <span>Add New</span>
            </button>
          )}
          {(visibleViewButtons === VisibleViewButtons.ADD_AND_EDIT ||
            visibleViewButtons === VisibleViewButtons.EDIT) && (
            <button
              data-testid={`update-edit-pencil`}
              aria-label="edit update"
              className="write-update-button"
              onClick={handleEditButtonClick}
            >
              <FontAwesomeIcon icon={'pen' as IconName} className="fai-shadow pen" />
              <span>Edit Update</span>
            </button>
          )}
        </div>
      )}
      {action !== Actions.view && (
        <div className="edit-update-buttons">{saveOrCancelButtons()}</div>
      )}
    </>
  );
}
