import React from 'react';
import { Event } from 'data/commonTypes';
import Modal from 'common/Modal/DepricatedModal/Modal';
import RelevantLink from 'types/RelevantLink';
import TeamMember from 'types/TeamMember';

import './ArchiveModal.scss';

interface Props {
  productIsDone: boolean;
  archiveText: string;
  productName: string;
  orgDoneText: string;
  orgName: string;
  updateProduct: (
    field: string,
    event: Event,
    content: string | Array<TeamMember> | Array<RelevantLink> | boolean,
  ) => void;
  setShowArchiveModal(value: boolean): void;
}

export default function ArchiveModal(props: Props): React.ReactElement {
  const {
    productIsDone,
    archiveText,
    productName,
    orgDoneText,
    orgName,
    updateProduct,
    setShowArchiveModal,
  } = props;

  return (
    <Modal
      isOpenModal={true}
      closeHandler={() => setShowArchiveModal(false)}
      showCloseIcon={false}
      className="archive-modal"
      title={productIsDone ? 'Activate Product' : `${archiveText} Product`}
    >
      <div className="archive-confirmation-text">
        Are you sure you want to move the product &quot;{productName}&quot; to the{' '}
        {productIsDone ? orgName : orgDoneText} Products page?
      </div>
      <div className="archive-confirmation-button-container">
        <button
          className="button-red"
          data-testid="archive-product-cancel"
          onClick={() => setShowArchiveModal(false)}
        >
          Cancel
        </button>
        <button
          className="button-blue"
          data-testid="archive-product-confirmation"
          onClick={() => {
            setShowArchiveModal(false);
            updateProduct('done', productIsDone ? Event.UNARCHIVE : Event.ARCHIVE, !productIsDone);
          }}
        >
          Yes, {productIsDone ? 'Activate' : archiveText}
        </button>
      </div>
    </Modal>
  );
}
