import React from 'react';
import { Event } from 'data/commonTypes';
import EditTeamCommonModal, { DataToEdit } from './EditTeamCommonModal/EditTeamCommonModal';
import RelevantLink from 'types/RelevantLink';

interface Props {
  setDialogIsOpen: (editing: boolean) => void;
  links: RelevantLink[] | undefined;
  updateRelevantLink: (field: string, event: Event, content: string | Array<RelevantLink>) => void;
}
export default function EditRelevantLinkModal({
  updateRelevantLink,
  links,
  setDialogIsOpen,
}: Props): React.ReactElement {
  function addLinkToList(dataToEdit: DataToEdit) {
    const linkToAdd = {
      text: dataToEdit.name,
      url: dataToEdit.titleOrUrl,
    };
    const newLinks = links ? [...links, linkToAdd] : [linkToAdd];
    updateRelevantLink('relevantLinks', Event.ADD, newLinks);
  }

  function editLinkInList(dataToEdit: DataToEdit, index: number) {
    const linkToEdit = {
      text: dataToEdit.name,
      url: dataToEdit.titleOrUrl,
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editedList = links!.map((link, key) => {
      if (key === index) {
        return linkToEdit;
      } else return link;
    });
    updateRelevantLink('relevantLinks', Event.EDIT, [...editedList]);
  }

  function reorderLinkData(dataList: Array<DataToEdit>) {
    const relevantLinks: Array<RelevantLink> = [];
    dataList.forEach((data) => {
      relevantLinks.push({
        text: data.name,
        url: data.titleOrUrl,
      });
    });
    updateRelevantLink('relevantLinks', Event.REORDER, [...relevantLinks]);
  }

  function deleteRelevantLinkFromList(dataToDelete: DataToEdit) {
    updateRelevantLink(`relevantLinks`, Event.DELETE, [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...links!.filter((item) => item.text !== dataToDelete.name),
    ]);
  }

  function convertRelevantLinksToDataList(): DataToEdit[] {
    const dataList: DataToEdit[] = [];
    if (links) links.forEach((link) => dataList.push({ name: link.text, titleOrUrl: link.url }));
    return dataList;
  }

  return (
    <EditTeamCommonModal
      dataType="link"
      dataList={convertRelevantLinksToDataList()}
      setDialogIsOpen={setDialogIsOpen}
      addProductData={addLinkToList}
      editProductData={editLinkInList}
      deleteProductData={deleteRelevantLinkFromList}
      reorderProductData={reorderLinkData}
    />
  );
}
