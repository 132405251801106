import type { Transition } from 'history';
import { useCallback } from 'react';
import { useBlocker } from './useBlocker';

export function usePrompt(message: string, when = true, callback?: () => void) {
  const blocker = useCallback(async (tx: Transition) => {
    try {
      const isConfirmed = confirm(message);
      if (isConfirmed) {
        callback && callback();
        tx.retry();
      }
    } catch (e) {
      // Do nothing
    }
  }, []);

  return useBlocker(blocker, when);
}
