import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Event } from 'data/commonTypes';
import useScreenSize from 'hooks/useScreenSize';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import EditRelevantLinkModal from 'common/Links/EditRelevantLinkModal/EditRelevantLinkModal';

import RelevantLink from 'types/RelevantLink';

interface Props {
  links: RelevantLink[] | undefined;
  trackingName: string;
  showEditPencil: boolean;
  orgParam: string;
  location: string;
  updateContent: (field: string, event: Event, content: string | Array<RelevantLink>) => void;
}
export default function Links({
  links,
  showEditPencil,
  updateContent,
}: Props): React.ReactElement {
  const isDesktop = useScreenSize();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const EditPencil = () => (
    <LightTooltip
      className="relevant-links-tooltip"
      title="Edit this section"
      data-testid="relevant-links-tooltip"
      placement="top"
      arrow
    >
      <button
        data-testid="edit-relevant-links"
        aria-label="edit relevant links"
        className="edit-icon"
        onClick={() => {
          setDialogIsOpen(true);
        }}
      >
        <FontAwesomeIcon icon={'pen' as IconName} />
      </button>
    </LightTooltip>
  );
  function printLinks(linksToPrint: RelevantLink[] | undefined) {
    return (
      <>
        {linksToPrint &&
          linksToPrint.map((link, index) => {
            return (
              <div key={`${link.text}-${index}`} className={'report-sidebar-content'}>
                <a
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.text}
                </a>
              </div>
            );
          })}
      </>
    );
  }

  return (
    <div>
      {dialogIsOpen && (
        <EditRelevantLinkModal
          updateRelevantLink={updateContent}
          links={links}
          setDialogIsOpen={setDialogIsOpen}
        />
      )}
      <div className={`relevant-links ${isDesktop ? '' : 'content-box'}`}>
        <div className="relevant-links-header-container">
          <h5 className="bold">LINKS</h5>
          {showEditPencil && <EditPencil />}
        </div>
        <div className="relevant-links-container">{printLinks(links)}</div>
      </div>
    </div>
  );
}
