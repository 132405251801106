import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

interface Props {
  testId: string;
  ariaLabel: string;
  onClick(): void;
  className?: string;
  iconName?: IconName;
}

export default function IconButton(props: Props) {
  const { onClick, testId, ariaLabel, className = '', iconName = 'pen' } = props;

  return (
    <button
      data-testid={testId}
      aria-label={ariaLabel}
      className={`${className} edit-icon`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={iconName as IconName} />
    </button>
  );
}
