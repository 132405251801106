import React from 'react';
import ContactSupportLink from 'common/ContactSupportLink/ContactSupportLink';
import SupportDocsLink from 'common/SupportDocsLink/SupportDocsLink';

import './HelpDropdown.scss';

export default function HelpDropdown(): React.ReactElement {
  return (
    <>
      <div className="nav-dropdown-container help-bar">
        <div className="nav-dropdown-content">
          <ContactSupportLink className="nav-link menu" location="help" />
          <SupportDocsLink className="nav-link menu" location="help" />
        </div>
      </div>
      <div id="nav-dropdown-overlay"></div>
    </>
  );
}
