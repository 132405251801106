import { atom } from 'recoil';

export enum TimeFilterOptions {
  UPDATED_ANY_TIME = 'Updated Any Time',
  IN_THE_LAST_7_DAYS = 'in the last 7 days',
  IN_THE_LAST_14_DAYS = 'in the last 14 days',
  IN_THE_LAST_30_DAYS = 'in the last 30 days',
  OVER_30_DAYS = 'over 30 days',
}

const TimeFilterSearchState = atom<string>({
  key: 'TimeFilterSearchState',
  default: TimeFilterOptions.UPDATED_ANY_TIME,
});

export default TimeFilterSearchState;
