import axios, { AxiosResponse } from 'axios';
import ProductHierarchy, { AddProductHierarchyRequest } from 'types/ProductHierarchy';

const ProductHierarchyService = {
  async getProductHierarchyForOrg(orgId: string): Promise<ProductHierarchy[]> {
    return axios.get(`/api/producthierarchy/${orgId}`).then((res) => res.data);
  },

  async editProductHierarchy(request: ProductHierarchy): Promise<AxiosResponse<ProductHierarchy>> {
    return axios.put('/api/producthierarchy/edit', request);
  },

  async addProductHierarchy(request: AddProductHierarchyRequest): Promise<ProductHierarchy[]> {
    return axios.post('/api/producthierarchy', request).then((res) => res.data);
  },

  async deleteProductHierarchyById(productHierarchyId: string): Promise<void> {
    return axios.delete('/api/producthierarchy/' + productHierarchyId);
  },

  async reorderProductHierarchyList(orgId: string, idList: string[]): Promise<void> {
    return axios.put(`/api/org/${encodeURIComponent(orgId)}/reorderProductHierarchies`, idList);
  },
};

export default ProductHierarchyService;
