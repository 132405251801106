import axios from 'axios';
import Product, { ProductsWithDescriptionForLine } from 'types/Product';

const ProductService = {
  async getProductsForOrg(orgId: string): Promise<Map<string, Product[]>> {
    return axios.get(`/api/products/${orgId}`).then((res) => new Map(Object.entries(res.data)));
  },

  async getProductsDescriptionForLinePage(
    orgId: string,
    productLineId: string,
  ): Promise<Map<string, ProductsWithDescriptionForLine>> {
    return axios
      .get(`/api/products/${orgId}/${encodeURIComponent(productLineId)}`)
      .then((res) => new Map(Object.entries(res.data)));
  },

  async editProduct(product: Product) {
    return axios.put('/api/product/edit', product);
  },

  async addProduct(product: Product): Promise<Product> {
    return axios.post('/api/product/add', product).then((res) => res.data);
  },

  async deleteProduct(productId: string): Promise<void> {
    return axios.delete(`/api/product/${productId}`);
  },
};

export default ProductService;
