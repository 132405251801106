import OrgForDropdown from "types/OrgForDropdown";


const TempFlagsmithReplacement = {
   getFeaturedOrgs(
    allOrgsForDropdown: OrgForDropdown[],
    isDesktop: boolean,
  ): OrgForDropdown[] {
  
    //TODO this should not be hardcoded values and instead stored somewhere
    const featuredOrgsDesktop : string[] = ["Electrification", "Enterprise Platform Engineering & Operations (EPEO)", "FCSD Tech", "Ford Credit",  "Ford Pro Technology", "FordLabs & Team Shelby", "Global Data Insights and Analytics (GDI&A)", "Sandbox"];
    const featuredOrgsMobile : string[] = ["Electrification", "Enterprise Platform Engineering & Operations (EPEO)", "FCSD Tech", "Ford Credit",  "Ford Pro Technology", "Global Data Insights and Analytics (GDI&A)"];
    const featuredOrgs = isDesktop ? featuredOrgsDesktop : featuredOrgsMobile;
  
    const filteredOrgs: OrgForDropdown[] = [];
      featuredOrgs.forEach((orgName) => {
        const foundOrg = allOrgsForDropdown.find(
          (orgForDropDown) => orgForDropDown.name === orgName,
        );
        if (foundOrg) filteredOrgs.push(foundOrg);
      });
    return filteredOrgs.length > 0 ? filteredOrgs : allOrgsForDropdown;
  }
}

export default TempFlagsmithReplacement
