import React from 'react';
import HomePageJoinImage from 'images/home-page-join-image.svg';
import DashDivider from 'common/DashDivider/DashDivider';

import FAQPageLink from 'common/FAQPageLink/FAQPageLink';

import styles from './CallToActionSection.module.scss';

export default function CallToActionSection() {
  function SendUsANoteButton() {
    return (
      <a
        className={`button-blue ${styles.sendUsANoteLink}`}
        href="https://engage.cloud.microsoft/main/org/ford.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxODU5MjM2NDEzNDQifQ/all"
        target="_blank"
        rel="noreferrer noopener"
      >
        Send us a note
      </a>
    );
  }

  return (
    <div className="call-to-action">
      <img
        src={HomePageJoinImage}
        className="call-to-action-image"
        alt="person raising hand from within computer screen"
      />
      <div className="call-to-action-text">
        <h2>Interested in joining our site?</h2>
        <div>
          Still have questions? Learn more on our <FAQPageLink />
        </div>
        <DashDivider className="desktop-only" />
        <SendUsANoteButton />
      </div>
    </div>
  );
}
