import { atom } from 'recoil';

type ErrorMessage = {
  caughtError: boolean;
  errorMessage: string;
};

const ErrorMessageState = atom<ErrorMessage>({
  key: 'ErrorMessageState',
  default: {
    caughtError: false,
    errorMessage: 'There was an error saving the changes. Please try saving again.',
  },
});

export default ErrorMessageState;
