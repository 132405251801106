import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { screenSizes } from 'data/breakpoints';
import IsDesktopState from 'state/IsDesktopState';
import { debounce } from '../utils/utils';

function useScreenSize(): boolean {
  const [isDesktop, setIsDesktop] = useRecoilState(IsDesktopState);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setIsDesktop(window.innerWidth >= screenSizes.medium);
    }, 200);
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return isDesktop;
}

export default useScreenSize;
