import { createTheme } from '@material-ui/core';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

type overridesNameToClassKey = {
  [P in keyof Required<MuiPickersOverrides>]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersDay: {
    color?: string;
  };
  MuiPickersBasePicker: {
    container: {
      padding?: string;
    };
    pickerView: {
      maxWidth?: string;
    };
  };
  MuiPickersCalendar: {
    transitionContainer: {
      maxWidth?: string;
    };
  };
};

declare module '@material-ui/core/styles/overrides' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends CustomType {}
}

export const selectCalendarTheme = createTheme({
  overrides: {
    // Applied to the <ul> element
    MuiTypography: {
      caption: {
        fontWeight: 600,
        fontSize: '20px',
      },
      body1: {
        color: '#111',
        fontWeight: 600,
        fontSize: '24px',
      },
      body2: {
        fontWeight: 'bold',
        fontSize: '20px',
      },
    },
    MuiPopover: {
      root: {
        backgroundColor: '#111111C7',
      },
    },
    MuiPickersBasePicker: {
      container: {
        padding: '40px 60px 60px 60px',
        border: '2px solid #097BBC',
        boxSizing: 'border-box',
        width: '600px',
        height: '584px',
      },
      pickerView: {
        border: '2px solid #e2e2e2',
        borderRadius: '10px',
        minHeight: '474px',
        maxWidth: '480px',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        height: '100%',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#111',
        width: '60px',
      },
      switchHeader: {
        paddingTop: '20px',
        paddingBottom: '10px',
      },
      transitionContainer: {
        height: '50px',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#097BBC',
        width: '60px',
        height: '58px',
        '&$current': {
          border: '1px dashed #097BBC',
          boxSizing: 'border-box',
          borderRadius: '0',
        },
        '&:hover': {
          backgroundColor: '#097bbc33',
          color: '#097BBC',
          textDecoration: 'none',
        },
        '&:focus': {
          boxShadow: 'inset 0px 4px 4px #000000d9',
          backgroundColor: '#097BBC',
          color: 'white',
        },
      },
      daySelected: {
        '&$current': {
          color: 'white !important',
          border: 'none !important',
        },
        '&:hover': {
          color: 'white',
          textDecoration: 'none',
          backgroundColor: '#034870 !important',
        },
      },
      dayDisabled: {
        '&$current': {
          color: '#111 !important',
          border: '1px dashed #111111',
          boxSizing: 'border-box',
          borderRadius: '0',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: '#111',
      },
    },
  },
  palette: {
    primary: {
      main: '#097bbc !important',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif', 'Arial', 'Helvetica'].join(','),
  },
});
