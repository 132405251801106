import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Event } from 'data/commonTypes';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import EditTeamMemberModal from 'common/EditTeamMemberModal/EditTeamMemberModal';
import OrgForKanban from 'types/OrgForKanban';
import TeamMember from 'types/TeamMember';
import OrgService from 'services/api/OrgService';
import IconButton from 'common/IconButton/IconButton';
import PermissionService from 'services/PermissionService';

export default function OrgAdminSection(): React.ReactElement {
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const [orgDetails, setOrgDetails] = useRecoilState(OrgDetailsState);
  const isNewsieAdminTeam = PermissionService.isNewsieAdmin(cdsid);
  const [orgAdminEditMode, setOrgAdminEditMode] = useState<boolean>(false);

  function updateOrg(field: string, event: Event, content: string | Array<TeamMember>) {
    if (orgDetails && orgDetails.id) {
      const orgToUpdate: OrgForKanban = { ...orgDetails, [field]: content };
      saveOrgTeamMembers(orgToUpdate);
    }
  }

  function saveOrgTeamMembers(newOrgDetails: OrgForKanban) {
    OrgService.updateOrgTeamMembers(orgDetails.id, newOrgDetails.teamMembers).then(() => {
      setOrgDetails(newOrgDetails);
    });
  }

  return (
    <>
      <div className="details-box">
        <div className="box-header">
          Org Admins
          {isNewsieAdminTeam && (
            <IconButton
              iconName="pen"
              testId="edit-org-team"
              ariaLabel="edit org team members"
              onClick={() => setOrgAdminEditMode(true)}
            />
          )}
        </div>
        <div className="box-info">
          <ul className={'no-padding no-margin'}>
            {orgDetails.teamMembers?.map((teamMember: TeamMember) => {
              return (
                <li key={teamMember.cdsid}>
                  {teamMember.name} ({teamMember.cdsid})
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {orgAdminEditMode && (
        <EditTeamMemberModal
          updateTeam={updateOrg}
          title={'team member'}
          teamMembers={orgDetails.teamMembers ? orgDetails.teamMembers : null}
          setDialogIsOpen={setOrgAdminEditMode}
        />
      )}
    </>
  );
}
