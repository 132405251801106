import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { generateProductURL } from 'utils/utils';
import useSearchFilters from 'hooks/useSearchFilters';
import { useSetRecoilState } from 'recoil';
import FilteredProductCountState from 'state/FilteredProductCountState';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { styled } from '@mui/material';

import OrgForDropdown from 'types/OrgForDropdown';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

interface Props {
  productsForGroup: Product[];
  group: ProductHierarchy;
  organization: OrgForDropdown;
  linesForGroup: ProductHierarchy[];
}

export default function ProductsForOrgPageContentMobile({
  group,
  productsForGroup,
  organization,
  linesForGroup,
}: Props): React.ReactElement {
  const searchFilters = useSearchFilters();
  const setFilteredProductCount = useSetRecoilState(FilteredProductCountState);

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion elevation={0} square {...props} />
  ))(() => ({
    paddingBottom: '24px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <FontAwesomeIcon
          className="dropdown-caret menu"
          icon={'chevron-up' as IconName}
          color={'white'}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1rem',
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#097BBC',
    textTransform: 'uppercase',
    borderRadius: '10px',
    height: '64px',
    '&.Mui-expanded': {
      borderRadius: '10px 10px 0 0 !important',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#F5F5F5',
    borderRadius: '0px 0px 10px 10px',
  }));

  useEffect(() => {
    setFilteredProductCount((prev) => prev + productsForGroup.length);
    return () => {
      setFilteredProductCount((prev) => prev - productsForGroup.length);
    };
  }, [searchFilters]);

  function printProductsForLine(productsInLine: Product[]): React.ReactElement[] {
    return productsInLine.map((product) => (
      <li key={`products-for-line-${product.id}`}>
        <Link
          className="product-name standard-link"
          data-testid="product-name"
          to={`${generateProductURL(organization.url, product)}/profile`}
        >
          {product.name}
        </Link>
      </li>
    ));
  }

  function LinesWithProducts(): React.ReactElement {
    return (
      <>
        {linesForGroup.map((line: ProductHierarchy) => {
          const productsInLine = productsForGroup.filter(
            (product) => line.id === product.productLineId,
          );
          return (
            <Fragment key={`line-with-products-${line.id}`}>
              {productsInLine.length > 0 && (
                <div data-testid={`product-line-${line.id}`}>
                  <Link
                    className="product-line-name standard-link"
                    data-testid="product-line"
                    to={`/${organization.url}/line/${encodeURIComponent(
                      line.id,
                    )}/${encodeURIComponent(line.name)}/profile`}
                  >
                    {line.name}
                  </Link>
                  <ul className="product-list with-bullets">
                    {printProductsForLine(productsInLine)}
                  </ul>
                </div>
              )}
            </Fragment>
          );
        })}
      </>
    );
  }

  function groupAccordian(group: ProductHierarchy) {
    return (
      <Accordion
        defaultExpanded={true}
        onChange={() => {
        }}
      >
        <AccordionSummary data-testid="product-group-name">{group?.name}</AccordionSummary>
        <AccordionDetails>{LinesWithProducts()}</AccordionDetails>
      </Accordion>
    );
  }

  return <>{productsForGroup && productsForGroup.length > 0 && groupAccordian(group)}</>;
}
