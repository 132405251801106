import React, { Fragment, useEffect, useState } from 'react';
import { extractErrorMessage } from 'utils/utils';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import NoUpdatesMessage from './NoUpdatesMessage/NoUpdatesMessage';
import { Loaded, PageLoad } from '../ProductTabs';
import { SectionComponent } from './SectionComponent/SectionComponent';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorTag from 'common/ErrorTag/ErrorTag';
import AddUpdateModal from './AddUpdateModal/AddUpdateModal';
import ConfirmationModal from 'common/ConfirmationModal/DepricatedConfirmationModal/ConfirmationModal';
import IsContentChangedState from 'state/ContentChangedState';
import UpdateCalendar from './UpdateCalendar/UpdateCalendar';
import UpdatePageButtons, { VisibleViewButtons } from './UpdatePageButtons/UpdatePageButtons';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { GetPageTemplateByPageAndTemplateType } from 'state/OrgDetailsState';
import { usePrompt } from 'hooks/usePrompt';
import ProductPageDatesState from 'state/ProductPageDatesState';
import PageService from 'services/api/PageService';

import Page, { PageSection, PageType, ParentType } from 'types/Page';
import PermissionService from '../../../../../services/PermissionService';

import './UpdateTab.scss';
import ErrorMessageState from "../../../../../state/ErrorMessageState";

export enum Actions {
  view,
  edit,
  add,
}

export enum TrackUpdateAction {
  VIEW_UPDATE = 'view update',
  VIEW_HISTORICAL_UPDATE = 'view historical update',
  START_NEW_UPDATE = 'start new update',
  START_EDIT_UPDATE = 'start edit update',
  SAVE_NEW_UPDATE = 'save new update',
  SAVE_EDITED_UPDATE = 'save edited update',
}

interface Props {
  isDesktopAndTeamMemberOrAdmin: boolean;
  productId: string;
}

export default function Updates({
  productId,
  isDesktopAndTeamMemberOrAdmin,
}: Props): React.ReactElement {
  const [pageDatesState, setPageDatesState] = useRecoilState(ProductPageDatesState);
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const [isContentChanged, setIsContentChanged] = useRecoilState(IsContentChangedState);
  const currentProduct = useRecoilValue(CurrentProductSelector(productId));
  const updatePageTemplate = useRecoilValue(
    GetPageTemplateByPageAndTemplateType({
      pageType: PageType.UPDATE,
      templateType: ParentType.PRODUCT,
    }),
  );
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  const [pageLoad, setPageLoad] = useState<PageLoad>({ state: 'Loading' });
  const [showAddNewUpdateModal, setShowAddNewUpdateModal] = useState<boolean>(false);
  const [action, setAction] = useState(Actions.view);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  usePrompt(
    'There are unsaved changes that will be lost. Are you sure you want to leave?',
    isContentChanged,
  );

  useEffect(() => {
    if (!errorMessage.caughtError) {
      setErrorMessage({ ...errorMessage, caughtError: false });
    }
  }, []);

  useEffect(() => {
    if (
      currentProduct.id !== '' &&
      pageDatesState.state === 'Loaded' &&
      pageDatesState.parentId === currentProduct.id
    ) {
      document.title = `${currentProduct.name} | Update | Newsie`;
      setAction(Actions.view);
      setUpdateForPage();
    }
  }, [currentProduct.id, pageDatesState.state]);

  useEffect(() => {
    if (action === Actions.view) initializePage();
    //need initialize for add new update
    if (action === Actions.add) {
      initializeAddUpdatePage((pageLoad as Loaded).page);
    }
  }, [selectedDate]);

  function initializeAddUpdatePage(page: Page | undefined) {
    let newSections: PageSection[];
    const newPage = {
      parentId: currentProduct.id,
      date: selectedDate,
      pageType: PageType.UPDATE,
      parentType: ParentType.PRODUCT,
    };
    if (updatePageTemplate) {
      newSections = updatePageTemplate.sections.map((sectionTemplate: PageSection) => {
        const sectionContent = page
          ? page.sections.find((section) => section.title === sectionTemplate.title)?.content
          : sectionTemplate.content;
        return {
          title: sectionTemplate.title,
          content: sectionContent ?? '',
        };
      });
      setPageLoad({ state: 'Loaded', page: { ...newPage, sections: newSections } });
    } else {
      setPageLoad({
        state: 'Error',
        message: 'Update template is undefined in org details',
      });
    }
  }

  async function initializePage() {
    if (
      !selectedDate &&
      pageDatesState.state === 'Loaded' &&
      pageDatesState.pageDates.length === 0
    ) {
      setPageLoad({ state: 'Loaded', page: undefined });
      return;
    }
    if (selectedDate) {
      setPageLoad({ state: 'Loading' });
      await PageService.getProductUpdatePage(currentProduct.id, selectedDate)
        .then((page) => setPageLoad({ state: 'Loaded', page }))
        .catch((e) =>
          setPageLoad({
            state: 'Error',
            message: extractErrorMessage(
              e,
              `Error getting update page for Product ${currentProduct.id}`,
            ),
          }),
        );
    }
  }

  function setUpdateForPage() {
    if (pageDatesState.state === 'Loaded' && pageDatesState.pageDates.length > 0) {
      if (pageDatesState.pageDates[0] === selectedDate) {
        initializePage();
      } else {
        setSelectedDate(pageDatesState.pageDates[0]);
      }
    } else {
      setSelectedDate('');
    }
  }

  function doCancel() {
    setShowConfirmationModal(false);
    setIsContentChanged(false);
    setAction(Actions.view);
    setUpdateForPage();
  }

  function displayUpdateSections(sections: PageSection[]): React.ReactElement {
    function setNewContent(content: string, title: string) {
      sections.forEach((section) => {
        if (section.title === title) {
          section.content = content;
          return;
        }
      });
    }

    return (
      <div className="update-container-flex">
        {sections.map((section: PageSection, key) => {
          return (
            <Fragment key={key}>
              <SectionComponent
                section={section}
                action={action}
                setSectionContent={setNewContent}
                setDisabled={setDisabled}
                templateType={ParentType.PRODUCT}
              />
            </Fragment>
          );
        })}
      </div>
    );
  }

  function displayUpdateTab(
    page: Page | undefined,
    pageDatesForProduct: string[],
  ): React.ReactElement {
    function formatToSortDate(week: string): string {
      return moment(week, 'w-gggg').format('YYYY/MM/DD');
    }
    function addNewDateToState(date: string): void {
      const newPageDates = [date];
      if (pageDatesForProduct && pageDatesForProduct.length > 0) {
        newPageDates.push(...pageDatesForProduct);
        if (
          formatToSortDate(newPageDates[0]).localeCompare(formatToSortDate(newPageDates[1])) < 0
        ) {
          newPageDates.sort((obj1: string, obj2: string) => {
            return formatToSortDate(obj2).localeCompare(formatToSortDate(obj1));
          });
        }
      }
      setPageDatesState({ state: 'Loaded', pageDates: newPageDates, parentId: currentProduct.id });
    }

    async function savePage(): Promise<Page> {
      if (action === Actions.edit) {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return PageService.editPage(page!.id!, page!.sections);
      }
      //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      addNewDateToState(page!.date!);
      //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return PageService.addPage(page!).then((savedPage) => {
        setPageLoad({ state: 'Loaded', page: savedPage });
        return savedPage;
      });
    }

    function displayUpdateControls(): React.ReactElement {
      function isEditable(): boolean {
        return isDesktopAndTeamMemberOrAdmin && currentProduct.done !== true;
      }

      function determineVisibleViewButtons(): VisibleViewButtons {
        if (PermissionService.isNewsieAdmin(cdsid) || pageDatesForProduct[0] === selectedDate)
          return VisibleViewButtons.ADD_AND_EDIT;
        else if (pageDatesForProduct[1] === selectedDate) return VisibleViewButtons.EDIT;
        return VisibleViewButtons.NONE;
      }

      return (
        <>
          {(pageDatesForProduct.length > 0 || selectedDate) && (
            <div className="update-controls">
              <UpdateCalendar
                datesForPage={pageDatesForProduct}
                action={action}
                selectedDate={selectedDate ?? ''}
                setSelectedDate={setSelectedDate}
              />
              {isEditable() && (
                <UpdatePageButtons
                  savePage={savePage}
                  action={action}
                  setShowAddNewUpdateModal={setShowAddNewUpdateModal}
                  disabled={disabled}
                  setShowConfirmationModal={setShowConfirmationModal}
                  setAction={setAction}
                  visibleViewButtons={determineVisibleViewButtons()}
                />
              )}
            </div>
          )}
        </>
      );
    }

    return (
      <div className="updates tab-content-container">
        {displayUpdateControls()}
        <ErrorTag />
        {page && displayUpdateSections(page.sections)}
        {!page && action === Actions.view && (
          <NoUpdatesMessage
            setShowAddNewUpdateModal={setShowAddNewUpdateModal}
            isDesktopAndTeamMemberOrAdmin={isDesktopAndTeamMemberOrAdmin}
          />
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            showModal={showConfirmationModal}
            setShowModal={setShowConfirmationModal}
            exitWithoutSaving={doCancel}
          />
        )}
        <AddUpdateModal
          datesForPage={pageDatesForProduct}
          setActionToAdd={() => setAction(Actions.add)}
          setSelectedDate={setSelectedDate}
          isEditing={showAddNewUpdateModal}
          setIsEditing={setShowAddNewUpdateModal}
        />
      </div>
    );
  }

  switch (pageLoad.state) {
    case 'Loading':
      return <LoadingMessage />;
    case 'Loaded':
      if (
        pageDatesState.state === 'Loaded' &&
        (!pageLoad.page || pageLoad.page.parentId === currentProduct.id)
      )
        return displayUpdateTab(pageLoad.page, pageDatesState.pageDates);
      return <></>;
    case 'Error':
      return <ErrorMessageView />;
  }
}
