import axios from 'axios';
import { Metric } from 'web-vitals';
import TokenService from 'services/TokenService';

export default function reportMetricsToBackend(metrics: Metric): void {
  metrics.entries = [];
  if (TokenService.getToken()) {
    if (metrics.name !== 'TTFB') {
      axios.post('/api/metrics', metrics);
    }
  }
}
