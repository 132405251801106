import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AccessTokenState } from 'state/AccessTokenState';
import { ProductsState, ProductsStateType } from 'state/ProductsForOrgState';
import { extractErrorMessage } from '../utils/utils';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { useParams } from 'react-router-dom';
import { ShowNavState } from 'state/ShowNavState';
import ProductHierarchyState, { ProductHierarchyByTypeInState } from 'state/ProductHierarchyState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import Product from 'types/Product';
import ProductService from 'services/api/ProductService';

interface Props {
  products: ProductsStateType;
}

export default function useProductsContext(): Props {
  const [products, setProducts] = useRecoilState(ProductsState);
  const { org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const productHierarchyStateValue = useRecoilValue(ProductHierarchyState);
  const productGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const accessToken = useRecoilValue(AccessTokenState);
  const setShowNav = useSetRecoilState(ShowNavState);
  const orgDetails = useRecoilValue(OrgDetailsState);

  function getProductLinesByGroupId(groupId: string): ProductHierarchy[] {
    let productLines: ProductHierarchy[] = [];
    if (productHierarchyStateValue.state === 'Loaded') {
      const productHierarchies = productHierarchyStateValue.productHierarchy;
      productLines = productHierarchies.filter((ph) => ph.parentId === groupId);
    }
    return productLines;
  }

  function getSortedProduct(productsMap: Map<string, Product[]>): Product[] {
    const sortedProducts: Product[] = [];
    if (productHierarchyStateValue.state === 'Loaded') {
      productGroups.forEach((productGroup) => {
        const productLines = getProductLinesByGroupId(productGroup.id);
        productLines.forEach((pl) => {
          if (productsMap.get(pl.id)) {
            // @ts-ignore
            sortedProducts.push(...productsMap.get(pl.id));
          }
        });
      });
    }
    return sortedProducts;
  }

  async function getProductsFromAPI() {
    setProducts({ state: 'Loading' });
    setShowNav(false);
    await ProductService.getProductsForOrg(organization.id)
      .then((productsMap) => {
        const sortedProducts = getSortedProduct(productsMap);
        const productsToSet: ProductsStateType = {
          state: 'Loaded',
          products: sortedProducts,
          orgId: organization.id,
        };
        setShowNav(true);
        setProducts(productsToSet);
      })
      .catch((error) => {
        setProducts({
          state: 'Error',
          message: extractErrorMessage(error, `Error getting products for ${organization.id}`),
        });
        setShowNav(true);
      });
  }

  useEffect(() => {
    if (
      accessToken &&
      organization.id &&
      orgDetails.id === organization.id &&
      productHierarchyStateValue.state === 'Loaded' &&
      productHierarchyStateValue.orgId === organization.id &&
      (products.state !== 'Loaded' ||
        (products.state === 'Loaded' && products.orgId !== organization.id))
    ) {
      getProductsFromAPI();
    }
  }, [accessToken, organization.id, orgDetails.id, productHierarchyStateValue]);

  return { products };
}
