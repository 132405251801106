import React from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { Navigate, Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { ShowNavState } from 'state/ShowNavState';

import TokenService from 'services/TokenService';

import ScrollToTop from 'common/ScrollToTop/ScrollToTop';
import Wrapper from 'common/Wrapper/Wrapper';
import ExperimentBanner from 'common/ExperimentBanner/ExperimentBanner';
import Nav from 'common/Nav/Nav';
import Modal from 'common/Modal/Modal';

import ViewAllOrgsPage from 'pages/view-all-org-page/ViewAllOrgsPage';
import HomePage from 'pages/home-page/HomePage';
import ArchivedProducts from 'pages/archived-products-page/ArchivedProductPage/ArchivedProducts';
import OrgPage from 'pages/org-page/OrgPage';
import ProductPage from 'pages/product-page/ProductPage';
import ProductLinePage from 'pages/product-line-page/ProductLinePage';
import OrgDetailsPage from 'pages/org-details/OrgDetailsPage';

import 'styles/App.scss';

const baseURL = getBaseUrl();
axios.defaults.baseURL = baseURL;

function getBaseUrl(): string | undefined {
  if (process.env.NODE_ENV === 'test') {
    return 'http://localhost:3000';
  }

  if (process.env.NODE_ENV !== 'development') {
    return process.env.REACT_APP_API_URL;
  }

  return undefined;
}

axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = 'Bearer ' + TokenService.getToken();
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      TokenService.clearToken();
      window.location.reload();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  },
);

library.add(fas);
library.add(far);

export default function App(): React.ReactElement {
  const showNav = useRecoilValue(ShowNavState);

  return (
    <Wrapper>
      <ScrollToTop />
      <a tabIndex={0} className="skip-link" href="#main-content">
        Skip to main content
      </a>
      <ExperimentBanner />
      {showNav && <Nav />}
      <main id="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all" element={<ViewAllOrgsPage />} />
          <Route path=":org">
            <Route path="" element={<OrgPage />} />
            <Route path="line/:lineId/:line">
              {['info', 'products', 'profile'].map((path) => (
                <Route path={path} element={<ProductLinePage />} key={path} />
              ))}
            </Route>
            <Route path=":productId/:productName">
              {['profile', 'update', 'info'].map((path) => (
                <Route path={path} element={<ProductPage />} key={path} />
              ))}
            </Route>
            <Route path="archived" element={<ArchivedProducts />} />
            <Route path="details" element={<OrgDetailsPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
        <Modal />
      </main>
    </Wrapper>
  );
}
