import Cookies from 'universal-cookie';

const cookies = new Cookies();
const TokenService = {
  setToken: (accessToken: string): void => {
    cookies.set('NEWSIE_API_TOKEN', accessToken, {
      path: '/',
      secure: true,
      sameSite: 'lax',
    });
    window.sessionStorage.setItem('access_token', accessToken);
  },

  setExpiry: (expiresAt: number): void => {
    window.sessionStorage.setItem('expires_at', expiresAt.toString());
  },

  getExpiry: (): number => {
    return Number(window.sessionStorage.getItem('expires_at'));
  },

  getToken: (): string => {
    return window.sessionStorage.getItem('access_token') || '';
  },

  clearToken: (): void => {
    cookies.remove('NEWSIE_API_TOKEN', {
      path: '/',
      secure: true,
      sameSite: 'lax',
    });
    window.sessionStorage.removeItem('access_token');
    window.sessionStorage.removeItem('expires_at');
  },
};

export default TokenService;
