import React, { FormEvent } from 'react';

import './ModalContentsTemplate.scss';

interface Props {
  title: string;
  subtitle?: string;
  onSubmit(event: FormEvent<HTMLFormElement>): void;

  content: React.ReactElement[] | React.ReactElement;
  buttons: React.ReactElement[] | React.ReactElement;
  testId?: string;
  className?: string;
  id?: string;
}

function ModalContentsTemplate(props: Props) {
  const { title, subtitle, onSubmit, className, id, content, buttons } = props;

  return (
    <form
      id={id}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(e);
      }}
      className={'modal-contents-template ' + className}
    >
      <div className="modal-contents-template-body">
        <div className="modal-contents-template-title bold">{title}</div>
        {subtitle && <p className="modal-contents-template-text">{subtitle}</p>}
        {content}
      </div>
      <div className="modal-contents-template-footer">{buttons}</div>
    </form>
  );
}

export default ModalContentsTemplate;
