import React from 'react';
import ProductLines from './ProductLines/ProductLines';
import useSearchFilters from 'hooks/useSearchFilters';
import OrgForDropdown from 'types/OrgForDropdown';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

import './ProductLineGroup.scss';

interface Props {
  productsInOrg: Product[];
  organization: OrgForDropdown;
  productGroup: ProductHierarchy;
  stagesMap?: Map<string, number>;
}

export default function ProductLineGroup({
  productsInOrg,
  organization,
  productGroup,
  stagesMap,
}: Props): React.ReactElement {
  const productLines = useSearchFilters().filteredLines.filter(
    (line) => line.parentId === productGroup.id,
  );

  const productLineIds = productLines.map((line) => line.id);
  const productsInProductGroup = productsInOrg.filter((product) =>
    productLineIds.includes(product.productLineId),
  );

  return productsInProductGroup.length > 0 ? (
    <div className="product-line-group gray" key={productGroup.id} data-testid="product-group">
      <div className="product-group">
        <div className="display-name">{productGroup?.name}</div>
      </div>
      <ProductLines
        productLines={productLines}
        productsInGroup={productsInProductGroup}
        orgUrl={organization.url}
        stagesMap={stagesMap}
      />
    </div>
  ) : (
    <></>
  );
}
