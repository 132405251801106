import React from 'react';
import { generateProductURL } from 'utils/utils';
import Link from 'common/Link/Link';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

interface Props {
  productLine: ProductHierarchy;
  products: Product[];
  orgUrl: string;
}

export default function ArchivedProductLineWithProducts({
  productLine,
  products,
  orgUrl,
}: Props): React.ReactElement {
  return (
    <>
      <div className={'archived-product-line'}>{productLine ? productLine.name : ''}</div>
      {products.map((product, key) => {
        return (
          <Link
            key={`key-${key}`}
            className="product-name"
            data-testid="product-name"
            to={`${generateProductURL(orgUrl, product)}/profile`}
          >
            {product.name}
          </Link>
        );
      })}
    </>
  );
}
