import React from 'react';
import Modal from 'common/Modal/DepricatedModal/Modal';

interface Props {
  showModal: boolean;
  setShowModal: (editing: boolean) => void;
  exitWithoutSaving: () => void;
}
export default function ConfirmationModal({
  showModal,
  setShowModal,
  exitWithoutSaving,
}: Props): React.ReactElement {
  return (
    <Modal
      title="Unsaved Changes!"
      showCloseIcon={true}
      isOpenModal={showModal}
      closeHandler={() => setShowModal(false)}
    >
      <div>
        There are <b>unsaved</b> changes that will be lost. If you would like to save the changes,
        press the &quot;Continue Editing&quot; button.
        <div className="confirmation-button-container">
          <button
            className="button-blue"
            data-testid="continue-button"
            id="update-edit-continue"
            onClick={() => setShowModal(false)}
          >
            Continue Editing
          </button>
          <button
            className="button-red"
            data-testid="exit-button"
            id="update-edit-cancel"
            onClick={exitWithoutSaving}
          >
            Exit Without Saving
          </button>
        </div>
      </div>
    </Modal>
  );
}
