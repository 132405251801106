import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CurrentOrgSelector,
  OrgsForDropDownState,
  OrgsForDropDownStateType,
} from 'state/OrgsForDropDownState';
import { extractErrorMessage } from '../utils/utils';
import { useParams } from 'react-router';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import OrgForDropdown from 'types/OrgForDropdown';
import OrgForKanban from 'types/OrgForKanban';
import OrgService from 'services/api/OrgService';

interface Props {
  orgsForDropDown: OrgsForDropDownStateType;
  organization: OrgForDropdown;
  orgDetails: OrgForKanban;
}

function useOrgsForDropDown(): Props {
  const { org = '' } = useParams();
  const [orgsForDropDown, setOrgsForDropDown] = useRecoilState(OrgsForDropDownState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const [orgDetails, setOrgDetails] = useRecoilState(OrgDetailsState);

  function getOrgDetails(orgId: string) {
    OrgService.getOrgDetailsByOrgId(orgId)
      .then(setOrgDetails)
      .catch(() => {
        setOrgDetails({} as OrgForKanban);
      });
  }

  useEffect(() => {
    if (cdsid && orgsForDropDown.state !== 'Loaded') {
      OrgService.getAllOrganizations()
        .then((organizations) => {
          setOrgsForDropDown({
            state: 'Loaded',
            organizations: [...organizations].sort((obj1: OrgForDropdown, obj2: OrgForDropdown) => {
              return obj1.name.localeCompare(obj2.name);
            }),
          });
        })
        .catch((e) => {
          setOrgsForDropDown({
            state: 'Error',
            message: extractErrorMessage(e, `Error getting orgs for drop down: ${e}`),
          });
        });
    }
  }, [orgsForDropDown, cdsid]);

  useEffect(() => {
    if (cdsid && organization.id && organization.id !== orgDetails.id) {
      getOrgDetails(organization.id);
    }
  }, [organization.id, cdsid]);

  return { orgsForDropDown, organization, orgDetails };
}

export default useOrgsForDropDown;
