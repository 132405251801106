import React, { Fragment } from 'react';
import ArchivedProductLineWithProducts from './ArchivedProductLineWithProducts/ArchivedProductLineWithProducts';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { ProductsToLineMap } from '../ArchivedProducts';
import { ProductHierarchyByTypeInState } from 'state/ProductHierarchyState';
import OrgForDropdown from 'types/OrgForDropdown';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import Product from 'types/Product';

interface Props {
  org: string;
  archivedProducts: Product[];
}
function Groups({ org, archivedProducts }: Props) {
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const productGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const productLines = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.LINE));

  const linesToProductsMap: Map<string, ProductsToLineMap> = mapProductsToLines(archivedProducts);
  const groupsForArchivedProducts = filterProductGroups(linesToProductsMap);

  function mapProductsToLines(archivedProducts: Product[]): Map<string, ProductsToLineMap> {
    const archivedProductLines = new Map<string, ProductsToLineMap>();
    archivedProducts.forEach((product) => {
      const lineForProduct = productLines.find((line) => line.id === product.productLineId);
      if (lineForProduct) {
        const lineToProductsFromMap = archivedProductLines.get(lineForProduct.id);
        archivedProductLines.set(lineForProduct.id, {
          line: lineForProduct,
          products: lineToProductsFromMap
            ? [...lineToProductsFromMap.products, product]
            : [product],
        });
      }
    });
    return archivedProductLines;
  }
  function filterProductGroups(
    linesToProductsMap: Map<string, ProductsToLineMap>,
  ): ProductHierarchy[] {
    const result = new Set<ProductHierarchy>();
    linesToProductsMap.forEach((value) => {
      const productGroup = productGroups.find((group) => value.line.parentId === group.id);
      if (productGroup) result.add(productGroup);
    });
    return Array.from(result.values());
  }

  function ProductsForGroup(
    groupId: string,
    linesToProductsMap: Map<string, ProductsToLineMap>,
    organization: OrgForDropdown,
  ): React.ReactElement[] {
    const productsWithLine: React.ReactElement[] = [];
    linesToProductsMap.forEach((productsToLineMap) => {
      if (productsToLineMap.line.parentId === groupId) {
        productsWithLine.push(
          <Fragment key={`archived-product-line-with-products-${productsToLineMap.line.id}`}>
            <ArchivedProductLineWithProducts
              productLine={productsToLineMap.line}
              products={productsToLineMap.products}
              orgUrl={organization.url}
            />
            ,
          </Fragment>,
        );
      }
    });
    return productsWithLine;
  }

  return (
    <>
      {groupsForArchivedProducts.map((group: ProductHierarchy) => {
        return (
          <div key={group.id} className="product-group-content">
            <div className="bold product-group-name" data-testid="product-group-name">
              {group.name}
            </div>
            {ProductsForGroup(group.id, linesToProductsMap, organization)}
          </div>
        );
      })}
    </>
  );
}

export default Groups;
