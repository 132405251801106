import React, { Fragment, ReactNode, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Event } from 'data/commonTypes';
import EditTeamMemberModal from 'common/EditTeamMemberModal/EditTeamMemberModal';

import TeamMember from 'types/TeamMember';
import { IsOrgAdminSelector, IsUserATeamMemberOrNewsieAdminSelector } from 'state/AccessTokenState';
import IsDesktopState from 'state/IsDesktopState';
import TeamMemberComponent from './TeamMember/TeamMemberComponent';
import EditPencil from '../EditPencil/EditPencil';

interface Props {
  title: 'TEAM' | 'TEAM LEADS';
  teamMembers: TeamMember[] | null;
  updateContent: (field: string, event: Event, content: string | Array<TeamMember>) => void;
  location: 'line' | 'product';
  noTeamMembersView?: ReactNode;
}

export default function TeamMembers(props: Props): React.ReactElement {
  const { title, teamMembers, updateContent, location, noTeamMembersView } = props;

  const isDesktop = useRecoilValue(IsDesktopState);
  const isUserATeamMemberOrNewsieAdmin = useRecoilValue(
    IsUserATeamMemberOrNewsieAdminSelector(teamMembers),
  );
  const IsOrgAdmin = useRecoilValue(IsOrgAdminSelector);
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  function getTitle() {
    return location === 'line' ? 'team lead' : 'team member';
  }

  const onClick = () => {
    setDialogIsOpen(true);
  };

  return (
    <div className={`team-members ${isDesktop ? '' : 'content-box'}`}>
      {dialogIsOpen && (
        <EditTeamMemberModal
          title={getTitle()}
          updateTeam={updateContent}
          teamMembers={teamMembers}
          setDialogIsOpen={setDialogIsOpen}
        />
      )}
      <div className="team-members-header-container">
        <h5 className="bold" data-testid="teamMemberTitle">
          {title}
        </h5>
        {(isUserATeamMemberOrNewsieAdmin || IsOrgAdmin) && (
          <EditPencil
            onClick={onClick}
            dataTestID={'edit-team-members'}
            ariaLabel={'edit team members'}
          />
        )}
      </div>
      <ul className="team-member-list">
        {!isUserATeamMemberOrNewsieAdmin && teamMembers === null && noTeamMembersView}
        {teamMembers &&
          teamMembers.map((teamMember, index) => {
            return (
              <Fragment key={index}>
                <TeamMemberComponent
                  teamMember={teamMember}
                  location={location}
                />
              </Fragment>
            );
          })}
      </ul>
    </div>
  );
}
