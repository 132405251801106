import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-common-types';
import React, { MouseEventHandler } from 'react';

import styles from './TextFieldTooltip.module.scss';

interface Props {
  className?: string;
  dataTestid?: string;
  onMouseEnter?: MouseEventHandler;
}

export default function TextFieldTooltip({ className = '', dataTestid, onMouseEnter }: Props) {
  return (
    <span
      data-testid={dataTestid}
      className={`${styles.textFieldTooltip} ${className}`}
      onMouseEnter={onMouseEnter}
    >
      <FontAwesomeIcon icon={'info-circle' as IconName} />
    </span>
  );
}
