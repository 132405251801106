import { atom, selector } from 'recoil';

const TextToSearchState = atom<string>({
  key: 'TextToSearchState',
  default: extractSearchFilterValue(),
});

export enum Criteria {
  LINE = 'LINE',
  PRODUCT = 'PRODUCT',
  GROUP = 'GROUP',
  STAGE = 'STAGE',
  NONE = 'NONE',
}

export type SearchCriteriaValue = {
  criteria: Criteria;
  value: string;
};

export const GetSearchCriteriaWithValueSelector = selector({
  key: 'GetSearchCriteriaWithValueSelector',
  get: ({ get }) => {
    const textToSearch = get(TextToSearchState);
    const searchCriteriaValue: SearchCriteriaValue = {
      criteria: Criteria.NONE,
      value: textToSearch.trim(),
    };
    const index = textToSearch.indexOf(':');
    if (index >= 0) {
      const searchCriteria: string = textToSearch.substring(0, index).trim().toLocaleLowerCase();
      const searchValue: string = textToSearch.substring(index + 1).trim();
      switch (searchCriteria) {
        case Criteria.GROUP.toLocaleLowerCase():
          return { criteria: Criteria.GROUP, value: searchValue };
        case Criteria.LINE.toLocaleLowerCase():
          return { criteria: Criteria.LINE, value: searchValue };
        case Criteria.STAGE.toLocaleLowerCase():
          return { criteria: Criteria.STAGE, value: searchValue };
        case Criteria.PRODUCT.toLocaleLowerCase():
          return { criteria: Criteria.PRODUCT, value: searchValue };
        case Criteria.NONE.toLocaleLowerCase():
        default:
          return searchCriteriaValue;
      }
    }
    return searchCriteriaValue;
  },
});

export function extractSearchFilterValue() {
  const search = window.location.search || '';
  const searchParams = new URLSearchParams(search.replace('?', ''));
  return searchParams.get('searchFilter') || '';
}

export default TextToSearchState;
