import RelevantLink from './RelevantLink';
import TeamMember from './TeamMember';

export enum HierarchyType {
  GROUP = 'GROUP',
  LINE = 'LINE',
}

// @todo rename to ProductGroupsAndLines throughout codebase
export default interface ProductHierarchy {
  id: string;
  orgId: string;
  type: HierarchyType;
  order?: number;
  name: string;
  relevantLinks?: RelevantLink[];
  teamMembers: TeamMember[] | null;
  parentId?: string;
}

// @Todo rename to ProductHierarchy
export interface AddProductHierarchyRequest {
  orgId: string;
  group?: ProductHierarchy;
  lines: ProductHierarchy[];
}
