import React, { useEffect } from 'react';

import styles from './UnsavedChanges.module.scss';

interface Props {
  continueHandler: () => void;
  exitHandler: () => void;

  showTitle?: boolean; // temporary
}

export function UnsavedChanges(props: Readonly<Props>): React.ReactElement {
  const { continueHandler, exitHandler, showTitle } = props;

  useEffect(() => {
    setModalCloseIconVisibility('none');

    return () => {
      setModalCloseIconVisibility('initial');
    };
  }, []);

  function setModalCloseIconVisibility(displayValue: 'none' | 'initial') {
    document.querySelector('#modalCloseButton')?.setAttribute('style', `display: ${displayValue}`);
  }

  return (
    <form
      id="modal-action-message"
      onSubmit={(e) => {
        e.preventDefault();
        continueHandler();
      }}
    >
      {showTitle && <h5 className={styles.title}>Unsaved Changes!</h5>}
      <span>
        There are <b>unsaved</b> changes that will be lost. If you would like to save the changes,
        press the &quot;Continue Editing&quot; button.
      </span>
      <div className="modal-button-container">
        <button className="button-blue" type="submit">
          Continue Editing
        </button>
        <button className="button-red" onClick={exitHandler} type="button">
          Exit Without Saving
        </button>
      </div>
    </form>
  );
}
