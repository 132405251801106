import React, { useEffect, useState } from 'react';
import { Error as ErrorState, Loading } from 'data/commonTypes';
import useProductsContext from 'hooks/useProductsContext';
import { extractErrorMessage, generateProductURL } from 'utils/utils';
import Link from 'common/Link/Link';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';

import OrgForDropdown from 'types/OrgForDropdown';
import ProductHierarchy from 'types/ProductHierarchy';
import Product, { ProductsWithDescriptionForLine } from 'types/Product';
import ProductService from 'services/api/ProductService';

import './ActiveProductsTab.scss';

interface Props {
  productLine: ProductHierarchy;
  organization: OrgForDropdown;
}

type Loaded = {
  state: 'Loaded';
  productsDescriptionForLine: Map<string, ProductsWithDescriptionForLine>;
};
type ProductDescriptionForLineState = Loading | Loaded | ErrorState;

export default function ActiveProductsTab({
  productLine,
  organization,
}: Props): React.ReactElement {
  const { products } = useProductsContext();
  const [productsDescriptionForLineState, setProductsDescriptionForLineState] =
    useState<ProductDescriptionForLineState>({ state: 'Loading' });

  const activeProducts: Product[] =
    products.state === 'Loaded'
      ? products.products.filter(
          (product: Product) => !product.done && product.productLineId === productLine.id,
        )
      : [];

  useEffect(() => {
    if (organization.id && productLine.id && products.state === 'Loaded') {
      setProductsDescriptionForLineState({ state: 'Loading' });
      if (activeProducts.length > 0) {
        ProductService.getProductsDescriptionForLinePage(organization.id, productLine.id)
          .then((productsDescriptionForLineMap) => {
            setProductsDescriptionForLineState({
              state: 'Loaded',
              productsDescriptionForLine: productsDescriptionForLineMap,
            });
          })
          .catch((e) =>
            setProductsDescriptionForLineState({
              state: 'Error',
              message: extractErrorMessage(e, 'A technical error occurred:' + e),
            }),
          );
      } else {
        setProductsDescriptionForLineState({
          state: 'Loaded',
          productsDescriptionForLine: new Map(),
        });
      }
    }
  }, [productLine.id, organization.id, products.state]);

  function displayActiveProducts(
    productsDescriptionForLine: Map<string, ProductsWithDescriptionForLine>,
  ): React.ReactElement {
    return (
      <div className="active-products-tab content  tab-content-container">
        <div className="content-box">
          {activeProducts.length > 0 &&
            activeProducts.map((product) => (
              <div key={`active-team-${product.id}`} className="content">
                <Link
                  className="active-product-name"
                  to={`${generateProductURL(organization.url, product)}/profile`}
                  data-testid={`product-link-${product.id}`}
                >
                  {product.name}
                </Link>
                <p
                  className="product-description"
                  dangerouslySetInnerHTML={{
                    __html: productsDescriptionForLine?.get(product.id)?.description ?? '',
                  }}
                ></p>
              </div>
            ))}
          {activeProducts.length <= 0 && (
            <div>There are no active products under {productLine.name}</div>
          )}
        </div>
      </div>
    );
  }

  switch (productsDescriptionForLineState.state) {
    case 'Loading':
      return <LoadingMessage />;
    case 'Loaded':
      return displayActiveProducts(productsDescriptionForLineState.productsDescriptionForLine);
    case 'Error':
      return (
        <ErrorMessageView  />
      );
  }
}
