import React, { Fragment } from 'react';
import ProductLine from './ProductLine/ProductLine';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

interface Props {
  productLines: ProductHierarchy[];
  productsInGroup: Product[];
  orgUrl: string;
  stagesMap?: Map<string, number>;
}

export default function ProductLines({
  productLines,
  productsInGroup,
  orgUrl,
  stagesMap,
}: Props): React.ReactElement {
  return (
    <>
      {productLines.map((line, index) => {
        const productsInThisProductLine = productsInGroup.filter(
          (product) => product.productLineId === line.id,
        );
        if (productsInThisProductLine.length > 0) {
          return (
            <Fragment key={`product-line-${index}`}>
              <ProductLine
                stagesMap={stagesMap}
                orgUrl={orgUrl}
                line={line}
                productsInLine={productsInThisProductLine}
              />
            </Fragment>
          );
        }
      })}
    </>
  );
}
