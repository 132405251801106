import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams } from 'react-router';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import IconButton from 'common/IconButton/IconButton';
import { IsUserNewsieAdminSelector } from 'state/AccessTokenState';
import { ModalContentsState } from 'state/ModalContentsState';
import EditOverviewModal from './EditOverviewModal/EditOverviewModal';


export default function OrgOverviewSection() {
  const { org = '' } = useParams();

  const organization = useRecoilValue(CurrentOrgSelector(org));
  const isUserNewsieAdmin = useRecoilValue(IsUserNewsieAdminSelector);
  const setModalContents = useSetRecoilState(ModalContentsState);

  function onEditOverviewIconClick() {
    setModalContents({
      title: 'Edit Overview',
      component: <EditOverviewModal orgParam={org} />,
    });
  }

  return (
    <div className="details-box" data-testid="orgOverview">
      <div className="box-header">
        Overview
        {isUserNewsieAdmin && (
          <IconButton
            iconName="pen"
            testId="edit-org-overview"
            ariaLabel="edit org overview"
            onClick={onEditOverviewIconClick}
          />
        )}
      </div>
      <div className="box-info">
        <div>
          <span className="bold">Org Name:</span> {organization.name}
        </div>
        <div>
          <span className="bold">Org Description:</span> {organization.blurb}
        </div>
        <div>
          <span className="bold">Org Icon:</span> {organization.icon}
        </div>
      </div>
    </div>
  );
}
