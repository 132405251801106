import React from 'react';
import DashDivider from 'common/DashDivider/DashDivider';
import HomePageHello from 'images/home-page-hello.svg';

export default function TitleBanner() {
  return (
    <div className={'title-and-leader-image'}>
      <div className="title-text">
        <DashDivider />
        <h1>Turning status reports into your favorite morning coffee read!</h1>
        <p>
          Our goal is to make it easy for Ford product teams to share meaningful progress updates
          and product information.
        </p>
        <DashDivider className="desktop-only" />
      </div>
      <img
        src={HomePageHello}
        className="title-image"
        alt={'person reading macbook with coffee and plant'}
      />
    </div>
  );
}
