import moment, { Moment } from 'moment';

export function getLatestAddNewUpdateDate(existingDates: string[]): Moment {
  let nextAvailable: Moment = moment().startOf('week').add(1, 'days');
  const latestUpdate = moment(existingDates[0], 'w-gggg').add(1, 'day');
  if (existingDates.length !== 0 && latestUpdate >= nextAvailable) {
    nextAvailable = latestUpdate.add(1, 'week');
  }
  return nextAvailable;
}

export function shouldDisableDate(date: Moment, datesForPage: string[]): boolean {
  const monday = 1;
  const d = date.toDate();
  return d.getDay() !== monday || reportExistsForDate(datesForPage, d);
}

// example datesForPage: [ '1-2024', '2-2024' ] <-- [week of year]-[year]
export function reportExistsForDate(datesForPage: string[], d: Date): boolean {
  const existingDates = datesForPage.map((date) => new Date(formatWeekToFullDate(date)));
  return existingDates.some((existingDate) => existingDate.getTime() === d.getTime());
}

// date example: 1-2024 ([week of year]-[year])
export function formatWeekToFullDate(date?: string): string {
  if (date) {
    return moment(date, 'w-gggg').startOf('week').add(1, 'days').format('MM/DD/YYYY');
  }
  return '';
}
