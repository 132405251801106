import React, { useState } from 'react';
import GroupsAndLinesModalWrapper from '../../GroupsAndLinesModalWrapper/GroupsAndLinesModalWrapper';
import { TextField } from '@mui/material';
import ProductHierarchy from 'types/ProductHierarchy';
import ProductHierarchyService from 'services/api/ProductHierarchyService';

import { useRecoilState, useResetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import ProductHierarchyState, { Loaded } from 'state/ProductHierarchyState';

import styles from './EditGroupModal.module.scss';

interface GroupField {
  value: string;
  isError: boolean;
}

interface Props {
  group: ProductHierarchy;
}

export default function EditGroupModal({ group }: Props) {
  const closeModal = useResetRecoilState(ModalContentsState);

  const [productHierarchyState, setProductHierarchyState] = useRecoilState(ProductHierarchyState);
  const productHierarchy: ProductHierarchy[] =
    (productHierarchyState.state === 'Loaded' && productHierarchyState.productHierarchy) || [];

  const [groupField, setGroupField] = useState<GroupField>({ value: group.name, isError: false });

  function isFormUpdated() {
    return group.name !== groupField.value;
  }

  async function onSubmit() {
    if (!isFormUpdated()) {
      closeModal();
      return Promise.resolve();
    }

    try {
      const updatedGroup: ProductHierarchy = { ...group, name: groupField.value.trim() };
      const data = await ProductHierarchyService.editProductHierarchy(updatedGroup).then((res) => [
        res.data,
      ]);

      if (Array.isArray(data)) {
        setProductHierarchyState({
          ...productHierarchyState,
          productHierarchy: [...productHierarchy].map((item) => {
            return item.id === data[0].id ? data[0] : item;
          }),
        } as Loaded);
      }
      closeModal();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setGroupField((prevState) => ({ ...prevState, isError: true }));

      } else {
        throw error;
      }
    }
  }

  return (
    <GroupsAndLinesModalWrapper
      title="Edit Group"
      onSubmit={onSubmit}
      isFormPopulated={isFormUpdated}
      useEffectDeps={[groupField]}
    >
      <div className={styles.content}>
        <TextField
          label="Group Name"
          required
          defaultValue={groupField.value}
          onChange={(event) => setGroupField({ value: event.target.value, isError: false })}
          className={styles.textField}
          inputProps={{
            'data-testid': 'editGroupNameInput',
            title: `Enter Group Name`,
          }}
          error={groupField.isError}
          helperText={groupField.isError && 'There is already a Group with the same name'}
        />
      </div>
    </GroupsAndLinesModalWrapper>
  );
}
