import React from 'react';
import { useRecoilValue } from 'recoil';
import { ProductLinesForTheGivenGroupIdInState } from 'state/ProductHierarchyState';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

interface Props {
  product: Product;
  setProduct: (product: Product) => void;
  selectedProductGroupId: string;
}

export default function ProductLineDropDown({
  product,
  setProduct,
  selectedProductGroupId,
}: Props): React.ReactElement {
  const lines = useRecoilValue(ProductLinesForTheGivenGroupIdInState(selectedProductGroupId));

  function printProductLines(): React.ReactElement[] | undefined {
    if (selectedProductGroupId) {
      return lines.map((line: ProductHierarchy) => (
        <MenuItem key={line.id} value={line.id} data-testid="line-select-option">
          {line.name}
        </MenuItem>
      ));
    }
  }

  const handleChange = (event: SelectChangeEvent<typeof product.productLineId>) => {
    setProduct({
      ...product,
      productLineId: event.target.value,
    });
  };

  return (
    <FormControl variant="outlined" className="product-hierarchy-select">
      <InputLabel id="line-select-label">Line *</InputLabel>
      <Select
        className={`${selectedProductGroupId ? '' : 'gray-out-select'}`}
        inputProps={{ 'data-testid': 'product-line-select' }}
        labelId="line-select-label"
        label="Line *"
        value={product.productLineId ? product.productLineId : ''}
        required={true}
        disabled={!selectedProductGroupId}
        onChange={handleChange}
      >
        {printProductLines()}
      </Select>
    </FormControl>
  );
}
