import React from 'react';
import { Link, useParams } from 'react-router-dom';

function NoLeadsAddedDisplay() {
  const { org = '' } = useParams();

  return (
    <div>
      <b>No team leads added</b> <br />
      <span>
        Contact{' '}
        <Link to={`/${org}/details`}>
          your org admin
        </Link>{' '}
        to add a team lead.
      </span>
    </div>
  );
}

export default NoLeadsAddedDisplay;
