import ScrumBoard from 'images/scrum-board.png';
import React from 'react';
import DashDivider from 'common/DashDivider/DashDivider';
import FAQPageLink from 'common/FAQPageLink/FAQPageLink';

export default function TitleBanner() {
  return (
    <div className="title-and-leader-image">
      <div className="title-text">
        <DashDivider />
        <h1>Newsie Across Ford</h1>
        <p>
          Whether it’s for weekly product updates or in support of live software demos, for helping
          new team members onboard or for finding the right person to talk to about a product, many
          parts of Ford are using Newsie to help communicate progress. Learn how Newsie could help
          your organization on our <FAQPageLink />
          .
          <br />
          <br />
          See what’s going on in different organizations by exploring their products.
        </p>
        <DashDivider className="desktop-only" />
        <img
          src={ScrumBoard}
          className="title-image mobile-only"
          alt="people using a kanban board"
        />
        <DashDivider className="mobile-only" />
      </div>
      <img
        src={ScrumBoard}
        className="title-image desktop-only"
        alt="people using a kanban board"
      />
    </div>
  );
}
