import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { hasPermissionToAddProduct } from 'utils/utils';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import AddProductModal from './AddProductModal/AddProductModal';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { OrgDetailsState } from 'state/OrgDetailsState';

import OrgActionsButton from './OrgActionsButton/OrgActionsButton';

import './OrgActions.scss';
import PermissionService from '../../../../../services/PermissionService';

export default function OrgActions(): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const orgDetails = useRecoilValue(OrgDetailsState);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewOrgClick = () => {
    navigate('details');
  };

  const handleAddProductClick = () => {
    setOpenAddProduct(true);
    setAnchorEl(null);
  };

  function orgDetailsButton(): React.ReactElement {
    const hasEditOrgPermission = PermissionService.isTeamMemberOrNewsieAdmin(
      cdsid,
      orgDetails.teamMembers,
    );
    return (
      <MenuItem
        key="org-details"
        onClick={handleViewOrgClick}
        className="org-action-menu-item"
        disableRipple
        disableGutters
      >
        <button className="button-org-action-item desktop-only" data-testid={'org-details-button'}>
          <FontAwesomeIcon
            className="org-actions-icon"
            icon={hasEditOrgPermission ? ('pen' as IconName) : ('fa-solid fa-sitemap' as IconName)}
          />
          {hasEditOrgPermission ? 'Edit Org Details' : 'View Org Details'}
        </button>
      </MenuItem>
    );
  }

  function getDropDownOptions() {
    const options = [];

    if (hasPermissionToAddProduct(cdsid, orgDetails.canAddProduct)) {
      options.push(
        <MenuItem
          key="add-product"
          onClick={handleAddProductClick}
          className="org-action-menu-item"
          disableRipple
          disableGutters
        >
          <button className="button-org-action-item desktop-only" onClick={handleClick}>
            <FontAwesomeIcon className="org-actions-icon" icon={'plus' as IconName} />
            Add Product
          </button>
        </MenuItem>,
      );
    }

    options.push(orgDetailsButton());

    return options;
  }

  const dropDownOptions = getDropDownOptions();

  if (dropDownOptions.length === 0) return <></>;
  return (
    <div>
      <OrgActionsButton onClick={handleClick} open={open} />
      <Menu
        id="org-actions-menu"
        className="org-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'org-actions-button',
          disablePadding: true,
        }}
        PaperProps={{
          sx: { borderRadius: 0 },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {dropDownOptions}
      </Menu>
      <AddProductModal isOpen={openAddProduct} setIsOpen={setOpenAddProduct} />
    </div>
  );
}
