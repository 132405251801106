import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useSetRecoilState } from 'recoil';

import { ModalContentsState } from 'state/ModalContentsState';
import AddNewGroupModal from './AddNewGroupModal/AddNewGroupModal';

interface Props {
  groupsLength: number;
  setExpanded(expandedIndex: number): void;
}

export default function AddNewGroupButton(props: Readonly<Props>) {
  const { groupsLength, setExpanded } = props;
  const setModalContents = useSetRecoilState(ModalContentsState);

  return (
    <button
      className="button-add"
      onClick={() => {
        setModalContents({
          title: 'Add New Group',
          component: <AddNewGroupModal groupsLength={groupsLength} setExpanded={setExpanded} />,
          dispatchCloseModalEvent: true,
        });
      }}
    >
      <FontAwesomeIcon icon={'plus' as IconName} />
      Add New Group
    </button>
  );
}
