import DashDividerIcon from '../../images/dash-divider.svg';
import React from 'react';

function DashDivider({ className }: { className?: string }) {
  return (
    <img src={DashDividerIcon} className={`dash-divider ${className ?? ''}`} alt={'dash divider'} />
  );
}

export default DashDivider;
