import React from 'react';

interface Props {
  onClick: () => void;
}

export default function DoneButton(props: Props) {
  const { onClick } = props;
  return (
    <button className="button-blue" data-testid="groups-lines-done-button" onClick={onClick}>
      Done
    </button>
  );
}
