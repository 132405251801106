import React, { useEffect, useState } from 'react';
import { Event } from 'data/commonTypes';
import useScreenSize from 'hooks/useScreenSize';
import StageProgressBar from 'common/Overview/KanbanState/StageProgressBar/StageProgressBar';
import EditKanbanStageModal from 'common/Overview/KanbanState/EditKanbanStageModel/EditKanbanStageModal';
import { Stage } from 'types/OrgForKanban';

import './KanbanStage.scss';
import EditPencil from 'common/EditPencil/EditPencil';
import { useRecoilValue } from 'recoil';
import { IsOrgAdminSelector } from 'state/AccessTokenState';

interface Props {
  currentStage: string | undefined;
  stagesInOrg: Stage[];
  isOnDesktopIsEditorOrAdmin: boolean;
  updateProduct: (field: string, event: Event, content: string | undefined) => void;
}

export default function KanbanStage({
  currentStage,
  stagesInOrg,
  isOnDesktopIsEditorOrAdmin,
  updateProduct,
}: Props): React.ReactElement {
  const isDesktop = useScreenSize();
  const [iconName, setIconName] = useState<string | undefined>(getIconName(currentStage));
  const [editing, setEditing] = useState<boolean>(false);
  const isOrgAdmin = useRecoilValue(IsOrgAdminSelector);

  useEffect(() => {
    setIconName(getIconName(currentStage));
  }, [currentStage]);

  function getIconName(name: string | undefined): string | undefined {
    let stagePosition = stagesInOrg.findIndex(
      (value) => name && value.name.toLowerCase() === name.toLowerCase(),
    );
    if (stagePosition === -1) {
      return;
    }
    stagePosition++;
    return `${stagesInOrg.length}-stage-${stagePosition}-spot`;
  }
  function printStageTracker(): React.ReactElement {
    if (currentStage)
      return (
        <>{iconName && <StageProgressBar stagesInOrg={stagesInOrg} activeStage={currentStage} />}</>
      );
    return <div className="stage-not-applicable-text">No stage associated with this product</div>;
  }
  return (
    <div className="content-box">
      <div className="heading-with-pencil">
        <h3 className="caps-header">STAGE</h3>
        {!isDesktop && <span className="stage-name-mobile">{currentStage}</span>}
        {(isOnDesktopIsEditorOrAdmin || isOrgAdmin) && (
          <>
            <EditPencil
              onClick={() => {
                setEditing(true);
              }}
              dataTestID={`edit-stage`}
              ariaLabel={'edit section'}
            />
            <EditKanbanStageModal
              currentStage={currentStage}
              editing={editing}
              setEditing={setEditing}
              stagesInOrg={stagesInOrg}
              updateProduct={updateProduct}
            />
          </>
        )}
      </div>
      {printStageTracker()}
    </div>
  );
}
