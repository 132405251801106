import OrgCard from '../../../common/OrgCard/OrgCard';
import React from 'react';
import OrgForDropdown from 'types/OrgForDropdown';

interface Props {
  orgs: OrgForDropdown[];
}

export default function OrgsList({ orgs }: Props) {
  return (
    <div className="browse-all-orgs">
      <div id="all-orgs" className="org-list">
        {orgs.map((org) => {
          return (
            <OrgCard
              key={org.name}
              orgName={org.name}
              orgIcon={org.icon}
              orgDescription={org.blurb}
              orgLink={'/' + org.url}
            />
          );
        })}
      </div>
    </div>
  );
}
