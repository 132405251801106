import axios from 'axios';
import Page, { PageSection, PageType } from 'types/Page';

const PageService = {
  async getOverviewPage(parentType: string, profileParentId: string): Promise<Page> {
    return axios
      .get(
        `/api/page/${parentType.toLocaleLowerCase()}/${encodeURIComponent(
          profileParentId,
        )}/profile`,
      )
      .then((res) => res.data);
  },

  async getProductUpdatePage(currentProductId: string, selectedDate: string): Promise<Page> {
    return axios
      .get(`/api/page/product/${encodeURIComponent(currentProductId)}/update/${selectedDate}`)
      .then((res) => res.data);
  },

  async addPage(page: Page): Promise<Page> {
    return axios.post('/api/page', page).then((res) => res.data);
  },

  async editPage(pageId: string, sections: PageSection[]): Promise<Page> {
    return axios.put(`/api/page/${encodeURIComponent(pageId)}`, sections).then((res) => {
      return res.data;
    });
  },

  async getPageDates(idForProduct: string, pageType: PageType): Promise<string[]> {
    return axios
      .get(`/api/pagedates/product/${encodeURIComponent(idForProduct)}/${pageType}`)
      .then((res) => res.data);
  },
};

export default PageService;
