import Links from 'common/Links/Links';
import React from 'react';
import TeamMembers from 'common/TeamMember/TeamMembers';
import NoLeadsAddedDisplay from './NoLeadsAddedDisplay/NoLeadsAddedDisplay';
import ProductHierarchy from 'types/ProductHierarchy';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import IsDesktopState from 'state/IsDesktopState';
import { IsUserATeamMemberOrNewsieAdminSelector } from 'state/AccessTokenState';
import TeamMember from 'types/TeamMember';
import RelevantLink from 'types/RelevantLink';
import { PageType } from 'types/Page';
import {Event} from 'data/commonTypes';

import ProductHierarchyService from 'services/api/ProductHierarchyService';
import { extractErrorMessage } from 'utils/utils';
import { ProductHierarchyByIdInState } from 'state/ProductHierarchyState';
import ErrorMessageState from 'state/ErrorMessageState';

function LinksAndTeamMembers() {
  const { lineId = '', org = '' } = useParams();

  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const [productLine, setProductLine] = useRecoilState(ProductHierarchyByIdInState(lineId));
  const isDesktop = useRecoilValue(IsDesktopState);
  const isUserATeamMemberOrNewsieAdmin = useRecoilValue(
    IsUserATeamMemberOrNewsieAdminSelector(productLine?.teamMembers),
  );

  function saveLine(lineToUpdate: ProductHierarchy) {
    ProductHierarchyService.editProductHierarchy(lineToUpdate)
      .then((response) => {
        if (response.status > 205) {
          setErrorMessage({ ...errorMessage, caughtError: true});
        } else {
          setProductLine(lineToUpdate);
          setErrorMessage({ errorMessage: '', caughtError: false});
        }
      })
      .catch((e) => {
        const error = extractErrorMessage(
          e,
          'A technical error occurred while editing product hierarchy' + e,
        );
        setErrorMessage({
          caughtError: true,
          errorMessage: error,
        });
      });
  }

  function updateContent(
    field: string,
    event: Event,
    content: string | Array<TeamMember> | Array<RelevantLink> | boolean | Map<PageType, string[]>,
  ) {
    if (productLine && productLine.id) {
      const lineToUpdate: ProductHierarchy = { ...productLine };
      //@ts-ignore
      lineToUpdate[field] = content;
      saveLine(lineToUpdate);
    }
  }

  return productLine ? (
    <>
      <Links
        links={productLine.relevantLinks}
        trackingName={`${productLine.name} | ${productLine.id}`}
        orgParam={org}
        showEditPencil={isDesktop && isUserATeamMemberOrNewsieAdmin}
        updateContent={updateContent}
        location="line"
      />
      <TeamMembers
        title="TEAM LEADS"
        teamMembers={productLine.teamMembers}
        noTeamMembersView={<NoLeadsAddedDisplay />}
        location="line"
        updateContent={updateContent}
      />
    </>
  ) : (
    <></>
  );
}

export default LinksAndTeamMembers;
