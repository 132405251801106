import { atom } from 'recoil';
import { Error, Loading } from 'data/commonTypes';

export type PageDatesState = Loading | Loaded | Error;

type Loaded = {
  state: 'Loaded';
  parentId: string;
  pageDates: string[];
};

const ProductPageDatesState = atom<PageDatesState>({
  key: 'ProductPageDatesState',
  default: { state: 'Loading' },
});

export default ProductPageDatesState;
