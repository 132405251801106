import React from 'react';

import styles from './SaveFailedMessage.module.scss';

interface Props {
  onSubmit(): void;

  showTitle?: boolean; // temporary
}

export default function SaveFailedMessage({
  onSubmit,
  showTitle,
}: Readonly<Props>): React.ReactElement {
  return (
    <form
      id="modal-action-message"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {showTitle && <h5 className={styles.title}>Save Failed</h5>}
      <div style={{ textAlign: 'center' }}>
        Your save was not successful. Please try again. If the issue persists, please reach out to
        Newsie Support.
      </div>
      <div className="modal-button-container">
        <button className="button-blue" type="submit">
          Try Again
        </button>
      </div>
    </form>
  );
}
