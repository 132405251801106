import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  className: string;
  location: 'help' | 'footer';
}

export default function ContactSupportLink(props: Props) {
  const { className } = props;
  return (
    <a
      className={className}
      href="https://engage.cloud.microsoft/main/org/ford.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxODU5MjM2NDEzNDQifQ/all"
      target="_blank"
      rel="noreferrer noopener"
    >
      <FontAwesomeIcon
        className="nav-link-icon icon fa-inverse"
        icon={['far', 'comment-dots']}
        aria-hidden="true"
      />
      <span className="nav-link-text">Contact Support</span>
    </a>
  );
}
