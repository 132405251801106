import React, { FormEventHandler } from 'react';
import ModalContentsTemplate from 'common/Modal/ModalContentsTemplate/ModalContentsTemplate';

interface Props {
  testId?: string;
  className?: string;
  children?: React.ReactElement[] | React.ReactElement;
  title: string;
  subtitle: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: FormEventHandler;
  onCancel?: () => void;
}

function ConfirmationModal(props: Props) {
  const {
    className,
    children,
    title,
    subtitle,
    confirmButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel,
  } = props;

  return (
    <>
      <ModalContentsTemplate
        id="modal-delete-item"
        title={title}
        subtitle={subtitle}
        className={className}
        onSubmit={(e) => {
          onConfirm && onConfirm(e);
        }}
        content={<>{children}</>}
        buttons={
          <>
            {onCancel && (
              <button type="button" className="button-blue-dialog" onClick={onCancel}>
                {cancelButtonText}
              </button>
            )}
            {onConfirm && (
              <button className="button-red" type="submit">
                {confirmButtonText}
              </button>
            )}
          </>
        }
      />
    </>
  );
}

export default ConfirmationModal;
