import React from 'react';
import { useSetRecoilState } from 'recoil';
import { ProductHierarchyByIdInState } from 'state/ProductHierarchyState';
import DeleteItemConfirmation from 'common/DeleteItemConfirmation/DeleteItemConfirmation';

import ProductHierarchy from 'types/ProductHierarchy';
import ProductHierarchyService from 'services/api/ProductHierarchyService';

interface Props {
  name: string;
  id: string;
  title: string;
  type: string;
}

function DeleteProductHierarchyConfirmation(props: Props) {
  const { name, id, title, type } = props;
  const setProductHierarchyInState = useSetRecoilState(ProductHierarchyByIdInState(id));

  const handleDelete = (success: () => void, failure: () => void) => {
    ProductHierarchyService.deleteProductHierarchyById(id)
      .then(() => {
        setProductHierarchyInState({} as ProductHierarchy);
        success();
      })
      .catch(() => {
        failure();
      });
  };

  return (
    <DeleteItemConfirmation name={name} title={title} handleDelete={handleDelete} type={type} />
  );
}

export default DeleteProductHierarchyConfirmation;
