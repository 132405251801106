import React from 'react';
import Link from 'common/Link/Link';
import IconClock from 'images/icon-clock.svg';
import { toTitleCase } from 'utils/utils';
import { GrayTooltip } from 'styles/material-ui/MaterialUIStyles';

import styles from './ProductCard.module.scss';

interface Props {
  name: string;
  url: string;
  stage: string | undefined;
  lastUpdatedTimeStamp?: string;
  stageIndex?: number;
}
export default function ProductCard({
  name,
  url,
  stage,
  stageIndex,
  lastUpdatedTimeStamp,
}: Props): React.ReactElement {
  return (
    <Link data-testid={`${name}-product-card`} to={`${url}/profile`} className={styles.productCard}>
      <GrayTooltip
        className="product-card-tooltip"
        title={name}
        aria-label={name}
        id={`product-card-name-tooltip-${name}`}
        placement="bottom-start"
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <div className={styles.cardName} data-testid="card-name">
          {name}
        </div>
      </GrayTooltip>
      {lastUpdatedTimeStamp && (
        <div className={styles.cardDate}>
          <img src={IconClock} alt="Clock Icon" className={styles.cardDateIcon} />
          <span
            className={styles.updateTimestamp}
            data-testid={`${name.replace(/\W/g, '-')}-update-timestamp`}
          >
            {lastUpdatedTimeStamp.toUpperCase()}
          </span>
        </div>
      )}
      {stageIndex !== undefined && stage && (
        <span
          data-testid="card-stage"
          className={`${styles.cardStage} ${styles[`stageColor${stageIndex}`]}`}
        >
          {toTitleCase(stage)}
        </span>
      )}
    </Link>
  );
}
