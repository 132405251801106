import React from 'react';
import { Footer } from 'common/Footer/Footer';

interface Props {
  children: React.ReactNode;
}

export default function Wrapper({ children }: Props): React.ReactElement {
  return (
    <>
      <div id={'wrapper'}>{children}</div>
      <Footer />
    </>
  );
}
