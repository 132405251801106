import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AccessTokenState } from 'state/AccessTokenState';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import ProductHierarchyState, { ProductHierarchyAtomState } from 'state/ProductHierarchyState';
import { extractErrorMessage } from 'utils/utils';
import ProductHierarchyService from 'services/api/ProductHierarchyService';

interface Props {
  productHierarchy: ProductHierarchyAtomState;
}

function useProductHierarchy(): Props {
  const { org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const accessToken = useRecoilValue(AccessTokenState);
  const [productHierarchy, setProductHierarchy] = useRecoilState(ProductHierarchyState);

  function getProductHierarchy() {
    ProductHierarchyService.getProductHierarchyForOrg(organization.id)
      .then((productHierarchy) => {
        setProductHierarchy({
          state: 'Loaded',
          productHierarchy: productHierarchy,
          orgId: organization.id,
        });
      })
      .catch((e) => {
        setProductHierarchy({
          state: 'Error',
          message: extractErrorMessage(
            e,
            `Error getting product hierarchy details for ${organization.id}`,
          ),
        });
      });
  }

  useEffect(() => {
    if (
      accessToken &&
      organization.id &&
      (productHierarchy.state !== 'Loaded' ||
        (productHierarchy.state === 'Loaded' && productHierarchy.orgId !== organization.id))
    ) {
      getProductHierarchy();
    }
  }, [accessToken, organization.id, productHierarchy.state]);

  return { productHierarchy };
}

export default useProductHierarchy;
