import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { stageOptions } from 'common/StageOptions/StageOptions';
import OrgForKanban from 'types/OrgForKanban';
import Product from 'types/Product';
import { TaxonomyInput } from 'common/TaxonomyInput/TaxonomyInput';

interface Props {
  product: Product;
  setProduct: (product: Product) => void;
  orgDetails: OrgForKanban;
}
export function AddProductInfoSection({
  product,
  setProduct,
  orgDetails,
}: Props): React.ReactElement {
  const handleChange = (event: SelectChangeEvent<typeof product.stage>) => {
    const stageValue = event.target.value;
    setProduct({
      ...product,
      stage: stageValue === 'stages not applicable' ? undefined : stageValue,
    });
  };

  return (
    <div className="add-new-product-section">
      <TaxonomyInput
        location="add product form"
        product={product}
        setProduct={setProduct}
        orgDetails={orgDetails}
      />
      {orgDetails.stages && (
        <FormControl variant="outlined" className="add-product-stage-select">
          <InputLabel id="stage-select-label">Stage *</InputLabel>
          <Select
            inputProps={{ 'data-testid': 'product-stage-select' }}
            labelId="stage-select-label"
            label="Stage *"
            value={product.stage ?? 'stages not applicable'}
            required={true}
            onChange={handleChange}
          >
            {orgDetails.stages && stageOptions(orgDetails.stages)}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
