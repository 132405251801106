import React from 'react';
import { useSetRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import ProductHierarchy from 'types/ProductHierarchy';
import { ModalContentsState } from 'state/ModalContentsState';
import EditGroupModal from './EditGroupModal/EditGroupModal';
interface Props {
  group: ProductHierarchy;
}

function EditGroupButton({ group }: Props) {
  const setModalContents = useSetRecoilState(ModalContentsState);

  const testId = `${group.name.replace(/ /g, '-')}-edit-pencil`;

  return (
    <button
      data-testid={testId}
      aria-label="edit group"
      className="edit-pencil"
      onClick={() => {
        setModalContents({
          title: `Edit "${group.name}" Group`,
          component: <EditGroupModal group={group} />,
          dispatchCloseModalEvent: true,
        });
      }}
    >
      <FontAwesomeIcon icon={'pen' as IconName} />
    </button>
  );
}

export default EditGroupButton;
