import React, { useEffect, useState } from 'react';
import ModalContentsTemplate from 'common/Modal/ModalContentsTemplate/ModalContentsTemplate';
import SaveFailedMessage from '../SaveFailedMessage/SaveFailedMessage';
import { UnsavedChanges } from 'common/UnsavedChanges/UnsavedChanges';
import { useResetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import { usePrompt } from 'hooks/usePrompt';

interface Props {
  title: string;
  children: React.ReactElement[] | React.ReactElement;
  subtitle?: string;
  className?: string;
  saveBtnText?: string;
  useEffectDeps: unknown[];

  onSubmit(): Promise<void>;
  isFormPopulated(): boolean;
}

function GroupsAndLinesModalWrapper(props: Readonly<Props>) {
  const {
    title,
    subtitle,
    saveBtnText = 'Save',
    onSubmit,
    className,
    children,
    isFormPopulated,
    useEffectDeps,
  } = props;

  const closeModal = useResetRecoilState(ModalContentsState);

  const [showUnsavedChangesMsg, setShowUnsavedChangesMsg] = useState<boolean>(false);
  const [showSaveFailedMsg, setShowSaveFailedMsg] = useState<boolean>(false);

  // if user clicks the back button and form is populated, alert user
  usePrompt(
    'There are unsaved changes that will be lost. Are you sure you want to leave?',
    isFormPopulated(),
    closeModal,
  );

  const closeModalEventListener = (formIsPopulated: boolean) => {
    return function () {
      if (formIsPopulated) {
        setShowUnsavedChangesMsg(true);
      } else {
        closeModal();
      }
    };
  };

  useEffect(() => {
    const listenerTarget = document.querySelector('#main-content');
    const callback = closeModalEventListener(isFormPopulated());

    listenerTarget?.addEventListener('closeModal', callback);

    return () => listenerTarget?.removeEventListener('closeModal', callback);
  }, useEffectDeps);

  if (showUnsavedChangesMsg)
    return (
      <UnsavedChanges
        continueHandler={() => setShowUnsavedChangesMsg(false)}
        exitHandler={closeModal}
        showTitle
      />
    );

  if (showSaveFailedMsg)
    return <SaveFailedMessage onSubmit={() => setShowSaveFailedMsg(false)} showTitle />;

  return (
    <ModalContentsTemplate
      title={title}
      subtitle={subtitle}
      className={className}
      onSubmit={() => {
        onSubmit().catch(() => {
          setShowSaveFailedMsg(true);
        });
      }}
      content={<>{children}</>}
      buttons={
        <button className="button-blue" type="submit">
          {saveBtnText}
        </button>
      }
    />
  );
}

export default GroupsAndLinesModalWrapper;
