export interface ActionSpec {
  key: number;
  title: string;
  subtitle?: string;
  showCloseIcon: boolean;
}

export type Loading = { state: 'Loading' };

export type Error = {
  state: 'Error';
  message: string;
};

export interface AmplitudeTracking {
  eventName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export enum Event {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  REORDER = 'REORDER',
  ARCHIVE = 'ARCHIVE',
  UNARCHIVE = 'UNARCHIVE',
}
