import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal/DepricatedModal/Modal';
import { FormControl, InputLabel, Select, SelectChangeEvent, ThemeProvider } from '@mui/material';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import { Event } from 'data/commonTypes';
import { stageOptions } from 'common/StageOptions/StageOptions';
import { Stage } from 'types/OrgForKanban';

interface Props {
  currentStage: string | undefined;
  setEditing: (isEditing: boolean) => void;
  editing: boolean;
  stagesInOrg: Stage[];
  updateProduct: (field: string, event: Event, content: string | undefined) => void;
}

export default function EditKanbanStageModal({
  currentStage,
  editing,
  setEditing,
  stagesInOrg,
  updateProduct,
}: Props): React.ReactElement {
  const [stageName, setStageName] = useState<string | undefined>(
    currentStage ? currentStage.toLowerCase() : undefined,
  );
  useEffect(() => {
    setStageName(currentStage ? currentStage.toLowerCase() : undefined);
  }, [currentStage]);

  const handleChange = (event: SelectChangeEvent<typeof stageName>) => {
    const stageValue = event.target.value;
    setStageName(stageValue);
  };

  return (
    <Modal
      title="Edit Stage"
      isOpenModal={editing}
      showCloseIcon={true}
      closeHandler={() => setEditing(false)}
      onExited={() => {
        setStageName(currentStage);
      }}
    >
      <ThemeProvider theme={muiTheme}>
        <FormControl variant="outlined">
          <InputLabel id="select-label">Stage</InputLabel>
          <Select
            inputProps={{ 'data-testid': 'product-stage-select' }}
            labelId="select-label"
            label="Stage"
            value={stageName ? stageName : 'stages not applicable'}
            onChange={handleChange}
          >
            {stageOptions(stagesInOrg)}
          </Select>
        </FormControl>
      </ThemeProvider>
      <button
        className="button-blue"
        onClick={() => {
          updateProduct(
            'stage',
            Event.EDIT,
            stageName === 'stages not applicable' ? undefined : stageName,
          );
          setEditing(false);
        }}
      >
        Save
      </button>
    </Modal>
  );
}
