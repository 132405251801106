import React, { useEffect } from 'react';
import AuthGuard from 'adfs/AuthGuard';
import App from 'App';
import { useRecoilValue } from 'recoil';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';

export default function AppWrapper(): React.ReactElement {
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  useEffect(() => {
  }, [cdsid]);

  return (
    <AuthGuard>
        <App />
    </AuthGuard>
  );
}
