import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import TextToSearchState, { extractSearchFilterValue } from 'state/TextToSearchState';
import useScreenSize from 'hooks/useScreenSize';
import OrgPageContentMobile from '../../OrgPageContentMobile/OrgPageContentMobile';
import NoResultsMessage from './NoResultsMessage/NoResultsMessage';
import ProductLineGroup from './ProductLineGroup/ProductLineGroup';
import useSearchFilters from 'hooks/useSearchFilters';
import FilteredProductCountState from 'state/FilteredProductCountState';
import TimeFilterSearchState, { TimeFilterOptions } from 'state/TimeFilterSearchState';
import OrgForDropdown from 'types/OrgForDropdown';
import ProductHierarchy from 'types/ProductHierarchy';

import './OrgContainer.scss';

interface Props {
  totalActiveProducts: number;
  organization: OrgForDropdown;
}

export default function OrgContainer({
  totalActiveProducts,
  organization,
}: Props): React.ReactElement {
  const setTextToSearch = useSetRecoilState(TextToSearchState);
  const setSearchTime = useSetRecoilState(TimeFilterSearchState);
  const isDesktop = useScreenSize();
  const orgDetails = useRecoilValue(OrgDetailsState);
  const searchFilters = useSearchFilters();
  const filteredProductCount = useRecoilValue(FilteredProductCountState);

  useEffect(() => {
    setTextToSearch(extractSearchFilterValue());
    setSearchTime(TimeFilterOptions.UPDATED_ANY_TIME);
  }, [organization.name]);

  function printOrgContainer(): React.ReactElement {
    const shouldShowNoResultMessage = () => {
      return (
        searchFilters.filteredProducts.length === 0 || searchFilters.filteredGroups.length === 0
      );
    };
    function printKanban(): React.ReactElement {
      if (isDesktop) {
        return <>{orgDetails && printProductGroup(searchFilters.filteredGroups)}</>;
      }
      return <OrgPageContentMobile />;
    }
    function getStageMap(): Map<string, number> {
      const stageMap = new Map();
      if (orgDetails && orgDetails.stages && orgDetails.stages.length > 0) {
        orgDetails.stages.forEach((stage, index) => stageMap.set(stage.name.toLowerCase(), index));
      }
      return stageMap;
    }
    function printProductGroup(filteredProductGroups: ProductHierarchy[]): React.ReactElement[] {
      const productGroups: React.ReactElement[] = [];
      filteredProductGroups.forEach((productGroup) =>
        productGroups.push(
          <ProductLineGroup
            key={productGroup.id}
            productsInOrg={searchFilters.filteredProducts}
            organization={organization}
            productGroup={productGroup}
            stagesMap={getStageMap()}
          />,
        ),
      );
      return productGroups;
    }

    if (shouldShowNoResultMessage()) return <NoResultsMessage setTextToSearch={setTextToSearch} />;
    return printKanban();
  }

  return (
    <div className="org-products-container">
      <p className="org-products-count" data-testid="org-products-count">
        {filteredProductCount} of {totalActiveProducts} products
      </p>
      {printOrgContainer()}
    </div>
  );
}
