import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import useSearchFilters from 'hooks/useSearchFilters';
import ProductsForOrgPageContentMobile from './ProductsForOrgPageContentMobile/ProductsForOrgPageContentMobile';
import Product from 'types/Product';

import './OrgPageContentMobile.scss';

export default function OrgPageContentMobile(): React.ReactElement {
  const { org = '' } = useParams();

  const organization = useRecoilValue(CurrentOrgSelector(org));

  const searchFilters = useSearchFilters();

  function getProductsForGroup(groupId: string): Product[] {
    const filteredLineIdsByGroup = searchFilters.filteredLines
      .filter((line) => line.parentId === groupId)
      .map((line) => line.id);

    return searchFilters.filteredProducts.filter((product) =>
      filteredLineIdsByGroup.includes(product.productLineId),
    );
  }

  return (
    <div className="mobile-product-hierarchy">
      {searchFilters.filteredGroups.map((group) => (
        <Fragment key={`mobile-products-${group.id}`}>
          <ProductsForOrgPageContentMobile
            group={group}
            organization={organization}
            productsForGroup={getProductsForGroup(group.id)}
            linesForGroup={searchFilters.filteredLines.filter((line) => line.parentId === group.id)}
          />
        </Fragment>
      ))}
    </div>
  );
}
