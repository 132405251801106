import React from 'react';
import {
  Link as RouterLink,
  NavLink as NavRouterLink,
  NavLinkProps,
  LinkProps,
} from 'react-router-dom';

export default function Link(props: NavLinkProps | LinkProps): React.ReactElement {
  function renderNavLinkOrLink() {
    if (props.className === 'nav-link') {
      const { children, className, ...rest } = props as NavLinkProps;
      return (
        <NavRouterLink {...rest} className={className}>
          {children}
        </NavRouterLink>
      );
    }
    const { children, className, ...rest } = props as LinkProps;
    return (
      <RouterLink {...rest} className={className}>
        {children}
      </RouterLink>
    );
  }
  return renderNavLinkOrLink();
}
