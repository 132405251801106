import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { selectCalendarTheme } from 'styles/material-ui/materialUI';
import { Moment } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import './Calendar.scss';

interface Props {
  selectedDate: Moment;
  setSelectedDate: (selectedDate: Moment) => void;
  shouldDisableDate: (date: Moment) => boolean;
  addingNewUpdate: boolean;
  disabledMode?: boolean;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
}

export default function Calendar({
  selectedDate,
  setSelectedDate,
  shouldDisableDate,
  addingNewUpdate,
  disabledMode,
  minDate,
  maxDate,
}: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bodyAnchorEl: Element = document.getElementById('content-container')!;
  function printCustomToolbar(): React.ReactElement {
    return (
      <div className={'custom-toolbar'}>
        <span className={'toolbar-title'}>
          {addingNewUpdate ? 'Add New Update' : 'Choose An Update'}
        </span>
        <button
          data-testid="close-button"
          aria-label="close button"
          className="close-button"
          onClick={() => setIsOpen(false)}
        >
          <FontAwesomeIcon icon={'times' as IconName} />
        </button>
      </div>
    );
  }
  return (
    <ThemeProvider theme={selectCalendarTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          autoOk={true}
          readOnly={disabledMode}
          disabled={disabledMode}
          minDate={minDate}
          maxDate={maxDate}
          open={isOpen}
          ToolbarComponent={() => printCustomToolbar()}
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-picker-inline"
          keyboardIcon={
            <FontAwesomeIcon
              icon={'calendar-alt' as IconName}
              className={`calendar-icon ${disabledMode ? 'disabled' : ''}`}
            />
          }
          label={addingNewUpdate ? 'Update Start Date' : ''}
          PopoverProps={{
            anchorEl: bodyAnchorEl,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          }}
          inputProps={{ 'data-testid': 'date-picker' }}
          InputProps={{
            startAdornment: addingNewUpdate ? (
              ''
            ) : (
              <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                Update for
              </div>
            ),
          }}
          onClick={() => {
            if (!disabledMode) setIsOpen(true);
          }}
          className="dialog-date"
          value={selectedDate}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onChange={(date: MaterialUiPickersDate) => {
            if (date) setSelectedDate(date);
          }}
          leftArrowButtonProps={{ id: 'left-arrow' }}
          rightArrowButtonProps={{ id: 'right-arrow' }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Tab' && e.key !== 'Enter') {
              e.preventDefault();
            }
          }}
          shouldDisableDate={(date) => {
            if (date) {
              return shouldDisableDate(date);
            } else {
              return false;
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
