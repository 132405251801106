import React from 'react';
import { Event } from 'data/commonTypes';
import EditTeamCommonModal, {
  DataToEdit,
} from 'common/Links/EditRelevantLinkModal/EditTeamCommonModal/EditTeamCommonModal';
import TeamMember from 'types/TeamMember';

interface Props {
  setDialogIsOpen: (editing: boolean) => void;
  teamMembers: TeamMember[] | null;
  title: string;
  updateTeam: (field: string, event: Event, content: string | Array<TeamMember>) => void;
}
export default function EditTeamMemberModal({
  updateTeam,
  title,
  teamMembers,
  setDialogIsOpen,
}: Props): React.ReactElement {
  function addTeamMemberToList(dataToEdit: DataToEdit) {
    const teamMemberToAdd = {
      name: dataToEdit.name,
      title: dataToEdit.titleOrUrl,
      cdsid: dataToEdit.cdsid,
    };
    const newTeamMembers = teamMembers ? [...teamMembers, teamMemberToAdd] : [teamMemberToAdd];
    updateTeam('teamMembers', Event.ADD, newTeamMembers);
  }
  function reorderTeamData(dataList: Array<DataToEdit>) {
    const reorderedTeamMembers: Array<TeamMember> = [];
    dataList.forEach((data) => {
      reorderedTeamMembers.push({
        name: data.name,
        title: data.titleOrUrl,
        cdsid: data.cdsid,
      });
    });
    updateTeam('teamMembers', Event.REORDER, [...reorderedTeamMembers]);
  }

  function editTeamMemberInList(dataToEdit: DataToEdit, index: number) {
    const teamMemberToEdit = {
      name: dataToEdit.name,
      title: dataToEdit.titleOrUrl,
      cdsid: dataToEdit.cdsid,
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editedListOfTeamMembers = teamMembers!.map((member, key) => {
      if (key === index) {
        return teamMemberToEdit;
      } else return member;
    });
    updateTeam('teamMembers', Event.EDIT, [...editedListOfTeamMembers]);
  }

  function deleteTeamMemberFromList(dataToDelete: DataToEdit) {
    updateTeam(`teamMembers`, Event.DELETE, [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...teamMembers!.filter((member) => member.cdsid !== dataToDelete.cdsid),
    ]);
  }

  function convertTeamMembersToDataList(): DataToEdit[] {
    const dataList: DataToEdit[] = [];
    if (teamMembers)
      teamMembers.forEach((member) =>
        dataList.push({ name: member.name, titleOrUrl: member.title, cdsid: member.cdsid }),
      );
    return dataList;
  }

  return (
    <EditTeamCommonModal
      dataType={title}
      dataList={convertTeamMembersToDataList()}
      setDialogIsOpen={setDialogIsOpen}
      addProductData={addTeamMemberToList}
      editProductData={editTeamMemberInList}
      deleteProductData={deleteTeamMemberFromList}
      reorderProductData={reorderTeamData}
    />
  );
}
