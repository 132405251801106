import { TextField, ThemeProvider } from '@mui/material';
import Modal from 'common/Modal/DepricatedModal/Modal';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import ModalListDragAndDrop from './ModalListDragAndDrop/ModalListDragAndDrop';
import CdsidInputBox from 'common/CdsidInputBox/CdsidInputBox';
import { UnsavedChanges } from 'common/UnsavedChanges/UnsavedChanges';
import { ActionSpec } from 'data/commonTypes';

import './EditTeamCommonModal.scss';

interface Props {
  dataType: string;
  dataList: DataToEdit[];
  setDialogIsOpen: (editing: boolean) => void;
  addProductData: (dataToAdd: DataToEdit) => void;
  editProductData: (dataToEdit: DataToEdit, index: number) => void;
  deleteProductData: (dataToDelete: DataToEdit) => void;
  reorderProductData: (dataList: Array<DataToEdit>) => void;
}

export interface DataToEdit {
  name: string;
  titleOrUrl: string;
  cdsid?: string;
}

enum Action {
  View,
  Add,
  Edit,
  DeleteConfirm,
  UnsavedChanges,
}

function getActionSpec(action: Action, title: string): ActionSpec {
  return { key: action, title, showCloseIcon: false };
}

export default function EditTeamCommonModal({
  dataType,
  dataList,
  setDialogIsOpen,
  addProductData,
  editProductData,
  deleteProductData,
  reorderProductData,
}: Props): React.ReactElement {
  const [action, setAction] = useState<ActionSpec>(
    getActionSpec(Action.View, `Edit ${getViewTitle()}`),
  );
  const [dataToEdit, setDataToEdit] = useState<DataToEdit>({ name: '', titleOrUrl: '', cdsid: '' });
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [showErrorFromCdsidValidation, setShowErrorFromCdsidValidation] = useState<boolean>(false);

  function isEditingTeamMember(): boolean {
    return dataType === 'team member' || dataType === 'team lead';
  }
  function getViewTitle(): string {
    return isEditingTeamMember() ? 'Team' : 'Links';
  }

  function getLabelForName(): string {
    return isEditingTeamMember() ? 'Name' : 'Display as';
  }

  function getLabelForTitleOrUrl(): string {
    return isEditingTeamMember() ? 'Role' : 'Web address (URL)';
  }

  function onEditOfDataItem(key: number, data: DataToEdit) {
    setAction(getActionSpec(Action.Edit, `Edit ${dataType}`));
    setEditIndex(key);
    setDataToEdit(data);
  }

  function onAddNewItemClick() {
    setAction(getActionSpec(Action.Add, `Add New ${dataType}`));
  }

  function onDeleteItemClick(data: DataToEdit) {
    setAction(getActionSpec(Action.DeleteConfirm, `Delete ${dataType}`));
    setDataToEdit(data);
  }

  function handleClickAway() {
    if (
      action.key === Action.Add &&
      (dataToEdit.name || dataToEdit.titleOrUrl || dataToEdit.cdsid)
    ) {
      setAction(getActionSpec(Action.UnsavedChanges, 'UNSAVED CHANGES!'));
    } else {
      setDialogIsOpen(false);
    }
  }

  function printContent(): React.ReactElement {
    switch (action.key) {
      case Action.Add:
      case Action.Edit:
        return printAddForm();
      case Action.DeleteConfirm:
        return printDeleteConfirmationDialog();
      case Action.UnsavedChanges:
        return printUnsavedChanges();
      case Action.View:
      default:
        return printList();
    }
  }

  function printAddForm() {
    return (
      <form
        className="dialog-form"
        onSubmit={(e) => {
          e.preventDefault();

          if (action) {
            if (action.key === Action.Edit) {
              editProductData(dataToEdit, editIndex);
            }
            if (action.key === Action.Add) {
              addProductData(dataToEdit);
            }
          }
          setDialogIsOpen(false);
        }}
      >
        <div className={'dialog-input-wrapper'}>
          <TextField
            fullWidth={true}
            onChange={(event) => setDataToEdit({ ...dataToEdit, name: event.target.value })}
            id={getLabelForName()}
            label={getLabelForName()}
            variant="outlined"
            required={true}
            defaultValue={dataToEdit.name}
            inputProps={{ 'data-testid': 'name-input', title: 'Enter Name' }}
          />
        </div>
        <div className={'dialog-input-wrapper'}>
          <TextField
            fullWidth={true}
            id={getLabelForTitleOrUrl()}
            onChange={(event) => setDataToEdit({ ...dataToEdit, titleOrUrl: event.target.value })}
            label={getLabelForTitleOrUrl()}
            variant="outlined"
            required={true}
            defaultValue={dataToEdit.titleOrUrl}
            inputProps={{
              'data-testid': 'title-or-url-input',
              pattern: isEditingTeamMember() ? '^.{2,250}' : 'https?://.+',
              title: `${
                isEditingTeamMember()
                  ? 'Role should contain more than 1 character (e.g., Product Manager)'
                  : 'e.g., http://example.org'
              }`,
            }}
          />
        </div>
        {isEditingTeamMember() && (
          <div className={'dialog-input-wrapper'}>
            <CdsidInputBox
              teamMember={dataToEdit}
              setTeamMember={({ cdsid }) => {
                setDataToEdit({ ...dataToEdit, cdsid });
              }}
              setShowErrorCallback={setShowErrorFromCdsidValidation}
            />
          </div>
        )}
        <button
          type="button"
          className="back-to-team-links"
          onClick={() => {
            setAction(getActionSpec(Action.View, `Edit ${getViewTitle()}`));
            setDataToEdit({ name: '', titleOrUrl: '', cdsid: '' });
          }}
        >
          <FontAwesomeIcon icon={'angle-double-left' as IconName} />
          {`Back to ${getViewTitle().toLowerCase()}`}
        </button>
        <button
          disabled={showErrorFromCdsidValidation}
          className={`button-blue ${showErrorFromCdsidValidation ? 'disabled' : ''}`}
          type="submit"
          data-testid="submit-button"
        >
          {action && action.key === Action.Edit && 'Save'}
          {action && action.key === Action.Add && `Add ${dataType}`}
        </button>
      </form>
    );
  }

  function printDeleteConfirmationDialog() {
    return (
      <form
        id="edit-team-modal-delete-item"
        onSubmit={(e) => {
          e.preventDefault();
          deleteProductData(dataToEdit);
          setDialogIsOpen(false);
        }}
      >
        <span>
          Are you sure you want to delete &quot;{dataToEdit.name}&quot; from your product?
        </span>
        <div className="edit-team-delete-button-container">
          <button
            type="button"
            className="button-red cancel-btn"
            onClick={() => {
              setAction(getActionSpec(Action.View, `Edit ${getViewTitle()}`));
            }}
          >
            Cancel
          </button>
          <button className={'button-blue'} type="submit">
            Yes, Delete
          </button>
        </div>
      </form>
    );
  }

  function printUnsavedChanges() {
    return (
      <UnsavedChanges
        continueHandler={onAddNewItemClick}
        exitHandler={() => setDialogIsOpen(false)}
      />
    );
  }

  function printList() {
    return (
      <div id="modal-view-item">
        <ModalListDragAndDrop
          onUpdateOfDataItem={reorderProductData}
          setDialogIsOpen={setDialogIsOpen}
          dataList={dataList}
          dataType={dataType}
          onAddNewItemClick={onAddNewItemClick}
          onEditOfDataItem={onEditOfDataItem}
          onDeleteItemClick={onDeleteItemClick}
        />
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <Modal
          title={action.title}
          isOpenModal={true}
          closeHandler={handleClickAway}
          showCloseIcon={action.showCloseIcon}
        >
          {printContent()}
        </Modal>
      </ThemeProvider>
    </>
  );
}
