import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

import ProductHierarchy from 'types/ProductHierarchy';

interface Props {
  selectedProductGroupId: string;
  handleChange?: (event: SelectChangeEvent) => void;
  listOfGroups: ProductHierarchy[];
  disabled?: boolean;
}

export default function ProductGroupSelect(props: Readonly<Props>): React.ReactElement {
  const { selectedProductGroupId, handleChange, listOfGroups, disabled = false } = props;

  function printProductGroups() {
    const sortedGroups = [...listOfGroups].sort((a: ProductHierarchy, b: ProductHierarchy) =>
      a.name.localeCompare(b.name),
    );
    return sortedGroups.map((group: ProductHierarchy) => {
      return (
        <MenuItem
          key={`product-group-select-option-${group.id}`}
          value={group.id}
          data-testid="group-select-option"
        >
          {group.name}
        </MenuItem>
      );
    });
  }

  return (
    <FormControl
      variant="outlined"
      className={`product-hierarchy-select ${disabled ? 'gray-out-select' : ''}`}
      disabled={disabled}
    >
      {!disabled && <InputLabel id="group-select-label">Group *</InputLabel>}
      <Select
        inputProps={{ 'data-testid': 'group-select' }}
        labelId="group-select-label"
        label={!disabled ? 'Group *' : ''}
        value={selectedProductGroupId}
        required={true}
        onChange={handleChange}
      >
        {printProductGroups()}
      </Select>
    </FormControl>
  );
}
