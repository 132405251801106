import React from 'react';
import { generateProductURL } from 'utils/utils';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import Link from 'common/Link/Link';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import useScreenSize from 'hooks/useScreenSize';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { CurrentProductSelector, ProductsState } from 'state/ProductsForOrgState';
import { ActiveTabState } from 'state/ActiveTabState';
import { useParams } from 'react-router-dom';

import Product from 'types/Product';
import { ActiveTab } from 'utils/getActiveTab';

import './ProductNavigation.scss';

interface Props {
  isHeader?: boolean;
}

export default function ProductNavigation({ isHeader = false }: Props): React.ReactElement {
  const { productId = '', org = '' } = useParams();
  const products = useRecoilValue(ProductsState);
  const currentProduct = useRecoilValue(CurrentProductSelector(productId));
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const activeTab = useRecoilValue(ActiveTabState);
  const isDesktop = useScreenSize();
  const page = ActiveTab[activeTab].toLowerCase();
  let previousProductURL: string;
  let nextProductURL: string;
  function productNav(products: Product[]) {
    try {
      if (!currentProduct || !currentProduct.name) {
        return <LoadingMessage />;
      }
      const currentProductIsGraduatedOrTestProduct = products.filter(
        (product) => product.id === currentProduct.id,
      )[0].done;

      if (currentProductIsGraduatedOrTestProduct) {
        return <></>;
      }

      const activeProducts = products.filter((product: Product) => product.done !== true);
      const currentProductIndex = activeProducts.findIndex(
        (product) => product.id === currentProduct.id,
      );

      const previousProductIndex =
        currentProductIndex === 0 ? activeProducts.length - 1 : currentProductIndex - 1;
      const nextProductIndex =
        currentProductIndex === activeProducts.length - 1 ? 0 : currentProductIndex + 1;

      previousProductURL =
        generateProductURL(organization.url, activeProducts[previousProductIndex]) + `/${page}`;
      nextProductURL =
        generateProductURL(organization.url, activeProducts[nextProductIndex]) + `/${page}`;
    } catch (e) {
      return <ErrorMessageView/>;
    }
    return (
      <div className={`${isHeader ? '' : 'content-container product-nav'}`}>
        <div className={`${isDesktop ? 'product-nav-container' : ''}`}>
          {isHeader ? (
            <LightTooltip
              className="previous-arrow-tooltip"
              title="Go to Previous Product"
              data-testid="previous-arrow-tooltip"
              placement="top"
              arrow
            >
              <div className="nav-button prev-product-button" data-testid="previous-product-button">
                {prevProductLink()}
              </div>
            </LightTooltip>
          ) : (
            prevProductLink()
          )}
          {isHeader ? (
            <>
              <LightTooltip
                className="next-arrow-tooltip"
                title="Go to Next Product"
                data-testid="next-arrow-tooltip"
                placement="top"
                arrow
              >
                <div className="nav-button next-product-button" data-testid="next-product-button">
                  {nextProductLink()}
                </div>
              </LightTooltip>
            </>
          ) : (
            nextProductLink()
          )}
        </div>
      </div>
    );

    function prevProductLink(): React.ReactElement {
      return (
        <Link
          to={previousProductURL}
          aria-label="previous product"
          data-testid="previous-product"
        >
          <FontAwesomeIcon
            icon={'angle-left' as IconName}
            className="product-nav-arrow"
            size={isHeader ? 'lg' : 'sm'}
          />
          {isHeader ? '' : 'Previous Product'}
        </Link>
      );
    }

    function nextProductLink(): React.ReactElement {
      return (
        <Link
          data-testid="next-product"
          aria-label="next product"
          to={nextProductURL}
        >
          {isHeader ? '' : 'Next Product'}
          <FontAwesomeIcon
            icon={'angle-right' as IconName}
            className="product-nav-arrow"
            size={isHeader ? 'lg' : 'sm'}
          />
        </Link>
      );
    }
  }

  switch (products.state) {
    case 'Loaded':
      return productNav(products.products);
    case 'Error':
      return <></>;
    case 'Loading':
    default:
      return isHeader ? <></> : <LoadingMessage />;
  }
}
