import { ReactImageGalleryItem } from 'react-image-gallery';
import jwt_decode from 'jwt-decode';
import Product from 'types/Product';

export function generateProductURL(orgUrl: string, product: Product): string {
  return `/${orgUrl}/${product.id}/${encodeURIComponent(removeNonAlphaNumerics(product.name))}`;
}

export function removeNonAlphaNumerics(thing: string): string {
  return thing.replace(/[^a-zA-Z0-9]+/gm, ' ');
}

export function toTitleCase(toTransform: string): string {
  const stringArray = toTransform.split(' ');

  for (let i = 0; i < stringArray.length; i++) {
    stringArray[i] = stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1).toLowerCase();
  }

  return stringArray.join(' ');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function extractErrorMessage(error: any, defaultMessage: string): string {
  if (error.response) {
    let errorMessage = defaultMessage;
    if (error.response.data.message && error.response.data.message !== '') {
      errorMessage = error.response?.data.message;
    }
    return error.response.status + ': ' + errorMessage;
  }
  return `A technical error occurred. Please try again or reach out the Newsie support team error: ${JSON.stringify(
    error,
  )}`;
}

export function formatAndRemoveSpacesFromName(name: string): string {
  return name.replace(/\s+/g, '_').toLowerCase();
}

export function findImages(content: string): ReactImageGalleryItem[] {
  const el = document.createElement('div');
  el.innerHTML = content;
  const images: HTMLCollectionOf<HTMLImageElement> = el.getElementsByTagName('img');
  const galleryItems: ReactImageGalleryItem[] = [];
  for (const image of images) {
    const img: ReactImageGalleryItem = image.alt
      ? { original: image.src, originalAlt: image.alt }
      : { original: image.src };
    galleryItems.push(img);
  }
  return galleryItems;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<Params extends any[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function getCdsidFromToken(token: string): string {
  try {
    const { sub: cdsid = '' }: { sub: string } = jwt_decode(token);
    return cdsid.toLowerCase();
  } catch (e) {
    return '';
  }
}

export function hasPermissionToAddProduct(cdsid: string, canAddProductList?: string[]): boolean {

  if (!Array.isArray(canAddProductList) || !canAddProductList.length ) return true;
  return canAddProductList.includes(cdsid)
}

export function hasScrolled(id: string) {
  const element = document.getElementById(id);
  return element && window.pageYOffset >= element.offsetTop;
}
