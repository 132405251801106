import axios from 'axios';

const S3Service = {
  async handleFileUpload(data: FormData): Promise<string> {
    return axios
      .post('/api/images', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  },
};

export default S3Service;
