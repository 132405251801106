import { LightTooltip } from '../../styles/material-ui/MaterialUIStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

interface Props {
  onClick: () => void;
  dataTestID: string;
  ariaLabel: string;
  className?: string;
}
export default function EditPencil({ onClick, ariaLabel, dataTestID, className }: Props) {
  return (
    <LightTooltip title="Edit this section" placement="top" arrow>
      <button
        data-testid={dataTestID}
        aria-label={ariaLabel}
        className={`desktop-only icon-grey ${className}`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={'pen' as IconName} />
      </button>
    </LightTooltip>
  );
}
