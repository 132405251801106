import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useSetRecoilState } from 'recoil';
import useProductsContext from 'hooks/useProductsContext';
import DeleteProductHierarchyConfirmation from './DeleteProductHierarchyConfirmation/DeleteProductHierarchyConfirmation';
import { ModalContentsState } from 'state/ModalContentsState';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import ProductHierarchy from 'types/ProductHierarchy';
import Accordion from './StyledAccordian/Accordian';
import AccordionSummary from './StyledAccordian/AccordianSummary';
import AccordionDetails from './StyledAccordian/AccordianDetails';
import LinesForGroup from './LinesForGroup/LinesForGroup';
import AddNewLineButton from '../../../AddNewLineButton/AddNewLineButton';
import EditGroupButton from '../../../EditGroupButton/EditGroupButton';

import './GroupAndLinesAccordion.scss';

interface Props {
  group: ProductHierarchy;
  lines: ProductHierarchy[];
  index: number;
  isEditMode: boolean;
  expanded: number;
  setExpanded: (expanded: number) => void;
  dragHandleSpan: React.ReactElement;
}

export default function GroupsAndLinesAccordion(props: Props) {
  const { group, lines, index, isEditMode, expanded, setExpanded, dragHandleSpan } = props;
  const groupHasNoLines = lines.length == 0;

  useProductsContext();

  const setModalContents = useSetRecoilState(ModalContentsState);

  return (
    <Accordion
      expanded={expanded == index}
      onChange={() => setExpanded(expanded === index ? -1 : index)}
    >
      <AccordionSummary
        aria-controls={`panel-content-${index}`}
        id={`panel-header-${index}`}
        iseditmode={isEditMode}
        data-testid={`panel-summary-${index}`}
      >
        <span>{group.name}</span>
        {isEditMode && (
          <div className="tool-bar">
            <EditGroupButton group={group} />
            {groupHasNoLines && (
              <button
                aria-label="delete group"
                className="trash-icon"
                onClick={() =>
                  setModalContents({
                    title: 'Delete Group',
                    component: (
                      <DeleteProductHierarchyConfirmation
                        name={group.name}
                        id={group.id}
                        title="Delete Group?"
                        type="group"
                      />
                    ),
                  })
                }
              >
                <FontAwesomeIcon icon={'trash-can' as IconName} />
              </button>
            )}
            <LightTooltip
              title={'Drag & Drop to Reorder'}
              aria-label={`Drag & Drop to Reorder ${group.name}`}
              placement="top"
              arrow
            >
              {dragHandleSpan}
            </LightTooltip>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <>
          {LinesForGroup(lines, isEditMode, setExpanded)}
          {isEditMode && (
            <>
              {groupHasNoLines && (
                <div className="lines italic">there are no lines in this group</div>
              )}
              <div className="add-line-button">
                <AddNewLineButton group={group} setExpanded={setExpanded} />
              </div>
            </>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
}
