import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Header } from 'common/Header/Header';
import { OrgDetailsState } from 'state/OrgDetailsState';
import useOrgsForDropDown from 'hooks/useOrgsForDropDown';
import { IsUserATeamMemberOrNewsieAdminSelector } from 'state/AccessTokenState';
import { Link } from 'react-router-dom';
import OrgAdminSection from 'pages/org-details/OrgAdminSection/OrgAdminSection';
import GroupsAndLinesCard from './GroupsAndLinesCard/GroupsAndLinesCard';
import OrgOverviewSection from './OrgOverviewSection/OrgOverviewSection';
import StagesSection from './StagesSection/StagesSection';

import './OrgDetailsPage.scss';

export default function OrgDetailsPage(): React.ReactElement {
  const { organization } = useOrgsForDropDown();

  const orgDetails = useRecoilValue(OrgDetailsState);
  const hasEditOrgPermission = useRecoilValue(
    IsUserATeamMemberOrNewsieAdminSelector(orgDetails.teamMembers),
  );

  useEffect(() => {
    document.title = organization.name + ' | Newsie';
  }, [organization.name]);

  return (
    <div className="org-details">
      <Header
        superHeaderText={<></>}
        productLink={false}
        productName={hasEditOrgPermission ? 'Edit Org Details' : 'View Org Details'}
      />
      <div className="org-details-container">
        <OrgOverviewSection />
        <OrgAdminSection />
        <StagesSection />
        <GroupsAndLinesCard hasEditOrgPermission={hasEditOrgPermission} />
      </div>
      <Link to={`/${organization.url}`}>
        <button className="button-white">Return to Org Page</button>
      </Link>
    </div>
  );
}
