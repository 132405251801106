import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, MenuItem, Select, ThemeProvider } from '@mui/material';
import React from 'react';
import { muiTabSelectTheme } from 'styles/material-ui/MaterialUIStyles';

interface Props {
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
  tabMenuItemNames: string[];
}

export default function MobileTabsDropdown({
  activeTab,
  setActiveTab,
  tabMenuItemNames,
}: Props): React.ReactElement {
  return (
    <ThemeProvider theme={muiTabSelectTheme}>
      <FormControl className="tab-select-form" data-testid="tab-select-form">
        <Select
          variant="standard"
          value={activeTab}
          IconComponent={(props) => <FontAwesomeIcon {...props} icon={'caret-down' as IconName} />}
          inputProps={{
            name: 'tab-select',
            className: 'tab-select bold',
            'data-testid': 'tab-select',
          }}
          MenuProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'left' },
          }}
          disableUnderline
          onChange={(e) => {
            setActiveTab(e.target.value as number);
          }}
        >
          {tabMenuItemNames.map((name, index) => (
            <MenuItem
              key={name}
              className="tab-select-option"
              data-testid="tab-select-option"
              value={index}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
