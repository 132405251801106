import React from 'react';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import useOrgsForDropDown from 'hooks/useOrgsForDropDown';
import useProductsContext from 'hooks/useProductsContext';
import useProductHierarchy from 'hooks/useProductHierarchy';

import Groups from './Groups/Groups';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

import './ArchivedProducts.scss';

export interface ProductsToLineMap {
  line: ProductHierarchy;
  products: Product[];
}

export default function ArchivedProducts(): React.ReactElement {
  const { org = '' } = useParams();

  const { products } = useProductsContext();
  const { orgDetails } = useOrgsForDropDown();
  useProductHierarchy();

  const organization = useRecoilValue(CurrentOrgSelector(org));
  document.title = `${orgDetails.doneText} | ${organization.name} | Newsie`;

  switch (products.state) {
    case 'Loaded':
      return (
        <div>
          <div className="archived-products-title product-header">
            <div className="content-container">
              <h6 data-testid="header-product-hierarchy" className="org-title">
                <Link
                  to={`/${organization.url}`}
                >
                  {organization.name}
                </Link>
              </h6>
              <h1 className="done-text-title">{orgDetails.doneText} Products</h1>
            </div>
          </div>
          <div className="product-groups content-container">
            <Groups
              archivedProducts={products.products.filter((product: Product) => product.done)}
              org={org}
            />
          </div>
        </div>
      );
    case 'Error':
      return <ErrorMessageView />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
