import React, { ReactElement, useState } from 'react';
import { Header } from 'common/Header/Header';
import { Event } from 'data/commonTypes';
import Links from 'common/Links/Links';
import TeamMembers from 'common/TeamMember/TeamMembers';
import ProductNavigation from 'common/ProductNavigation/ProductNavigation';
import ProductTaxonomyModal from './ProductTaxonomyModal/ProductTaxonomyModal';
import ProductTabs from './ProductTabs/ProductTabs';
import { useParams } from 'react-router';
import Link from 'common/Link/Link';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CDSIDFromTokenSelector, IsOrgAdminSelector } from 'state/AccessTokenState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { ProductHierarchyByIdInState } from 'state/ProductHierarchyState';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import ErrorMessageState from 'state/ErrorMessageState';
import IsContentChangedState from 'state/ContentChangedState';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';

import Product from 'types/Product';
import { PageType } from 'types/Page';
import RelevantLink from 'types/RelevantLink';
import TeamMember from 'types/TeamMember';
import ProductService from 'services/api/ProductService';
import PermissionService from 'services/PermissionService';
import IsDesktopState from '../../../state/IsDesktopState';

export default function ProductPageContent(): ReactElement {
  const { org = '', productId = '' } = useParams();

  const isDesktop = useRecoilValue(IsDesktopState);
  const isOrgAdmin = useRecoilValue(IsOrgAdminSelector);
  const [currentProduct, setCurrentProduct] = useRecoilState(CurrentProductSelector(productId));
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const setIsContentChanged = useSetRecoilState(IsContentChangedState);
  const orgDetails = useRecoilValue(OrgDetailsState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const productLineState = useRecoilValue(
    ProductHierarchyByIdInState(currentProduct.productLineId),
  );
  const productGroupState = useRecoilValue(
    ProductHierarchyByIdInState(productLineState?.parentId ?? ''),
  );

  const [showProductTaxonomyModal, setShowProductTaxonomyModal] = useState(false);

  function isOnDesktopAndCanEdit(): boolean {
    const orgAdmins = orgDetails.teamMembers || [];

    return (
      (PermissionService.isTeamMemberOrNewsieAdmin(cdsid, currentProduct.teamMembers) ||
        PermissionService.isTeamMember(cdsid, orgAdmins)) &&
      isDesktop
    );
  }

  function updateProduct(
    field: string,
    event: Event,
    content:
      | string
      | Array<TeamMember>
      | Array<RelevantLink>
      | boolean
      | Map<PageType, string[]>
      | undefined,
  ) {

    if (currentProduct && currentProduct.id) {
      const productToUpdate: Product = { ...currentProduct };
      //@ts-ignore
      productToUpdate[field] = content;
      saveProduct(productToUpdate).then(() => {
        setCurrentProduct(productToUpdate);
      });
    }
  }

  async function saveProduct(productToUpdate: Product): Promise<void> {
    return ProductService.editProduct(productToUpdate)
      .then((response) => {
        setIsContentChanged(false);
        if (response.status > 205) {
          setErrorMessage({ ...errorMessage, caughtError: true});
        } else {
          setErrorMessage({ ...errorMessage, caughtError: false});
        }
      })
      .catch(() => {
        setErrorMessage({ ...errorMessage, caughtError: true });
      });
  }

  function linkOrText() {
    if (productLineState) {
      return (
        <Link
          data-testid={'line-link'}
          to={`/${org}/line/${encodeURIComponent(productLineState.id)}/${encodeURIComponent(
            productLineState.name,
          )}/profile`}
        >
          {productLineState.name}
        </Link>
      );
    }
  }
  function superHeaderText() {
    return (
      <span>
        &nbsp;&gt; {productGroupState?.name ?? ''} &gt;&nbsp;
        {productLineState && linkOrText()}
      </span>
    );
  }

  if (!currentProduct) {
    return <ErrorMessageView />;
  } else if (currentProduct.id) {
    return (
      <>
        <Header
          productName={currentProduct.name}
          superHeaderText={superHeaderText()}
          setEditing={setShowProductTaxonomyModal}
          productLink={true}
        />
        <div className="content-container product-container-flex" id="content-container">
          {isDesktop && (
            <div className="product-content-left">
              <Links
                links={currentProduct.relevantLinks}
                trackingName={`${currentProduct.name} | ${currentProduct.id}`}
                orgParam={org}
                showEditPencil={isOnDesktopAndCanEdit() || isOrgAdmin}
                updateContent={updateProduct}
                location="product"
              />
              <TeamMembers
                title="TEAM"
                teamMembers={currentProduct.teamMembers}
                updateContent={updateProduct}
                location="product"
              />
            </div>
          )}
          <ProductTabs
            isDesktopAndTeamMemberOrAdmin={isOnDesktopAndCanEdit()}
            product={currentProduct}
            updateProduct={updateProduct}
          />
        </div>
        <ProductNavigation />
        {showProductTaxonomyModal && (
          <ProductTaxonomyModal
            setEditing={setShowProductTaxonomyModal}
            saveProduct={saveProduct}
          />
        )}
      </>
    );
  } else return <></>;
}
