import React, { useState } from 'react';
import { TextField } from '@mui/material';
import ProductHierarchy, {
  AddProductHierarchyRequest,
  HierarchyType,
} from 'types/ProductHierarchy';
import ProductGroupSelect from 'common/ProductGroupSelect/ProductGroupSelect';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import ProductHierarchyState, {
  Loaded,
  ProductHierarchyByTypeInState,
  ProductLinesForTheGivenGroupIdInState,
} from 'state/ProductHierarchyState';
import ProductHierarchyService from 'services/api/ProductHierarchyService';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { ModalContentsState } from 'state/ModalContentsState';

import GroupsAndLinesModalWrapper from '../../GroupsAndLinesModalWrapper/GroupsAndLinesModalWrapper';

import styles from './AddNewLineModal.module.scss';

interface LineField {
  value: string;
  isError: boolean;
}

interface Props {
  group: ProductHierarchy;
  setExpanded(expandedIndex: number): void;
}

function AddNewLineModal(props: Readonly<Props>) {
  const { group, setExpanded } = props;

  const closeModal = useResetRecoilState(ModalContentsState);
  const allGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const allLinesInGroup = useRecoilValue(ProductLinesForTheGivenGroupIdInState(group.id));
  const orgDetails = useRecoilValue(OrgDetailsState);
  const [productHierarchyState, setProductHierarchyState] = useRecoilState(ProductHierarchyState);
  const productHierarchy: ProductHierarchy[] =
    (productHierarchyState.state === 'Loaded' && productHierarchyState.productHierarchy) || [];

  const [productLineField, setProductLineField] = useState<LineField>({
    value: '',
    isError: false,
  });

  function generateAddRequest(): AddProductHierarchyRequest {
    const lines = [
      {
        name: productLineField.value.trim(),
        orgId: orgDetails.id,
        type: HierarchyType.LINE,
      },
    ] as ProductHierarchy[];

    return { orgId: orgDetails.id, group, lines };
  }

  function duplicateLineNamesFound(): boolean {
    const duplicateGroupFound =
      allLinesInGroup.filter(
        (line) => line.name.toLowerCase() === productLineField.value.toLowerCase(),
      ).length > 0;

    setProductLineField({ ...productLineField, isError: duplicateGroupFound });

    return duplicateGroupFound;
  }

  async function onSubmit(): Promise<void> {
    if (duplicateLineNamesFound()) return Promise.resolve();

    const response = generateAddRequest();

      const data = await ProductHierarchyService.addProductHierarchy(response);
      if (Array.isArray(data)) {
        setProductHierarchyState({
          ...productHierarchyState,
          productHierarchy: [...productHierarchy, ...data],
        } as Loaded);
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setExpanded(data[0].order!);

      closeModal();

  }

  function isFormPopulated() {
    return !!productLineField.value;
  }

  return (
    <GroupsAndLinesModalWrapper
      title="Add New Line"
      subtitle="This new Line will not appear on the org page until after a product has been added to the Line."
      saveBtnText="Add Line"
      onSubmit={onSubmit}
      isFormPopulated={isFormPopulated}
      useEffectDeps={[productLineField]}
    >
      <div className={styles.content}>
        <TextField
          label="Line Name"
          required
          defaultValue={productLineField.value}
          onChange={(event) => setProductLineField({ value: event.target.value, isError: false })}
          className={styles.textField}
          inputProps={{
            'data-testid': 'addLineNameInput',
            title: `Enter Line Name`,
          }}
          error={productLineField.isError}
          helperText={
            productLineField.isError && 'There is already a Line with the same name in the Group'
          }
        />
        <div className={styles.groupDropdown}>
          <ProductGroupSelect selectedProductGroupId={group.id} listOfGroups={allGroups} disabled />
        </div>
      </div>
    </GroupsAndLinesModalWrapper>
  );
}

export default AddNewLineModal;
