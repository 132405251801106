import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRecoilValue } from 'recoil';
import ErrorMessageState from 'state/ErrorMessageState';

//TODO Not sure if this is needed
export default function ErrorTag(): React.ReactElement {
  const errorMessage = useRecoilValue(ErrorMessageState);

  return (
    <>
      {errorMessage.caughtError &&
        (
          <div className="error-box">
            <FontAwesomeIcon
              icon={'exclamation-circle' as IconName}
              aria-hidden="true"
              title="Exclamation error"
            />
            <span aria-roledescription="alert">{errorMessage.errorMessage}</span>
          </div>
        )
      }
    </>

  );
}
