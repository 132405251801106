import React from 'react';
import Modal from 'common/Modal/DepricatedModal/Modal';
import AddProductModalContent from './AddProductModalContent/AddProductModalContent';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function AddProductModal({ isOpen, setIsOpen }: Props): React.ReactElement {
  return (
    <>
      {isOpen && (
        <Modal
          title="Add New Product"
          isOpenModal={true}
          closeHandler={() => {
            setIsOpen(false);
          }}
          showCloseIcon={true}
        >
          <AddProductModalContent />
        </Modal>
      )}
    </>
  );
}
