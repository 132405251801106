import axios from 'axios';
import OrgForKanban from 'types/OrgForKanban';
import OrgForDropdown, { OrgOverview } from 'types/OrgForDropdown';
import TeamMember from 'types/TeamMember';

const OrgService = {
  async getAllOrganizations(): Promise<OrgForDropdown[]> {
    return axios.get('/api/orgs').then((res) => res.data);
  },

  async getOrgDetailsByOrgId(orgId: string): Promise<OrgForKanban> {
    return axios.get(`/api/orgdetails/${orgId}`).then((res) => res.data);
  },

  async updateOrgTeamMembers(orgId: string, teamMembers: TeamMember[] | undefined): Promise<void> {
    return axios.put(`/api/orgs/${encodeURIComponent(orgId)}/team-members`, teamMembers);
  },

  updateOrgOverview(orgId: string, orgOverview: OrgOverview) {
    return axios.put(`/api/orgs/${encodeURIComponent(orgId)}/overview`, orgOverview);
  },
};

export default OrgService;
