import React, { useState } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findImages } from 'utils/utils';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import Modal from 'common/Modal/DepricatedModal/Modal';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';


import './SectionImageGallery.scss';

interface Props {
  content: string;
  title: string;
  page: string;
}

export function SectionImageGallery({ content, title }: Props): React.ReactElement {
  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>([]);
  const [galleryIsOpen, setGalleryIsOpen] = useState<boolean>(false);

  function printImageGalleryButton() {
    const imagesInContent = findImages(content);
    if (imagesInContent.length > 0)
      return (
        <LightTooltip
          title={'Click to view images in gallery view'}
          data-testid={'image-gallery-tooltip'}
          placement="top-start"
          arrow
        >
          <button
            data-testid="gallery-view"
            aria-label="enlarge image"
            className="gallery-view-button"
            onClick={() => {
              setGalleryIsOpen(true);
              setGalleryImages(imagesInContent);
            }}
          >
            <FontAwesomeIcon icon={'image' as IconName} />
          </button>
        </LightTooltip>
      );
  }

  return (
    <>
      {galleryIsOpen && (
        <Modal
          title={title}
          isOpenModal={galleryIsOpen}
          closeHandler={() => setGalleryIsOpen(false)}
          showCloseIcon={true}
          className="gallery-modal"
        >
          <ImageGallery
            items={galleryImages}
            showPlayButton={false}
            showThumbnails={false}
            showBullets={true}
          />
        </Modal>
      )}
      {printImageGalleryButton()}
    </>
  );
}
