import DashDivider from 'common/DashDivider/DashDivider';
import OrgCard from 'common/OrgCard/OrgCard';
import { Link } from 'react-router-dom';
import React from 'react';
import OrgForDropdown from 'types/OrgForDropdown';

import styles from './AllOrgsList.module.scss';
import IsDesktopState from '../../../state/IsDesktopState';
import { useRecoilValue } from 'recoil';
import TempFlagsmithReplacement from '../../../utils/TempFlagsmithReplacement';

interface Props {
  orgs: OrgForDropdown[];
}

export default function AllOrgsList({orgs} : Props) {
  const isDesktop = useRecoilValue(IsDesktopState)
  const filteredOrgsList: OrgForDropdown[] = TempFlagsmithReplacement.getFeaturedOrgs(orgs, isDesktop)

  return (
    <div className="browse-orgs">
      <span className="company-name">FORD</span>
      <h2>Browse organizations</h2>
      <DashDivider />
      <div className="org-list">
        {filteredOrgsList && filteredOrgsList.map((org) => {
          return (
            <OrgCard
              key={org.name}
              orgName={org.name}
              orgIcon={org.icon}
              orgDescription={org.blurb}
              orgLink={'/' + org.url}
            />
          );
        })}
      </div>
      <Link
        to="/all"
        className={`button-blue ${styles.viewAllOrgsLink}`}
        data-testid="view-all-orgs"
      >
        View All Organizations
      </Link>
    </div>
  );
}
