import { TextField } from '@mui/material';
import React, { useState } from 'react';
import TeamMember from 'types/TeamMember';

import './CdsidInputBox.scss';

interface Props {
  teamMember: Partial<TeamMember>;
  setTeamMember: (member: Partial<TeamMember>) => void;
  setShowErrorCallback?: (show: boolean) => void;
}
export default function CdsidInputBox({
  teamMember,
  setTeamMember,
  setShowErrorCallback,
}: Props): React.ReactElement {
  const [showError, setShowError] = useState<boolean>(!isCdsidValid(teamMember.cdsid ?? ''));
  function isCdsidValid(cdsid: string) {
    return cdsid.match(/[@.]/g)?.length === undefined;
  }
  return (
    <div className={'text-field-cdsid'}>
      <TextField
        label="CDSID"
        id="cdsid"
        error={showError}
        type="text"
        variant="outlined"
        fullWidth={true}
        defaultValue={teamMember.cdsid}
        onChange={(event) => {
          const cdsid = event.target.value;
          setShowError(!isCdsidValid(cdsid));
          setTeamMember({ ...teamMember, cdsid });
          if (setShowErrorCallback) {
            setShowErrorCallback(!isCdsidValid(cdsid));
          }
        }}
        required={true}
        inputProps={{
          'data-testid': 'cdsid-input',
          pattern: '((?!@).)*',
          title: 'Please provide CDSID only',
        }}
        helperText={showError ? 'Please provide CDSID only' : ''}
      />
    </div>
  );
}
