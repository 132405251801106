import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from 'images/logo.png';
import Link from 'common/Link/Link';
import HelpDropdown from 'common/Nav/HelpDropdown/HelpDropdown';

import './Nav.scss';
import ExploreDropdown from './ExploreDropdown/ExploreDropdown';

export default function Nav(): React.ReactElement {
  const [isExploreDropdownOpen, setIsExploreDropdownOpen] = useState<boolean>(false);
  const [isHelpDropdownOpen, setIsHelpDropdownOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [isSticky, setIsSticky] = useState<boolean>(pathname == '/' || pathname == '/all');

  useEffect(() => {
    if (pathname == '/' || pathname == '/all') {
      setIsSticky(true);
    } else {
      setIsSticky(isExploreDropdownOpen);
    }
  }, [isExploreDropdownOpen, pathname]);

  function toggleDropdown(location: string) {
    if (location === 'help') {
      setIsExploreDropdownOpen(false);
      setIsHelpDropdownOpen(!isHelpDropdownOpen);
    }
    if (location === 'explore') {
      setIsExploreDropdownOpen(!isExploreDropdownOpen);
      setIsHelpDropdownOpen(false);
    }
  }

  function getIcon(location: string): string {
    if (location === 'help') {
      return isHelpDropdownOpen ? 'caret-up' : 'caret-down';
    } else {
      return isExploreDropdownOpen ? 'caret-up' : 'caret-down';
    }
  }

  function getMobileIcon(): string {
    return isExploreDropdownOpen ? 'times' : 'bars';
  }

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      if (e.target instanceof Element) {
        if (isExploreDropdownOpen) {
          setIsExploreDropdownOpen(false);
          setIsHelpDropdownOpen(false);
        }
      }
      e.stopPropagation();
      e.preventDefault();
    }
  });

  document.getElementById('main-content')?.addEventListener('focusin', () => {
    setIsExploreDropdownOpen(false);
    setIsHelpDropdownOpen(false);
  });

  document.getElementById('main-content')?.addEventListener('click', (e) => {
    if (e.target instanceof Element) {
      if (isExploreDropdownOpen || isHelpDropdownOpen) {
        setIsExploreDropdownOpen(false);
        setIsHelpDropdownOpen(false);
      }
    }
  });

  return (
    <nav className={`nav-header ${isSticky ? 'sticky' : ''}`}>
      <div className="nav-container">
        <p className="nav-title">
          <Link
            to={'/'}
            tabIndex={0}
            onClick={() => {
              setIsExploreDropdownOpen(false);
              setIsHelpDropdownOpen(false);
            }}
          >
            <img src={logo} alt="Newsie logo with Newsie cap" />
          </Link>
        </p>
        <button className="button-clear desktop-only" onClick={() => toggleDropdown('help')}>
          HELP
          <FontAwesomeIcon className="dropdown-caret menu" icon={getIcon('help') as IconName} />
        </button>
        <button className="button-blue desktop-only" onClick={() => toggleDropdown('explore')}>
          EXPLORE
          <FontAwesomeIcon className="dropdown-caret menu" icon={getIcon('explore') as IconName} />
        </button>
        <button
          aria-label="menu"
          aria-expanded={isExploreDropdownOpen}
          onClick={() => toggleDropdown('explore')}
        >
          <FontAwesomeIcon
            className="icon mobile-only nav-hamburger"
            icon={getMobileIcon() as IconName}
            aria-hidden="true"
          />
        </button>
      </div>
      {isHelpDropdownOpen && <HelpDropdown />}
      {isExploreDropdownOpen && <ExploreDropdown toggleDropdown={toggleDropdown} />}
    </nav>
  );
}
