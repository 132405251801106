import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Event } from 'data/commonTypes';
import IsContentChangedState from 'state/ContentChangedState';
import ErrorMessageState from 'state/ErrorMessageState';
import { extractErrorMessage } from 'utils/utils';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import ErrorTag from 'common/ErrorTag/ErrorTag';
import { Loaded, PageLoad } from 'pages/product-page/ProductPageContent/ProductTabs/ProductTabs';
import { GetPageTemplateByPageAndTemplateType, OrgDetailsState } from 'state/OrgDetailsState';
import { usePrompt } from 'hooks/usePrompt';
import PageService from 'services/api/PageService';
import KanbanStage from './KanbanState/KanbanStage';
import ContentBox from 'common/Overview/ContentBox/ContentBox';

import Page, { PageSection, PageType, ParentType } from 'types/Page';
import RelevantLink from 'types/RelevantLink';
import TeamMember from 'types/TeamMember';
import ProductActionButtons from './ProductActionButtons/ProductActionButtons';
import PermissionService from 'services/PermissionService';
import IsDesktopState from 'state/IsDesktopState';
import { CDSIDFromTokenSelector, IsOrgAdminSelector } from 'state/AccessTokenState';

export interface ProfileParent {
  id: string;
  name: string;
  done?: boolean;
  stage?: string;
  teamMembers: TeamMember[] | null;
}

interface Props {
  parentProduct: ProfileParent;
  updateProductCallback?: (
    field: string,
    event: Event,
    content: string | Array<TeamMember> | Array<RelevantLink> | boolean | undefined,
  ) => void;
  parentType: ParentType;
}

export default function Overview({
  parentProduct,
  updateProductCallback,
  parentType,
}: Props): React.ReactElement {
  const isDesktop = useRecoilValue(IsDesktopState);
  const isOrgAdmin = useRecoilValue(IsOrgAdminSelector);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  const orgDetails = useRecoilValue(OrgDetailsState);
  const profilePageTemplate = useRecoilValue(
    GetPageTemplateByPageAndTemplateType({
      pageType: PageType.PROFILE,
      templateType: parentType,
    }),
  );
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const [isContentChanged, setIsContentChanged] = useRecoilState(IsContentChangedState);

  const [pageLoad, setPageLoad] = useState<PageLoad>({ state: 'Loading' });

  function isOnDesktopAndCanEdit(): boolean {
    const orgAdmins = orgDetails.teamMembers || [];

    return (
      (PermissionService.isTeamMemberOrNewsieAdmin(cdsid, parentProduct.teamMembers) ||
        PermissionService.isTeamMember(cdsid, orgAdmins)) &&
      isDesktop
    );
  }

  usePrompt(
    'There are unsaved changes that will be lost. Are you sure you want to leave?',
    isContentChanged,
  );

  useEffect(() => {
      setErrorMessage({ ...errorMessage, caughtError: false });
  }, []);

  useEffect(() => {
    if (parentProduct.id) {
      setPageLoad({ state: 'Loading' });

      getProfilePageFromAPI();

      document.title = `${parentProduct.name} | Overview | Newsie`;

      return () => {
        setPageLoad({ state: 'Loading' });
      };
    }
  }, [parentProduct.id]);

  function getProfilePageFromAPI() {
    PageService.getOverviewPage(parentType, parentProduct.id)
      .then((page) => setPageLoad({ state: 'Loaded', page }))
      .catch((e) => {
        setPageLoad({
          state: 'Error',
          message: extractErrorMessage(
            e,
            `Error getting Profile page for ${parentType} ${parentProduct.id}`,
          ),
        });
      });
  }

  function createFieldFromTitle(title: string): string {
    return title
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  function OverviewPage(page: Page | undefined): React.ReactElement {
    async function updateOverviewSection(title: string, content: string): Promise<void> {
      await savePage(title, content)
        .then((response) => {
          setIsContentChanged(false);
          setErrorMessage({ ...errorMessage, caughtError: false });
          setPageLoad({ ...(pageLoad as Loaded), page: response });
          return new Promise<void>((resolved) => resolved());
        })
        .catch(() => {
          setErrorMessage({ ...errorMessage, caughtError: true });
          return new Promise<void>((_, rejected) => rejected());
        });
    }

    function savePage(title: string, content: string): Promise<Page> {
      if (!page) {
        return PageService.addPage({
          parentId: parentProduct.id,
          pageType: PageType.PROFILE,
          sections: [{ title, content }],
          parentType: parentType,
        });
      }
      //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return PageService.editPage(page!.id!, [{ title, content }]);
    }
    function OverviewSections(page: Page | undefined) {
      const templateSections = profilePageTemplate ? profilePageTemplate.sections : [];

      if (templateSections.length === 0) {
        return (
          <span>
            There are no overview sections in Newsie for this {parentType.toLocaleLowerCase()}.
          </span>
        );
      } else {
        return (
          <>
            {templateSections.map((pageSection: PageSection) => {
              const sectionFound: PageSection | undefined = page?.sections
                ? page.sections.find((section) => section.title === pageSection.title)
                : undefined;
              return (
                <ContentBox
                  key={pageSection.title}
                  isOnDesktopIsEditorOrAdmin={isOnDesktopAndCanEdit()}
                  title={pageSection.title}
                  content={sectionFound?.content ?? pageSection.content ?? ''}
                  field={createFieldFromTitle(pageSection.title)}
                  updateOverviewSection={updateOverviewSection}
                />
              );
            })}
          </>
        );
      }
    }

    return (
      <div className="overview tab-content-container">
        <ErrorTag/>
        {parentType === ParentType.PRODUCT && orgDetails && orgDetails.stages && (
          <KanbanStage
            currentStage={parentProduct.stage}
            stagesInOrg={orgDetails.stages}
            isOnDesktopIsEditorOrAdmin={isOnDesktopAndCanEdit()}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            updateProduct={updateProductCallback!}
          />
        )}
        {OverviewSections(page)}
        {parentType === ParentType.PRODUCT &&
          orgDetails.doneText &&
          (isOnDesktopAndCanEdit() || (isDesktop && isOrgAdmin)) && (
            <ProductActionButtons
              parentProduct={parentProduct}
              updateProductCallback={updateProductCallback}
            />
          )}
      </div>
    );
  }

  switch (pageLoad.state) {
    case 'Loaded':
      if (!pageLoad.page || pageLoad.page.parentId === parentProduct.id)
        return OverviewPage(pageLoad.page);
      return <></>;
    case 'Error':
      return <ErrorMessageView />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
