import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Button } from '@mui/material';
import React from 'react';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
}

export default function OrgActionsButton({ onClick, open }: Props) {
  return (
    <Button
      id="org-actions-button"
      data-testid="org-actions-button"
      aria-controls={open ? 'org-actions-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      variant="contained"
      disableElevation
      disableRipple
      disableFocusRipple
      onClick={onClick}
      endIcon={
        open ? (
          <FontAwesomeIcon className="manage-caret" icon={'caret-up' as IconName} />
        ) : (
          <FontAwesomeIcon className="manage-caret" icon={'caret-down' as IconName} />
        )
      }
      sx={{
        backgroundColor: 'white',
        color: '#0976b5',
        border: '3px solid #0976b5',
        boxShadow: 'none',
        borderRadius: 0,
        width: '230px',
        fontWeight: '700',
        padding: '10px 15px',
        textTransform: 'uppercase',
        '&:hover': {
          backgroundColor: 'white',
          color: '#0976b5',
          border: '3px solid #0976b5',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      }}
    >
      Org Actions
    </Button>
  );
}
