import DragAndDrop from './DragAndDrop/DragAndDrop';
import { Droppable } from 'react-beautiful-dnd';
import React, { Fragment } from 'react';
import AddNewGroupButton from '../AddNewGroupButton/AddNewGroupButton';
import DoneButton from '../DoneButton/DoneButton';
import DraggableItem from './DraggableItem/DraggableItem';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';

interface Props {
  expanded: number;
  setExpanded: (expandedIndex: number) => void;
  isEditMode: boolean;
  productHierarchies: ProductHierarchy[];
  handleEditModeToggle: () => void;
}

export default function DragAndDropSection(props: Props) {
  const { expanded, setExpanded, isEditMode, productHierarchies, handleEditModeToggle } = props;

  const lines = productHierarchies.filter(
    (ph: { type: HierarchyType }) => ph.type === HierarchyType.LINE,
  );
  const groups = productHierarchies.filter((ph: { id: string; type: HierarchyType }) => {
    return ph.type === HierarchyType.GROUP;
  });

  return (
    <DragAndDrop groups={groups} setExpanded={setExpanded}>
      <Droppable droppableId={'group'}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className="box-info">
              {groups.map((group: ProductHierarchy, index: number) => (
                <Fragment key={`${group.name}-${index}`}>
                  <DraggableItem
                    group={group}
                    index={index}
                    isEditMode={isEditMode}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    lines={lines}
                  />
                </Fragment>
              ))}
              {isEditMode && (
                <>
                  <AddNewGroupButton groupsLength={groups.length} setExpanded={setExpanded} />
                  <DoneButton onClick={handleEditModeToggle} />
                </>
              )}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragAndDrop>
  );
}
