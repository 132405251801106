import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import closeButton from 'images/close-button.svg';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import CdsidInputBox from 'common/CdsidInputBox/CdsidInputBox';
import { GrayTooltip } from 'styles/material-ui/MaterialUIStyles';
import TeamMember from 'types/TeamMember';
import TextFieldTooltip from 'common/TextFieldTooltip/TextFieldTooltip';

import './AddTeamMembersSection.scss';

interface Props {
  teamMembers: TeamMember[];
  setTeamMembers: (teamMembers: Array<TeamMember>) => void;
}

export function AddTeamMembersSection({ teamMembers, setTeamMembers }: Props): React.ReactElement {
  const [showBorder, setShowBorder] = useState(-1);

  function addBorderIfHoveringOnCloseButton(index: number): string {
    let classes = '';
    if (showBorder === index) {
      classes += 'close-button-hover';
    }
    return classes;
  }

  return (
    <>
      {teamMembers.map((teamMember, index) => {
        return (
          <div
            key={teamMember.cdsid === '' ? 'emptyTeamMember' + Math.random() : teamMember.cdsid}
            className={`add-team-member-section ${addBorderIfHoveringOnCloseButton(index)}`}
          >
            {index > 0 && (
              <button
                type="button"
                title="remove team member"
                className="remove-team-member-button"
                aria-label="remove team member section"
                data-testid={`remove-team-member-section-button-${index}`}
                onClick={() => {
                  const updatedTeamMembers = teamMembers;
                  updatedTeamMembers.splice(index, 1);
                  setTeamMembers([...updatedTeamMembers]);
                }}
                onMouseEnter={() => setShowBorder(index)}
                onMouseLeave={() => setShowBorder(-1)}
              >
                <img alt="remove team member button" src={closeButton} />
              </button>
            )}
            <div className={'dialog-input-wrapper'}>
              <TextField
                id="fullname"
                fullWidth={true}
                defaultValue={teamMember.name}
                onChange={(event) => {
                  const updatedTeamMembers = teamMembers;
                  updatedTeamMembers[index] = {
                    ...updatedTeamMembers[index],
                    name: event.target.value,
                  };
                  setTeamMembers(updatedTeamMembers);
                }}
                label={'Full Name'}
                variant="outlined"
                required={true}
                inputProps={{ 'data-testid': 'full-name-input', title: 'Please Enter Full Name' }}
              />
            </div>
            <div className={'dialog-input-wrapper'}>
              <TextField
                id="role"
                fullWidth={true}
                defaultValue={teamMember.title}
                onChange={(event) => {
                  const updatedTeamMembers = teamMembers;
                  updatedTeamMembers[index] = {
                    ...updatedTeamMembers[index],
                    title: event.target.value,
                  };
                  setTeamMembers(updatedTeamMembers);
                }}
                label={'Role'}
                variant="outlined"
                required={true}
                inputProps={{ 'data-testid': 'title-input', title: 'Please Enter Role' }}
              />
            </div>
            <div className={'dialog-input-wrapper text-field-with-tooltip'}>
              <CdsidInputBox
                teamMember={teamMember}
                setTeamMember={({ cdsid }) => {
                  const updatedTeamMembers = teamMembers;
                  updatedTeamMembers[index] = {
                    ...updatedTeamMembers[index],
                    cdsid,
                  };
                  setTeamMembers(updatedTeamMembers);
                }}
              />

              <GrayTooltip
                title="Required for team members to have editing access of your Newsie space"
                role="tooltip"
                aria-label="Required for team members to have editing access of your Newsie space"
                placement="right"
              >
                {TextFieldTooltip({ dataTestid: 'cdsid-tooltip' })}
              </GrayTooltip>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        className="add-team-member-button"
        onClick={() => {
          setTeamMembers([...teamMembers, { name: '', title: '', cdsid: '' }]);
        }}
      >
        <FontAwesomeIcon icon={'plus-circle' as IconName} />
        &nbsp;Add Another Team Member
      </button>
    </>
  );
}
