import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { AccessTokenState } from 'state/AccessTokenState';
import TokenService from 'services/TokenService';

export default function HandleRedirect(): React.ReactElement | null {
  const setAccessToken = useSetRecoilState(AccessTokenState);
  const searchParams = new URLSearchParams(window.location.hash.substring(1));
  const accessToken = searchParams.get('access_token') || '';
  const expiresIn = Number(searchParams.get('expires_in'));

  const expiresAt = Date.now() + expiresIn * 1000;
  const redirectPath = window.location.pathname.replace('/oauth/redirect', '');

  const finalRedirect = `${redirectPath}${window.location.search}`;

  useEffect(() => {
    setAccessToken(accessToken);
    TokenService.setToken(accessToken);
    TokenService.setExpiry(expiresAt);
  }, []);

  return <Navigate to={finalRedirect} />;
}
