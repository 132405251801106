import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 'unset',
  '& .MuiAccordionDetails-content': {
    flexDirection: 'row',
  },
}));

export default AccordionDetails;
