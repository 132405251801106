export enum ActiveTab {
  PROFILE,
  UPDATE,
  INFO,
}

export enum ActiveLineTab {
  PROFILE,
  PRODUCTS,
  INFO,
}

export default function getActiveTab(currentPath: string, isProduct?: boolean): number {
  const pageType = currentPath
    .split(/[\s/]+/)
    .pop()
    ?.toLowerCase();
  if (isProduct) {
    return getProductTab(pageType);
  }
  return getLineTab(pageType);
}

function getProductTab(pageType: string | undefined): number {
  switch (pageType) {
    case 'update':
      return ActiveTab.UPDATE;
    case 'info':
      return ActiveTab.INFO;
    case 'profile':
    default:
      return ActiveTab.PROFILE;
  }
}

function getLineTab(pageType: string | undefined): number {
  switch (pageType) {
    case 'products':
      return ActiveLineTab.PRODUCTS;
    case 'info':
      return ActiveLineTab.INFO;
    case 'profile':
    default:
      return ActiveLineTab.PROFILE;
  }
}
