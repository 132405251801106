import React from 'react';
import { MenuItem } from '@mui/material';

import { Stage } from 'types/OrgForKanban';

export function stageOptions(stagesInOrg: Stage[]): React.ReactElement[] {
  const stageOptions: React.ReactElement[] = [];
  stagesInOrg.forEach((stage) => {
    stageOptions.push(
      <MenuItem key={stage.name} value={stage.name.toLowerCase()} data-testid="stage-select-option">
        {stage.name}
      </MenuItem>,
    );
  });
  stageOptions.push(
    <MenuItem
      key={stagesInOrg.length}
      value={'stages not applicable'}
      data-testid="stage-select-option"
      className="stage-not-applicable-option"
    >
      stages not applicable
    </MenuItem>,
  );
  return stageOptions;
}
