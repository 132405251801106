import React, { ReactElement, useEffect } from 'react';
import useOrgsForDropDown from '../../hooks/useOrgsForDropDown';
import LoadingMessage from '../../common/LoadingMessage/LoadingMessage';
import ErrorMessageView from '../../common/ErrorMessageView/ErrorMessageView';
import TitleBanner from './TitleBanner/TitleBanner';
import OrgsList from './OrgsList/OrgsList';

export default function ViewAllOrgsPage(): ReactElement {
  const { orgsForDropDown } = useOrgsForDropDown();

  useEffect(() => {
    document.title = 'Newsie';
  }, []);

  switch (orgsForDropDown.state) {
    case 'Loaded':
      return (
        <div className="content-container">
          <TitleBanner />
          <OrgsList orgs={orgsForDropDown.organizations} />
        </div>
      );
    case 'Error':
      return <ErrorMessageView />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
