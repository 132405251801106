import AddProductForm from './AddProductForm/AddProductForm';

import React from 'react';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import {
  hasPermissionToAddProduct,
} from 'utils/utils';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { OrgDetailsState } from 'state/OrgDetailsState';

export default function AddProductModalContent(): React.ReactElement {
  const { org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const orgDetails = useRecoilValue(OrgDetailsState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  if (hasPermissionToAddProduct(cdsid, orgDetails.canAddProduct)) {
    return (
      <AddProductForm orgDetails={orgDetails} icon={organization.icon} orgUrl={organization.url} />
    );
  }

  return (
    <>
      <ErrorMessageView
      />
    </>
  );
}
