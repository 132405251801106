import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function LoadingMessage(): React.ReactElement {
  return (
    <div className="center">
      <FontAwesomeIcon className="loading-icon fa-2x" icon={'newspaper' as IconName} />
      <p>Loading...</p>
    </div>
  );
}
