import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import ArchiveModal from './ArchiveModal/ArchiveModal';
import { ProfileParent } from '../Overview';
import { Event } from 'data/commonTypes';
import TeamMember from 'types/TeamMember';
import RelevantLink from 'types/RelevantLink';
import { ModalContentsState } from 'state/ModalContentsState';
import DeleteProductModal from './DeleteProductModal/DeleteProductModal';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { useParams } from 'react-router';

import styles from './ProductActionButtons.module.scss';
import PermissionService from '../../../services/PermissionService';

interface Props {
  parentProduct: ProfileParent;
  updateProductCallback?(
    field: string,
    event: Event,
    content: string | Array<TeamMember> | Array<RelevantLink> | boolean | undefined,
  ): void;
}

export default function ProductActionButtons(props: Props) {
  const { org: orgNameParam = '' } = useParams<{ org: string }>();

  const { parentProduct, updateProductCallback } = props;

  const isProductDone = parentProduct.done ?? false;

  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const orgDetails = useRecoilValue(OrgDetailsState);
  const setModalContents = useSetRecoilState(ModalContentsState);

  const [showArchiveModal, setShowArchiveModal] = useState(false);

  function getArchiveText(): string {
    if (isProductDone) return "Activate"

    const archiveText = orgDetails.doneText ?? '';
    return archiveText.slice(0, -1);
  }

  return (
    <>
      <div className="archive-button-container">
        {PermissionService.isNewsieAdmin(cdsid) && (
          <button
            className={`button-blue-dialog ${styles.deleteButton}`}
            onClick={() => {
              setModalContents({
                title: 'Delete Product?',
                component: (
                  <DeleteProductModal
                    productName={parentProduct.name}
                    productId={parentProduct.id}
                    orgNameParam={orgNameParam}
                  />
                ),
              });
            }}
          >
            Delete Product
          </button>
        )}
          <button
            className="button-blue"
            data-testid="archive-button"
            onClick={() => setShowArchiveModal(true)}
          >
            {getArchiveText()} Product
          </button>
      </div>
      {showArchiveModal && (
        // @todo refactor to use new modal
        <ArchiveModal
          productIsDone={parentProduct.done ?? false}
          archiveText={getArchiveText()}
          productName={parentProduct.name}
          orgDoneText={orgDetails.doneText ?? 'Graduated'}
          orgName={orgDetails.name}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          updateProduct={updateProductCallback!}
          setShowArchiveModal={setShowArchiveModal}
        />
      )}
    </>
  );
}
