import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ActiveTabState } from 'state/ActiveTabState';
import useScreenSize from './useScreenSize';

import getActiveTab, { ActiveLineTab, ActiveTab } from '../utils/getActiveTab';

interface InputProps {
  type: string;
  typeNameId: string;
}

interface OutputProps {
  activeTab: ActiveTab | ActiveLineTab;
  setActiveTab: (activeTab: ActiveTab) => void;
}

function useActiveTab({ type }: InputProps): OutputProps {
  const initialRender = useRef(true);
  const [activeTab, setActiveTab] = useRecoilState(ActiveTabState);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useScreenSize();

  const isProduct = type.toLowerCase() === 'product';

  function getTabForPage() {
    return isProduct ? ActiveTab : ActiveLineTab;
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const tabName = getTabForPage()[activeTab].toLowerCase();
      if (!pathname.includes(tabName)) {
        navigate(`../${tabName}`);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (!pathname.includes(getTabForPage()[activeTab].toLowerCase())) {
      setActiveTab(getActiveTab(pathname, isProduct));
    }
  }, [pathname]);

  useEffect(() => {
    if (isDesktop && activeTab === getTabForPage().INFO) {
      setActiveTab(getTabForPage().PROFILE.valueOf());
    }
  }, [isDesktop]);

  return { activeTab, setActiveTab };
}

export default useActiveTab;
