import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

export const muiTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:invalid': {
            borderColor: '#c72020',
          },
        },
        input: {
          '&:invalid': {
            borderColor: '#c72020',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
          '&$focused': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          backgroundColor: 'white !important',
          '&.Mui-focused ': {
            backgroundColor: '#f0f5fa !important',
            borderRadius: '4px',
          },
        },
        clearIndicator: {
          paddingTop: '5px',
          marginRight: '30px',
          color: '#0976b5',
          fontSize: '1.25rem',
          ':hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: '15px !important',
          '&:invalid': {
            borderColor: '#c72020',
            backgroundColor: '#c72020',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#097bbc !important',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif', 'Arial', 'Helvetica'].join(','),
  },
});

export const muiTabSelectTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '200px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#f6f6f6',
          borderRadius: '10px 10px 0 0',
          padding: '20px',
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: '700',
        },
        icon: {
          fontSize: '20px',
          marginRight: '18px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          justifyContent: 'center',
          fontSize: '14px',
          fontWeight: '700',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          background: '#111111C7',
        },
      },
    },
  },
});

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow:
      '0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 10%)',
    fontSize: 11,
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    right: '20px',
    top: '8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
      color: theme.palette.common.white,
      boxShadow:
        '0px 1px 2px rgb(0 0 0 / 16%), 0px 2px 4px rgb(0 0 0 / 12%), 0px 1px 8px rgb(0 0 0 / 10%)',
    },
  },
}));

export const GrayTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e2e2e2',
    color: '#111111',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
