import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import useScreenSize from 'hooks/useScreenSize';
import './ExperimentBanner.scss';

//TODO store this instead of hard coding
 export const BANNER_MESSAGE = ["✅ Attention Newsie users: All files older than two (2) years have been deleted as part of Ford's annual file review process as of Monday, June 17 at 11:45 a.m. ET", "Questions? Click here", "https://azureford.sharepoint.com/:u:/r/sites/fordlabsproductsuite/SitePages/Frequently-Asked-Questions.aspx?csf=1&web=1&e=hLFZ6u"];


export default function ExperimentBanner(): React.ReactElement {
  const isDesktop = useScreenSize();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const bannerMainText = BANNER_MESSAGE[0];
  const bannerLinkText = BANNER_MESSAGE[1];
  const bannerHref = BANNER_MESSAGE[2];

  function hasLink() {
    return BANNER_MESSAGE.length > 2;
  }

  return (
    <>
      {isDesktop && isOpen && BANNER_MESSAGE && (
        <div className="experiment-banner">
          <div className="content-container">
            <span data-testid="experiment-banner-text">{bannerMainText} {hasLink() && (
                <a
                  href={bannerHref}
                  target="_blank"
                  rel="noreferrer"
                >
                  {bannerLinkText}
                </a>
              )}</span>
            <button
              data-testid="close-button"
              aria-label="close-button"
              className="close-button"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <FontAwesomeIcon icon={'times' as IconName} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
