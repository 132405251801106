import React from 'react';
import { useRecoilValue } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';

export default function StagesSection() {
  const orgDetails = useRecoilValue(OrgDetailsState);

  return (
    <div className="details-box">
      <div className="box-header">Stages</div>
      <div className="box-info">
        {!orgDetails.stages && <div>Stages are not set</div>}
        {orgDetails.stages?.map((stage) => (
          <div key={stage.name}>
            <span>{stage.name}</span>: {stage.description}
          </div>
        ))}
      </div>
    </div>
  );
}
