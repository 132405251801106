import React, { useState } from 'react';

import DragAndDropSection from './DragAndDropSection/DragAndDropSection';
import ProductHierarchy from 'types/ProductHierarchy';
import IconButton from 'common/IconButton/IconButton';

interface Props {
  productHierarchies: ProductHierarchy[];
  hasEditOrgPermission: boolean;
}

export default function GroupsAndLinesCardContent(props: Props): React.ReactElement {
  const { productHierarchies, hasEditOrgPermission } = props;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number>(0);

  const handleEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <>
      <div className={`details-box ${isEditMode ? 'edit-mode' : ''}`}>
        <div className="box-header">
          Groups & Lines
          {hasEditOrgPermission && (
            <IconButton
              iconName={isEditMode ? 'x' : 'pen'}
              testId="groups-lines-edit-toggle"
              ariaLabel="edit groups and lines"
              onClick={handleEditModeToggle}
              className={isEditMode ? 'edit-toggle-cancel' : 'edit-toggle-pencil'}
            />
          )}
        </div>
        <DragAndDropSection
          expanded={expanded}
          setExpanded={setExpanded}
          isEditMode={isEditMode}
          productHierarchies={productHierarchies}
          handleEditModeToggle={handleEditModeToggle}
        />
      </div>
    </>
  );
}
