import React from 'react';

import { Stage } from 'types/OrgForKanban';

import './StageProgressBar.scss';

interface Props {
  stagesInOrg: Stage[];
  activeStage: string;
}

export default function StageProgressBar({ stagesInOrg, activeStage }: Props): React.ReactElement {
  const stagePosition = stagesInOrg.findIndex(
    (value) => value.name.toLowerCase() === activeStage.toLowerCase(),
  );
  return (
    <div className="stage-progress-bar">
      <ul>
        {stagesInOrg.map((stage, index) => {
          return (
            <li
              key={stage.name}
              className={`${index <= stagePosition ? 'active' : ''}${
                index === stagePosition ? ' lastActiveClass' : ''
              }`}
              data-testid={`stage-${index}${index === stagePosition ? '-active' : ''}`}
            >
              <span>{stage.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
