import { atom, selector, selectorFamily } from 'recoil';
import { getCdsidFromToken } from 'utils/utils';
import TokenService from 'services/TokenService';
import TeamMember from 'types/TeamMember';
import PermissionService from '../services/PermissionService';
import { OrgDetailsState } from './OrgDetailsState';

export const AccessTokenState = atom<string | null>({
  key: 'AccessTokenState',
  default: TokenService.getToken(),
});

export const CDSIDFromTokenSelector = selector<string>({
  key: 'CDSIDFromTokenSelector',
  get: ({ get }) => {
    const accessToken = get(AccessTokenState) || '';
    return getCdsidFromToken(accessToken);
  },
});

export const IsUserNewsieAdminSelector = selector<boolean>({
  key: 'IsUserNewsieAdminSelector',
  get: ({ get }) => {
    const cdsid = get(CDSIDFromTokenSelector);
    return PermissionService.isNewsieAdmin(cdsid);
  },
});

export const IsOrgAdminSelector = selector<boolean>({
  key: 'IsOrgAdminSelector',
  get: ({ get }) => {
    const cdsid = get(CDSIDFromTokenSelector);
    const orgAdmins = get(OrgDetailsState).teamMembers || [];

    return PermissionService.isTeamMember(cdsid, orgAdmins);
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IsUserATeamMemberOrNewsieAdminSelector = selectorFamily<boolean, any>({
  key: 'IsUserATeamMemberOrNewsieAdminSelector',
  get:
    (teamMembers: TeamMember[] | null | undefined) =>
    ({ get }) => {
      const cdsid = get(CDSIDFromTokenSelector);
      return PermissionService.isTeamMemberOrNewsieAdmin(cdsid, teamMembers);
    },
});
