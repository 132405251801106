import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { extractErrorMessage } from '../utils/utils';
import { useParams } from 'react-router';
import ProductPageDatesState, { PageDatesState } from 'state/ProductPageDatesState';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import { AccessTokenState } from 'state/AccessTokenState';
import { PageType } from 'types/Page';
import PageService from 'services/api/PageService';

interface Props {
  pageDatesForProduct: PageDatesState;
}

function usePageDatesForProduct(): Props {
  const { productId = '' } = useParams();
  const currentProduct = useRecoilValue(CurrentProductSelector(productId));
  const [pageDates, setPageDates] = useRecoilState(ProductPageDatesState);
  const accessToken = useRecoilValue(AccessTokenState);

  async function getPageDates(idForProduct: string) {
    setPageDates({ state: 'Loading' });
    await PageService.getPageDates(idForProduct, PageType.UPDATE)
      .then((pageDates) => {
        setPageDates({ state: 'Loaded', pageDates: pageDates, parentId: idForProduct });
      })
      .catch((error) => {
        setPageDates({
          state: 'Error',
          message: extractErrorMessage(error, `Error getting pagedates for ${currentProduct.id}`),
        });
      });
  }

  useEffect(() => {
    if (accessToken && currentProduct.id) {
      getPageDates(currentProduct.id);
    }
  }, [currentProduct.id]);

  return { pageDatesForProduct: pageDates };
}

export default usePageDatesForProduct;
