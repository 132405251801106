import React, { ReactElement } from 'react';
import ErrorMessageView from '../../common/ErrorMessageView/ErrorMessageView';
import ProductPageContent from './ProductPageContent/ProductPageContent';
import useOrgsForDropDown from '../../hooks/useOrgsForDropDown';
import useProductsContext from '../../hooks/useProductsContext';
import useProductHierarchy from '../../hooks/useProductHierarchy';
import LoadingMessage from '../../common/LoadingMessage/LoadingMessage';
import usePageDatesForProduct from '../../hooks/usePageDatesForProduct';

export default function ProductPage(): ReactElement {
  useOrgsForDropDown();
  useProductHierarchy();
  usePageDatesForProduct();
  const { products } = useProductsContext();

  switch (products.state) {
    case 'Loaded':
      return <ProductPageContent />;
    case 'Error':
      return (
        <ErrorMessageView />
      );
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
