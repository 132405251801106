export enum ParentType {
  PRODUCT = 'PRODUCT',
  LINE = 'LINE',
}

export enum PageType {
  PROFILE = 'PROFILE',
  UPDATE = 'UPDATE',
}

export interface PageTemplate {
  pageType: PageType;
  templateType: ParentType;
  sections: PageSection[];
}

export interface PageSection {
  title: string;
  content?: string;
  icon?: string;
}

export default interface Page {
  id?: string;
  parentId: string;
  date?: string;
  pageType: PageType;
  sections: PageSection[];
  parentType: ParentType;
}
