import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import React, { useState } from 'react';
import TeamMemberType from 'types/TeamMember';

interface Props {
  teamMember: TeamMemberType;
  location: 'line' | 'product';
}

function TeamMemberComponent(props: Props) {
  const { teamMember } = props;
  const [toolTipContent, setToolTipContent] = useState<string>('');

  return (
    <LightTooltip
      className="cdsid-tooltip"
      title={toolTipContent}
      aria-label={teamMember.cdsid}
      data-testid="cdsid-tooltip"
      placement="top"
      arrow
    >
      <li tabIndex={-1} key={teamMember.cdsid} className={'report-sidebar-content'}>
        <div
          onClick={() => {
            navigator.clipboard.writeText(teamMember.cdsid ? teamMember.cdsid : '');
            setToolTipContent('Copied!');
          }}
          onMouseEnter={() => {
            setToolTipContent('Click to copy CDSID: ' + teamMember.cdsid);
          }}
        >
          <div className={'semi-bold'}>{teamMember.name}</div>
          <div className="light">{teamMember.title}</div>
        </div>
      </li>
    </LightTooltip>
  );
}

export default TeamMemberComponent;
