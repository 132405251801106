import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import React from 'react';

import './Modal.scss';

interface Props {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  isOpenModal: boolean;
  onExited?: () => void;
  showCloseIcon: boolean;
  className?: string;
  closeHandler: () => void;
}

/**
 * @deprecated please use web/src/components/common/Modal/Modal.tsx
 */
export default function Modal(props: Props): React.ReactElement {
  return (
    <Dialog
      open={props.isOpenModal}
      onClose={props.closeHandler}
      TransitionProps={{ onExited: props.onExited }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`modal-dialog ${props.className}`}
    >
      <div className="dialog-wrapper dialog-border">
        {props.showCloseIcon && (
          <button
            aria-label="close dialog"
            data-testid="close-icon"
            onClick={props.closeHandler}
            className="dialog-close"
          >
            <FontAwesomeIcon icon={'times' as IconName} />
          </button>
        )}
        <h5 className="bold dialog-header" data-testid="modal-title">
          {props.title}
        </h5>
        {props.subtitle && (
          <div className="dialog-subtitle" data-testid="dialog-subtitle">
            {props.subtitle}
          </div>
        )}

        {props.children}
      </div>
    </Dialog>
  );
}
