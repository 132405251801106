import { TextField, ThemeProvider } from '@mui/material';
import React from 'react';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import ProductHierarchyInput from './ProductHierarchyInput/ProductHierarchyInput';
import OrgForKanban from 'types/OrgForKanban';
import Product from 'types/Product';

interface TaxonomyInputProps {
  location: string;
  product: Product;
  setProduct: (product: Product) => void;
  orgDetails: OrgForKanban;
}

export function TaxonomyInput({
  location,
  product,
  setProduct,
  orgDetails,
}: TaxonomyInputProps): React.ReactElement {
  return (
    <>
      <div className={'dialog-input-wrapper'}>
        <ThemeProvider theme={muiTheme}>
          <TextField
            id="productname"
            fullWidth={true}
            defaultValue={product.name}
            onChange={(event) => {
              const prevProduct = product;
              prevProduct.name = event.target.value;
              setProduct(prevProduct);
            }}
            label={'Product Name'}
            variant="outlined"
            required={true}
            inputProps={{
              'data-testid': 'product-name-input',
              pattern: '^.{2,250}',
              title: 'Product name should contain more than 1 character',
            }}
          />
        </ThemeProvider>
      </div>
      <ProductHierarchyInput
        location={location}
        product={product}
        setProduct={setProduct}
        orgDetails={orgDetails}
      />
    </>
  );
}
