import { atom, selectorFamily } from 'recoil';
import { Error, Loading } from '../data/commonTypes';
import OrgForDropdown from 'types/OrgForDropdown';

export type OrgsForDropDownStateType = Loading | Loaded | Error;

type Loaded = {
  state: 'Loaded';
  organizations: OrgForDropdown[];
};

export const OrgsForDropDownState = atom<OrgsForDropDownStateType>({
  key: 'OrgsForDropDownState',
  default: { state: 'Loading' },
});

export const CurrentOrgSelector = selectorFamily({
  key: 'CurrentOrgSelector',
  get:
    (orgParam: string) =>
    ({ get }) => {
      const orgsForDropdownState = get(OrgsForDropDownState);

      if (orgParam && orgsForDropdownState.state === 'Loaded') {
        const { organizations } = orgsForDropdownState;

        const foundOrg = organizations.find((org) => {
          return equalsIgnoreCase(org.url, orgParam.toLowerCase() || '');
        });

        if (foundOrg) return foundOrg;
        else window.location.href = '/';
      }

      return { name: '', blurb: '', icon: '', url: '', id: '' } as OrgForDropdown;
    },
  set:
    (orgParam: string) =>
    ({ get, set }, updatedOrg) => {
      const orgsForDropdownState = get(OrgsForDropDownState);

      if (orgParam && orgsForDropdownState.state === 'Loaded') {
        const { organizations } = orgsForDropdownState;

        const updatedOrgs: OrgForDropdown[] = [...organizations].map((org) => {
          return equalsIgnoreCase(org.url, orgParam.toLowerCase() || '')
            ? (updatedOrg as OrgForDropdown)
            : org;
        });

        set(OrgsForDropDownState, {
          state: 'Loaded',
          organizations: updatedOrgs,
        });
      }
    },
});

function equalsIgnoreCase(str: string, other: string) {
  return !str.localeCompare(other, undefined, { sensitivity: 'accent' });
}
