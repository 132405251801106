import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import ProductHierarchyService from 'services/api/ProductHierarchyService';
import ProductHierarchy, {
  AddProductHierarchyRequest,
  HierarchyType,
} from 'types/ProductHierarchy';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { ModalContentsState } from 'state/ModalContentsState';
import ProductHierarchyState, {
  Loaded,
  ProductHierarchyByTypeInState,
} from 'state/ProductHierarchyState';

import GroupsAndLinesModalWrapper from '../../GroupsAndLinesModalWrapper/GroupsAndLinesModalWrapper';

import styles from './AddNewGroupModal.module.scss';

function getEmptyLineField(): LineField {
  return { value: '', id: 'id' + Math.random().toString(16).slice(2), isError: false };
}

interface GroupField {
  value: string;
  isError: boolean;
}

interface LineField extends GroupField {
  id: string;
}

interface Props {
  groupsLength: number;
  setExpanded(expandedIndex: number): void;
}

function AddNewGroupModal(props: Readonly<Props>) {
  const { groupsLength, setExpanded } = props;

  const orgDetails = useRecoilValue(OrgDetailsState);
  const closeModal = useResetRecoilState(ModalContentsState);
  const allGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const [productHierarchyState, setProductHierarchyState] = useRecoilState(ProductHierarchyState);
  const productHierarchy: ProductHierarchy[] =
    (productHierarchyState.state === 'Loaded' && productHierarchyState.productHierarchy) || [];

  const [groupField, setGroupField] = useState<GroupField>({ value: '', isError: false });
  const [productLineFields, setProductLineFields] = useState<LineField[]>([getEmptyLineField()]);

  function updateProductLineFieldValue(value: string, id: string) {
    setProductLineFields((prevState) =>
      prevState.map((field) => {
        if (field.id === id) field.value = value;
        return { ...field, isError: false };
      }),
    );
  }

  function duplicateGroupNamesFound(): boolean {
    const duplicateGroupFound =
      allGroups.filter((group) => group.name === groupField.value).length > 0;

    setGroupField({ ...groupField, isError: duplicateGroupFound });

    return duplicateGroupFound;
  }

  function duplicateLineNamesFound(): boolean {
    let duplicateLineFound = false;

    setProductLineFields((prevState) =>
      prevState.map((field) => {
        const isDuplicate = prevState.filter((f) => f.value === field.value).length > 1;
        if (isDuplicate) duplicateLineFound = true;
        return { ...field, isError: isDuplicate };
      }),
    );

    return duplicateLineFound;
  }

  function removeProductLineField(id: string) {
    setProductLineFields((prevState) => prevState.filter((_lineField) => _lineField.id !== id));
  }

  function addProductLineField() {
    setProductLineFields((prevState) => [...prevState, getEmptyLineField()]);
  }

  function generateAddRequest(): AddProductHierarchyRequest {
    const group = {
      name: groupField.value.trim(),
      orgId: orgDetails.id,
      type: HierarchyType.GROUP,
      order: groupsLength,
      teamMembers: null,
    } as ProductHierarchy;

    const lines = productLineFields.map(({ value }) => ({
      name: value.trim(),
      orgId: orgDetails.id,
      type: HierarchyType.LINE,
    })) as ProductHierarchy[];

    return { orgId: orgDetails.id, group, lines };
  }

  async function onSubmit(): Promise<void> {
    if (duplicateLineNamesFound() || duplicateGroupNamesFound()) return Promise.resolve();

    const response = generateAddRequest();
      const data = await ProductHierarchyService.addProductHierarchy(response);
      if (Array.isArray(data)) {
        setProductHierarchyState({
          ...productHierarchyState,
          productHierarchy: [...productHierarchy, ...data],
        } as Loaded);
      }

      setExpanded(groupsLength);

      closeModal();
  }

  function isFormPopulated() {
    return !!groupField.value || !!productLineFields.filter((f) => f.value !== '').length;
  }

  return (
    <GroupsAndLinesModalWrapper
      title="Add New Group"
      subtitle="This new Group and its associated Lines will not appear on the org page until a product is added to the Lines."
      saveBtnText="Add Group"
      onSubmit={onSubmit}
      isFormPopulated={isFormPopulated}
      useEffectDeps={[groupField, productLineFields]}
    >
      <div className={styles.content}>
        <TextField
          label="Group Name"
          required
          defaultValue={groupField.value}
          onChange={(event) => setGroupField({ value: event.target.value, isError: false })}
          className={styles.textField}
          inputProps={{
            'data-testid': 'addGroupNameInput',
            title: `Enter Group Name`,
          }}
          error={groupField.isError}
          helperText={groupField.isError && 'There is already a Group with the same name'}
        />
        {productLineFields.map((lineField, index) => {
          const { id, value, isError } = lineField;
          const showDeleteBtn = productLineFields.length > 1;
          return (
            <div key={`product-line-name-${id}`} className={styles.lineFieldContainer}>
              <TextField
                label="Line Name"
                required
                defaultValue={value}
                onChange={(event) => updateProductLineFieldValue(event.target.value, id)}
                className={styles.textField}
                inputProps={{
                  'data-testid': `addLineNameInput-${index}`,
                  title: `Enter Line Name`,
                }}
                error={isError}
                helperText={isError && 'There is already a Line with the same name'}
              />
              {showDeleteBtn && (
                <button
                  className={styles.trashCanButton}
                  data-testid={`deleteIcon-${index}`}
                  aria-label="delete item"
                  onClick={() => removeProductLineField(lineField.id)}
                >
                  <FontAwesomeIcon icon={'trash-can' as IconName} />
                </button>
              )}
            </div>
          );
        })}
        <button onClick={addProductLineField} type="button" className={styles.addAnotherLineBtn}>
          <FontAwesomeIcon icon={'circle-plus' as IconName} />
          &nbsp;Add Another Line
        </button>
      </div>
    </GroupsAndLinesModalWrapper>
  );
}

export default AddNewGroupModal;
