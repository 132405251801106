import React from 'react';
import { MenuItem, Select, SelectChangeEvent, ThemeProvider } from '@mui/material';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import { useRecoilState } from 'recoil';
import TimeFilterSearchState, { TimeFilterOptions } from 'state/TimeFilterSearchState';


export default function SearchTimeFilter(): React.ReactElement {
  const [searchTime, setSearchTime] = useRecoilState(TimeFilterSearchState);

  const handleChange = (event: SelectChangeEvent<typeof searchTime>) => {
    setSearchTime(event.target.value);
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <Select
        id="time-filter-select"
        data-testid="time-filter-select"
        value={searchTime}
        onChange={handleChange}
        displayEmpty
        sx={{ width: 200 }}
      >
        {(Object.keys(TimeFilterOptions) as (keyof typeof TimeFilterOptions)[]).map((key) => {
          return (
            <MenuItem key={key} value={TimeFilterOptions[key]}>
              {TimeFilterOptions[key]}
            </MenuItem>
          );
        })}
      </Select>
    </ThemeProvider>
  );
}
