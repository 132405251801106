import { styled } from '@mui/material/styles';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  iseditmode?: boolean;
}

const AccordionSummary = styled((props: StyledAccordionSummaryProps) => {
  return (
    <MuiAccordionSummary
      expandIcon={
        <FontAwesomeIcon
          className="dropdown-caret menu"
          icon={'chevron-up' as IconName}
          color={'black'}
        />
      }
      {...{ ...props, iseditmode: props?.iseditmode?.toString() }}
    />
  );
})<StyledAccordionSummaryProps>(({ iseditmode, theme }) => ({
  minHeight: '2rem',
  padding: theme.spacing(0),
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '1rem',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-root': {
    minHeight: 'unset',
  },
  ...(iseditmode && {
    borderBottom: '1px solid #E6E6E6',
    '& .MuiAccordionSummary-content': {
      flexGrow: 1,
      justifyContent: 'space-between',
      padding: '0.75rem 2rem 0.75rem 1.5rem',
      flexDirection: 'row',
      alignItems: 'left',
      margin: 0,
    },
  }),
  ...(!iseditmode && {
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      alignItems: 'left',
      flexDirection: 'column',
      margin: 0,
    },
  }),
}));

export default AccordionSummary;
