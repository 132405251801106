import { atom, selectorFamily } from 'recoil';
import OrgForKanban from 'types/OrgForKanban';
import { PageType, ParentType } from 'types/Page';

export const OrgDetailsState = atom<OrgForKanban>({
  key: 'OrgDetailsState',
  default: {} as OrgForKanban,
});

export type SelectorProps = { title: string; templateType: ParentType };

export type PageAndTemplateSelectorProps = { pageType: PageType; templateType: ParentType };

export const GetPageTemplateByPageAndTemplateType = selectorFamily({
  key: 'GetPageTemplateByPageAndTemplateType',
  get:
    (pageAndTemplateSelectorProps: PageAndTemplateSelectorProps) =>
    ({ get }) => {
      const pageTemplates = get(OrgDetailsState).pageTemplates;
      return pageTemplates
        ? pageTemplates.find(
            (page) =>
              page.pageType === pageAndTemplateSelectorProps.pageType &&
              page.templateType === pageAndTemplateSelectorProps.templateType,
          )
        : undefined;
    },
});

export const GetUpdatePageTemplateSectionByTitle = selectorFamily({
  key: 'GetUpdatePageTemplateSectionByTitle',
  get:
    (selectorProps: SelectorProps) =>
    ({ get }) => {
      const pageTemplates = get(OrgDetailsState).pageTemplates;
      if (pageTemplates) {
        const updatePageTemplate = pageTemplates.find(
          (page) =>
            page.pageType === PageType.UPDATE && page.templateType === selectorProps.templateType,
        );
        if (updatePageTemplate) {
          const updateSection = updatePageTemplate.sections.find(
            (section) => section.title.toLowerCase() === selectorProps.title.toLowerCase(),
          );
          if (updateSection) return updateSection;
        }
      }
      return undefined;
    },
});
