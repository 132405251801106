import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { DataToEdit } from '../EditTeamCommonModal';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import dragDropIcon from 'images/drag-drop-icon.svg';

interface Props {
  dataList: DataToEdit[];
  dataType: string;
  onEditOfDataItem: (key: number, data: DataToEdit) => void;
  onAddNewItemClick: () => void;
  onUpdateOfDataItem: (content: Array<DataToEdit>) => void;
  setDialogIsOpen: (isOpen: boolean) => void;
  onDeleteItemClick: (data: DataToEdit) => void;
}

export default function ModalListDragAndDrop({
  dataList,
  dataType,
  onEditOfDataItem,
  onAddNewItemClick,
  onUpdateOfDataItem,
  setDialogIsOpen,
  onDeleteItemClick,
}: Props): React.ReactElement {
  const reorder = (list: DataToEdit[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const [listState, setListState] = useState<DataToEdit[]>(dataList);

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const list = reorder(listState, result.source.index, result.destination.index);
    setListState(list);
    onUpdateOfDataItem(list);
  }

  function DataList(props: { listItems: DataToEdit[] }): React.ReactElement {
    return (
      <>
        <ul>
          {props.listItems.map((data, key) => {
            return (
              <li id={data.name} className={`list-${key} list-item`} key={key}>
                <Draggable draggableId={data.name} index={key}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <span className="data-name" data-testid={`modal-name-${key}`}>
                        {data.name}
                      </span>
                      <span className="delete-item-button">
                        <button
                          data-testid={`delete-${key}`}
                          aria-label="delete"
                          onClick={() => onDeleteItemClick(data)}
                        >
                          <FontAwesomeIcon icon={'trash-can' as IconName} />
                        </button>
                      </span>
                      <span className="edit-item-button">
                        <button
                          data-testid={`edit-${key}`}
                          aria-label="edit"
                          onClick={() => onEditOfDataItem(key, data)}
                        >
                          <FontAwesomeIcon icon={'pen' as IconName} />
                        </button>
                      </span>

                      <LightTooltip
                        title={'Drag & Drop to Reorder'}
                        aria-label={`Drag & Drop to Reorder ${data.name}`}
                        placement="top"
                        arrow
                      >
                        <span
                          className="arrange-item-button"
                          data-testid={`${data.name}-handle`}
                          {...provided.dragHandleProps}
                        >
                          <img src={dragDropIcon} alt="drag-and-drop" />
                        </span>
                      </LightTooltip>
                    </div>
                  )}
                </Draggable>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <DataList listItems={listState} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button
        className="add-items-button"
        data-testid={'add-items-button'}
        onClick={() => onAddNewItemClick()}
      >
        <FontAwesomeIcon icon={'plus' as IconName} />
        Add New {dataType}
      </button>
      <button
        data-testid="drag-drop-save"
        className="button-blue save-team-member"
        onClick={() => {
          setDialogIsOpen(false);
        }}
      >
        Done
      </button>
    </>
  );
}
