import SearchBar from './SearchBar/SearchBar';
import SearchTimeFilter from './SearchTimeFilter/SearchTimeFilter';
import Link from 'common/Link/Link';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import OrgActions from './OrgActions/OrgActions';

import './FilterAndActionsBar.scss';

export default function FilterAndActionsBar() {
  const orgDetails = useRecoilValue(OrgDetailsState);

  return (
    <div className="kanban-actions sticky" id="kanban-actions">
      <div className="filter">
        <SearchBar />
        <SearchTimeFilter />
        {orgDetails.doneText && <Link to={`archived`}> {orgDetails.doneText} Products</Link>}
      </div>
      <div className="desktop-only">
        <OrgActions />
      </div>
    </div>
  );
}
