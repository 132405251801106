import React from 'react';
import { useSetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

interface Props {
  name: string;
  title: string;
  handleDelete: (success: () => void, failure: () => void) => void;
  type: string;
}

function DeleteItemConfirmation(props: Props) {
  const { name, title, handleDelete, type } = props;

  const setModalContents = useSetRecoilState(ModalContentsState);

  const closeModal = () => setModalContents(null);

  const returnToModal = () => {
    return (
      <ConfirmationModal
        title="SAVE FAILED"
        subtitle={`Your save was not successful. Please try again. If the issue persists, please reach out to Newsie Support.`}
        onCancel={() => {
          setModalContents({
            title: title,
            component: (
              <DeleteItemConfirmation
                name={name}
                title={title}
                handleDelete={handleDelete}
                type={type}
              />
            ),
          });
        }}
        cancelButtonText="Try Again"
      />
    );
  };

  const onSubmit = () => {
    handleDelete(
      () => {
        setModalContents(null);
      },
      () => {
        setModalContents({ component: returnToModal(), title: 'Try Again' });
      },
    );
  };

  return (
    <ConfirmationModal
      title={title}
      className="delete-group-form"
      subtitle={`Are you sure you want to delete the "${name}" ${type}?`}
      onConfirm={onSubmit}
      onCancel={closeModal}
      cancelButtonText="Cancel"
      confirmButtonText="Yes, Delete"
    />
  );
}

export default DeleteItemConfirmation;
