import { Draggable } from 'react-beautiful-dnd';
import GroupsAndLinesAccordion from './GroupsAndLinesAccordion/GroupsAndLinesAccordion';
import dragDropIcon from 'images/drag-drop-icon.svg';
import React from 'react';
import ProductHierarchy from 'types/ProductHierarchy';

import './DraggableItem.scss';

interface Props {
  group: ProductHierarchy;
  lines: ProductHierarchy[];
  index: number;
  isEditMode: boolean;
  expanded: number;
  setExpanded: (expandedIndex: number) => void;
}

export default function DraggableItem(props: Props) {
  const { group, index, isEditMode, expanded, setExpanded, lines } = props;

  return (
    <Draggable draggableId={`draggable-${group.id}`} index={index} isDragDisabled={!isEditMode}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <GroupsAndLinesAccordion
            key={index}
            group={group}
            lines={lines.filter((line: ProductHierarchy) => line.parentId === group.id)}
            index={index}
            isEditMode={isEditMode}
            expanded={expanded}
            setExpanded={setExpanded}
            dragHandleSpan={
              <span
                className="arrange-item-button group-reorder-icon"
                data-testid={`${group.id}-handle`}
                {...provided.dragHandleProps}
              >
                {isEditMode ? <img src={dragDropIcon} alt="drag-and-drop" /> : null}
              </span>
            }
          />
        </div>
      )}
    </Draggable>
  );
}
