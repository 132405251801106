import React, { useState } from 'react';

interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string;
  alt?: string;
}

export default function Icon({ name, ...rest }: IconProps): React.ReactElement | null {
  const [hideImage, setHideImage] = useState<boolean>(false);
  return hideImage ? (
    <></>
  ) : (
    <img
      src={`/images/${name}.svg`}
      onError={() => {
        setHideImage(true);
      }}
      {...rest}
    />
  );
}
