import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import React from 'react';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters={true} elevation={0} {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

export default Accordion;
