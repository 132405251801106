import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useA11yDialog } from 'react-a11y-dialog';
import { useRecoilState } from 'recoil';
import { ModalContents, ModalContentsState } from 'state/ModalContentsState';

import './Modal.scss';

function Modal() {
  const [modalContents, setModalContents] = useRecoilState<ModalContents | null>(
    ModalContentsState,
  );

  const [modalInstance, attr] = useA11yDialog({
    id: 'modal-container',
    title: modalContents?.title,
  });

  const dataTestId = `${modalContents?.title.toLowerCase().replace(/ /g, '-')}-modal`;

  const clearModalContents = useCallback(() => setModalContents(null), [setModalContents]);

  const myEvent = new CustomEvent('closeModal', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false,
  });

  useEffect(() => {
    if (modalContents) {
      modalInstance?.show();
    } else {
      modalInstance?.hide();
      clearModalContents();
    }
  }, [clearModalContents, modalContents, modalInstance]);

  function onClose() {
    if (modalContents?.dispatchCloseModalEvent) {
      document.querySelector('#main-content')?.dispatchEvent(myEvent);
    } else {
      clearModalContents();
    }
  }

  return (
    <div {...attr.container} className="modal-container" data-testid={dataTestId} id="modal">
      <div
        {...attr.overlay}
        className="modal-overlay"
        data-testid="modalOverlay"
        onClick={onClose}
      />
      <div {...attr.dialog} className={`modal-content`}>
        <p {...attr.title} className="modal-title">
          {modalContents?.title}
        </p>
        {modalContents?.component}
        <button
          {...attr.closeButton}
          id="modalCloseButton"
          className="modal-close-button"
          aria-label="Close Modal"
          data-testid="closeModalButton"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={'fa-xmark' as IconName} />
        </button>
      </div>
    </div>
  );
}

export default Modal;
