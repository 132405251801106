import { atom } from 'recoil';
import { screenSizes } from '../data/breakpoints';

const IsDesktopState = atom<boolean>({
  key: 'IsDesktopState',
  default: isDesktopInitial(),
});

function isDesktopInitial() {
  return window.innerWidth >= screenSizes.medium;
}

export default IsDesktopState;
