import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import ProductHierarchy from 'types/ProductHierarchy';

import {  useSetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import EditLineModal from './EditLineModal/EditLineModal';

interface Props {
  line: ProductHierarchy;
  setExpanded(expandedIndex: number): void;
}

function EditLineButton({ line, setExpanded }: Props) {
  const setModalContents = useSetRecoilState(ModalContentsState);

  const testId = `${line.name.replace(/ /g, '-')}-edit-pencil`;

  return (
    <button
      data-testid={testId}
      aria-label="edit lines"
      className="edit-pencil"
      onClick={() => {

        setModalContents({
          title: `Edit "${line.name}" Line`,
          component: <EditLineModal line={line} setExpanded={setExpanded} />,
          dispatchCloseModalEvent: true,
        });
      }}
    >
      <FontAwesomeIcon icon={'pen' as IconName} />
    </button>
  );
}

export default EditLineButton;
