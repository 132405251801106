import { atom, selectorFamily } from 'recoil';
import { Error, Loading } from '../data/commonTypes';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';

export type Loaded = { state: 'Loaded'; productHierarchy: ProductHierarchy[]; orgId: string };

export type ProductHierarchyAtomState = Loaded | Loading | Error;

export const ProductHierarchyState = atom<ProductHierarchyAtomState>({
  key: 'ProductHierarchyState',
  default: { state: 'Loading' },
});

// NOTE: This is something of an abuse of what selectorFamily() was designed for.
export const ProductHierarchyByIdInState = selectorFamily({
  key: 'ProductHierarchyByIdInState',
  get: (productHierarchyId: string) =>
    function getterToReturn({ get }): ProductHierarchy | undefined {
      const state = get(ProductHierarchyState);

      let valueToReturn: ProductHierarchy | undefined;
      if (state.state === 'Loaded' && productHierarchyId) {
        valueToReturn = state.productHierarchy.find(
          (element: ProductHierarchy) => productHierarchyId === element.id,
        );

        if (!valueToReturn) window.location.href = '/'; // DEV_NOTE: This appears to be a hack to redirect the app to root if valueToReturn isn't found.
        //           Is it strange that it only performs this redirect when state is Loaded?
      }

      return valueToReturn;
    },

  set: (selectorFamilyProductHierarchyId: string) =>
    function setterToReturn({ set, get }, newValue) {
      const state = get(ProductHierarchyState);

      if (newValue && state.state === 'Loaded') {
        const oldList = state.productHierarchy;
        let newList: ProductHierarchy[];

        if (selectorFamilyProductHierarchyId) {
          // DEV_NOTE: For existing records, replace the old record that matches selectorFamilyProductHierarchyId with newValue.
          newList = oldList.map((current) => {
            if (current.id === selectorFamilyProductHierarchyId) {
              return newValue as ProductHierarchy;
            }
            return current;
          });
        } else {
          // DEV_NOTE: If this is a new record, just add it to the existing loaded collection.
          newList = [...oldList, newValue as ProductHierarchy];
        }

        // JAH_NOTE: This just overrides the ProductHierachy collection part of the state.
        set(ProductHierarchyState, {
          ...state,
          productHierarchy: newList,
        });
      }
    },
});

export const ProductLinesForTheGivenGroupIdInState = selectorFamily({
  key: 'ProductLinesForTheGivenGroupIdInState',
  get:
    (groupId: string) =>
    ({ get }) => {
      const productHierarchyInState = get(ProductHierarchyState);
      if (productHierarchyInState.state === 'Loaded') {
        const productHierarchy = productHierarchyInState.productHierarchy;
        return productHierarchy.filter((element) => element.parentId === groupId);
      }
      return [];
    },
});

export const ProductHierarchyByTypeInState = selectorFamily({
  key: 'ProductHierarchyByTypeInState',
  get:
    (type: HierarchyType) =>
    ({ get }) => {
      const productHierarchyInState = get(ProductHierarchyState);
      if (productHierarchyInState.state === 'Loaded') {
        const productHierarchy = productHierarchyInState.productHierarchy;
        return productHierarchy.filter((element) => element.type === type);
      }
      return [];
    },
});

export default ProductHierarchyState;
