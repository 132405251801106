import React from 'react';
import { useSetRecoilState } from 'recoil';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { ModalContentsState } from 'state/ModalContentsState';
import DeleteProductModal from '../DeleteProductModal/DeleteProductModal';

interface Props {
  productName: string;
  productId: string;
  orgNameParam: string;
}

export default function DeletionFailedModal({ productName, productId, orgNameParam }: Props) {
  const setModalContents = useSetRecoilState(ModalContentsState);

  return (
    <ConfirmationModal
      title="Deletion Failed"
      subtitle="Your deletion was not successful. Please try again. If the issue persists, please reach out to Newsie Support."
      onCancel={() =>
        setModalContents({
          title: 'Delete Product?',
          component: (
            <DeleteProductModal
              productName={productName}
              productId={productId}
              orgNameParam={orgNameParam}
            />
          ),
        })
      }
      cancelButtonText="Try Again"
    />
  );
}
