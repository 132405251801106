import React, { useEffect } from 'react';
import ErrorLogo from '../../images/error-logo.svg';


import './ErrorMessageView.scss';

export default function ErrorMessageView(): React.ReactElement {
  useEffect(() => {
  }, []);

  return (
    <div className="error-container">
      <div className="error-content">
        <span className="error-title">Oops, we haven’t had our morning coffee yet!</span>
        <span className="error-text">
          Please try refreshing the page or send us a note at &nbsp;
          <a
            href="'https://engage.cloud.microsoft/main/org/ford.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxODU5MjM2NDEzNDQifQ/all'"
          >
            Viva Engage
          </a>
        </span>
        <span className="refresh">
          <button onClick={() => location.reload()}>REFRESH</button>
        </span>
      </div>
      <div>
        <img alt={'error'} src={ErrorLogo} />
      </div>
    </div>
  );
}
